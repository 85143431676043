import { ITransversal } from "@zurich-dynamic-form/ui";
import { BehaviorSubject, Observable } from "rxjs";
import { Persistence } from "../persistence/persistence";

export class Behaviour {

    private componentData: any;
    private componentBehavior: BehaviorSubject<any>;
    private persistence: Persistence;
    private static behavior: Behaviour = null;

    private constructor() {
        this.componentData = {};
        this.componentBehavior = new BehaviorSubject<any>(this.componentData);
        this.persistence = new Persistence();
    }

    public static getInstance(): Behaviour {
        if (Behaviour.behavior == null) {
            Behaviour.behavior = new Behaviour();
        }
        return Behaviour.behavior;
    }

    public setDataBox(key: string, value: string | any): void {
        this.componentData[key] = value;
        this.persistence.save(this.componentData);
        this.persistence.saveOriginalValue(this.componentData);
        this.componentBehavior.next(this.componentData);
    }

    public getDataBox(): Observable<any> {
        return this.componentBehavior.asObservable();
    }

    public remove(key: string): void {
        const keys = Object.keys(this.componentData);
        let tmp = {};
        for (let internalKey of keys) {
            if (internalKey !== key) {
                tmp[internalKey] = this.componentData[internalKey];
            }
        }
        this.persistence.removeElement(key);
        this.componentData = tmp;
    }

    public readLocal(key: string): boolean {
        const value = this.persistence.read(key);
        if (value) {
            this.componentData[key] = value;
            this.componentBehavior.next(this.componentData);
            return true;
        }
        return false;
    }

    public readCache(key: string) {
        return this.componentData[key] || null;
    }

    public getAllObjects(): any {
        return this.componentData;
    }
}