import { PipeMask } from "../../input-mask/Pipes/PipeMask.transform";
import { ITransversal } from "../../ui.itransversal";
import { PageComponent } from "../page/page.component";

export class FunctionsExtendModal {

    /**
     * 
     * Logica para los modales de terceros
     * 
     * */

    public activeThirdDamage(page: PageComponent): void {
        const componentVisible: ITransversal[] = page.componentsModal;
        let componentDamage: ITransversal = null;
        let componentTypeDamage: ITransversal = null;
        for (let index = 0; index < componentVisible.length; index++) {
            if (componentVisible[index].getComponent().id === 'radioButtonThird') {
                componentDamage = componentVisible[index];
            } else if (componentVisible[index].getComponent().id === 'inputArticleThird') {
                componentTypeDamage = componentVisible[index];
            }
        }
        if (componentDamage !== null) {
            const value = componentDamage.getValue();
            if (value === 'C' && componentTypeDamage !== null) {
                componentTypeDamage.isInvisible();
                this.hideVehicleParts(page, false);
            } else if (value === 'M' && componentTypeDamage !== null) {
                componentTypeDamage.isVisible();
            }
        }
    }

    public activeVehicleDamage(page: PageComponent): void {
        const componentVisible: ITransversal[] = page.componentsModal;
        let componentTypeDamage: ITransversal = null;
        for (let index = 0; index < componentVisible.length; index++) {
            if (componentVisible[index].getComponent().id === 'inputArticleThird') {
                componentTypeDamage = componentVisible[index];
            }
        }
        const value = (componentTypeDamage !== null) ? componentTypeDamage.getValue() : '';
        if (value === 'v') {
            this.hideVehicleParts(page, true);
        } else {
            this.hideVehicleParts(page, false);
        }
    }

    public activeInsured(page: PageComponent): void {
        const componentVisible: ITransversal[] = page.componentsModal;

        let existInsured: ITransversal = null;
        let textInsured: ITransversal = null;

        for (let index = 0; index < componentVisible.length; index++) {
            if (componentVisible[index].getComponent().id === 'radioArticleInsuredThird') {
                existInsured = componentVisible[index];
            } else if (componentVisible[index].getComponent().id === 'inputNameArticleInsuredThird') {
                textInsured = componentVisible[index];
            }
        }
        const value = (existInsured !== null) ? existInsured.getValue() : '';
        if (value === 'Y') {
            textInsured.isVisible();
        } else {
            textInsured.isInvisible();
        }
    }

    private hideVehicleParts(page: PageComponent, show: boolean): void {

        let inputNumberCarThird: ITransversal = null;
        let inputNumberMotorCarThird: ITransversal = null;
        let inputNumberChasisCarThird: ITransversal = null;
        let inputNumberCarVehicleFrontThird: ITransversal = null;
        let inputNumberCarVehicleBackThird: ITransversal = null;
        let labelSection2: ITransversal = null;
        let inputInteractiveRigthThird: ITransversal = null;
        let inputInteractiveLeftThird: ITransversal = null;
        let inputInteractiveFrontThird: ITransversal = null;
        let inputInteractiveBackThird: ITransversal = null;
        let inputPhotoFrontLicenseThird: ITransversal = null;
        let inputPhotoBackLicenseThird: ITransversal = null;

        const componentVisible: ITransversal[] = page.componentsModal;
        for (let index = 0; index < componentVisible.length; index++) {
            if (componentVisible[index].getComponent().id === 'inputNumberCarThird') {
                inputNumberCarThird = componentVisible[index];
            } else if (componentVisible[index].getComponent().id === 'inputNumberMotorCarThird') {
                inputNumberMotorCarThird = componentVisible[index];
            } else if (componentVisible[index].getComponent().id === 'inputNumberChasisCarThird') {
                inputNumberChasisCarThird = componentVisible[index];
            } else if (componentVisible[index].getComponent().id === 'inputNumberCarVehicleFrontThird') {
                inputNumberCarVehicleFrontThird = componentVisible[index];
            } else if (componentVisible[index].getComponent().id === 'inputNumberCarVehicleBackThird') {
                inputNumberCarVehicleBackThird = componentVisible[index];
            } else if (componentVisible[index].getComponent().id === 'labelSection2') {
                labelSection2 = componentVisible[index];
            } else if (componentVisible[index].getComponent().id === 'inputInteractiveRigthThird') {
                inputInteractiveRigthThird = componentVisible[index];
            } else if (componentVisible[index].getComponent().id === 'inputInteractiveLeftThird') {
                inputInteractiveLeftThird = componentVisible[index];
            } else if (componentVisible[index].getComponent().id === 'inputInteractiveFrontThird') {
                inputInteractiveFrontThird = componentVisible[index];
            } else if (componentVisible[index].getComponent().id === 'inputInteractiveBackThird') {
                inputInteractiveBackThird = componentVisible[index];
            } else if (componentVisible[index].getComponent().id === 'inputPhotoFrontLicenseThird') {
                inputPhotoFrontLicenseThird = componentVisible[index];
            } else if (componentVisible[index].getComponent().id === 'inputPhotoBackLicenseThird') {
                inputPhotoBackLicenseThird = componentVisible[index];
            }
        }
        if (show) {
            inputNumberCarThird.isVisible();
            inputNumberMotorCarThird.isVisible();
            inputNumberChasisCarThird.isVisible();
            inputNumberCarVehicleFrontThird.isVisible();
            inputNumberCarVehicleBackThird.isVisible();
            labelSection2.isVisible();
            inputInteractiveRigthThird.isVisible();
            inputInteractiveLeftThird.isVisible();
            inputInteractiveFrontThird.isVisible();
            inputInteractiveBackThird.isVisible();
            inputPhotoFrontLicenseThird.isVisible();
            inputPhotoBackLicenseThird.isVisible();
        } else {
            inputNumberCarThird.isInvisible();
            inputNumberMotorCarThird.isInvisible();
            inputNumberChasisCarThird.isInvisible();
            inputNumberCarVehicleFrontThird.isInvisible();
            inputNumberCarVehicleBackThird.isInvisible();
            labelSection2.isInvisible();
            inputInteractiveRigthThird.isInvisible();
            inputInteractiveLeftThird.isInvisible();
            inputInteractiveFrontThird.isInvisible();
            inputInteractiveBackThird.isInvisible();
            inputPhotoFrontLicenseThird.isInvisible();
            inputPhotoBackLicenseThird.isInvisible();
        }
    }

    /**
    * 
    * Logica para los modales de heridos
    * 
    * */

    public loadWitness(page: PageComponent): void {
        const componentVisible: ITransversal[] = page.componentsModal;
        let radioButtonWitness: ITransversal;
        let inputNameRegisterWitness: ITransversal;
        let inputLastNameRegisterWitness: ITransversal;
        let inputCellPhoneRegisterWitness: ITransversal;
        let inputEmailRegisterWitness: ITransversal;

        for (let index = 0; index < componentVisible.length; index++) {
            if (componentVisible[index].getComponent().id === '@radioButtonWitness') {
                radioButtonWitness = componentVisible[index];
            } else if (componentVisible[index].getComponent().id === '@inputNameRegisterWitness') {
                inputNameRegisterWitness = componentVisible[index];
            } else if (componentVisible[index].getComponent().id === '@inputLastNameRegisterWitness') {
                inputLastNameRegisterWitness = componentVisible[index];
            } else if (componentVisible[index].getComponent().id === '@inputCellPhoneRegisterWitness') {
                inputCellPhoneRegisterWitness = componentVisible[index];
            } else if (componentVisible[index].getComponent().id === '@inputEmailRegisterWitness') {
                inputEmailRegisterWitness = componentVisible[index];
            }

        }
        const value = radioButtonWitness.getValue();
        if (value == 'Y') {
            const valueLocalName = localStorage.getItem('@inputNameRegister');
            inputNameRegisterWitness.setValuePostLoad(page.pipeMask.transform(valueLocalName, PipeMask.NAMEREGISTER));
            const valueLocalLastName = localStorage.getItem('@inputLastNameRegister');
            inputLastNameRegisterWitness.setValuePostLoad(page.pipeMask.transform(valueLocalLastName, PipeMask.LASTNAMEREGISTER));
            const valueLocalPhone = localStorage.getItem('@inputCellPhoneRegister');
            inputCellPhoneRegisterWitness.setValuePostLoad(page.pipeMask.transform(valueLocalPhone, PipeMask.PHONE));
            const valueLocalEmail = localStorage.getItem('@inputEmailRegister');
            inputEmailRegisterWitness.setValuePostLoad(page.pipeMask.transform(valueLocalEmail, PipeMask.EMAIL));
        } else {
            inputNameRegisterWitness.setValuePostLoad('');
            inputLastNameRegisterWitness.setValuePostLoad('');
            inputCellPhoneRegisterWitness.setValuePostLoad('');
            inputEmailRegisterWitness.setValuePostLoad('');
        }
    }

}