import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, from, of, throwError } from 'rxjs';
import { AuthService } from './http-service.service';
import { switchMap, catchError } from 'rxjs/operators';
import { environment, service } from '@env/environment';
import { SecurityService } from '../../../../../apps/zurich-dynamic-form/src/app/security/security.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

    constructor(private auth: AuthService,private securityService: SecurityService ) { }
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (this.shouldExcludeToken(request.url)) {
            return next.handle(request).pipe(
                catchError((error) => {
                    return throwError(error.message);
                })
            );
        }

        const host = this.extractHost(request.url);
        if (!host.includes(environment.host)) {
            return next.handle(request).pipe(
                catchError((error) => {
                    return throwError(error.message);
                })
            );
        }

        return this.getTokenAndHandleRequest(request, next);
    }

    private getTokenAndHandleRequest(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const cleanBody = this.removeNullsFields(request.body);
        const token = sessionStorage.getItem("token");
        if (!token) {
            return this.sendRequestWithNewCognitoToken(request, next, cleanBody);
        }
        const clonedRequest = this.addTokenAndApiKeyToRequest(request, token, service.apiKey, cleanBody);
        return next.handle(clonedRequest).pipe(
            catchError((error) => {
                if (error.status == 401) {
                    return this.sendRequestWithNewCognitoToken(request, next, cleanBody);
                }
                return throwError(error.message);
            })
        )
    }

    private sendRequestWithNewCognitoToken(request: HttpRequest<any>, next: HttpHandler, cleanBody: any) {
        return from(this.auth.getTokenCognito()).pipe(
            switchMap((tokenCognito) => {
                const clonedRequestNewToken = this.addTokenAndApiKeyToRequest(request, tokenCognito, this.securityService.getApiKey(), cleanBody);
                return next.handle(clonedRequestNewToken).pipe(
                    catchError((error) => {
                        return throwError(error.message);
                    })
                );
            })
        )
    }

    private addTokenAndApiKeyToRequest(
        request: HttpRequest<any>,
        token: string,
        apiKey: string,
        cleanBody: any
    ): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: token,
                'x-api-key': apiKey
            },
            body: cleanBody
        })
    }


    private shouldExcludeToken(url: string): boolean {
        return url.includes(environment.hostApiGateway.concat(environment.token));
    }

    private extractHost(url: string): string {
        const urlParts = url.split('/');
        return `${urlParts[0]}//${urlParts[2]}`;
    }

    private removeNullsFields = (jsonObject: any) => {
        for (var key in jsonObject) {
            if (typeof jsonObject[key] === "object") {
                this.removeNullsFields(jsonObject[key]);
            }
            if (jsonObject[key] === null) {
                delete jsonObject[key];
            }
        }
    }
}


