import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpGeneralService } from '../general/http-service.service';
import { environment, service } from '@env/environment';
import { SchemaToken } from '@zurich-dynamic-form/schemas';
import { SecurityService } from '../../../../../apps/zurich-dynamic-form/src/app/security/security.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private service: HttpGeneralService, private securityService: SecurityService ) { }

  private getToken(): Promise<string> {
    return new Promise(
      (result, reject) => {
        const headers = new HttpHeaders({
          'Authorization': 'Basic '+ btoa(this.securityService.getUser() + ':' + this.securityService.getPassword()),
          'Content-Type': 'application/x-www-form-urlencoded'
        });

        this.service.methodPost({
          body: `grant_type=client_credentials`,
          url: environment.hostApiGateway.concat(environment.token),
          headers: headers
        }).subscribe(
          (response: SchemaToken) => {
            if (response && response.access_token && response.token_type) {
              sessionStorage.setItem('token', response.token_type.concat(' '.concat(response.access_token)));
              result(response.token_type.concat(' '.concat(response.access_token)));
            }
            reject('Error in token')
          }, reject
        );
      }
    );
  }

  public async getTokenCognito() {
    const token = await this.getToken();
    return token;
  }

}
