import { Component, Input, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
declare var $:any;
@Component({
  selector: 'zurich-dynamic-form-tooltip-information',
  templateUrl: './tooltip-information.component.html',
  styleUrls: ['./tooltip-information.component.scss']
})
export class TooltipInformationComponent implements OnInit {

  @Input() title: string;
  @Input() body: string;
  @Input() img: string;

  constructor(public deviceService: DeviceDetectorService) {
  }

  ngOnInit() {
    this.title = (this.title) ? this.title : '';
    this.body = (this.body) ? this.body : '';
    this.img = (this.img) ? this.img : '';
    $('[data-toggle="popover"]').popover({
      animated: 'fade',
      placement: 'right',
      html: true,
      template: this.contentTooltip()});
  }

  public contentTooltip():string{
    let template = '<div class="card">';
    if (this.deviceService.isDesktop()){
      template += (this.title) ? `<div class="card-header header-card">${this.title}</div>` : '';
    } else {
      template += (this.title) ? `<div class="card-header header-card">${this.title} <span class="close-card" aria-hidden="true">&times;</span></div>` : '';
    }
    template += '<div class="card-body body-card">'
    template += (this.body) ? `<div class="content-body-card"> ${this.body}</div>` : '';
    template += (this.img) ? `<img class="" src="${this.img}" alt="Card image cap">` : '';
    template += '</div>';
    template += '</div>';
    return template;
  }

}
