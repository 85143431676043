import { IComponent } from "@zurich-dynamic-form/schemas";
import { FunctionsComponent } from "../component-logic.functions";
import { HttpServiceService } from "@zurich-dynamic-form/services";
import { ModalComponent } from "libs/ui/src/lib/modal/modal.component";
import { ITransversal } from "@zurich-dynamic-form/ui";
import { Functions } from '../../../../../../../libs/ui/src/utils/functions';

export class LogicPage1 {

    private functions: FunctionsComponent;

    constructor(fun: FunctionsComponent) {
        this.functions = fun;
    }

    public getValueForComponent(component: IComponent): string {
        return this.functions.getValueForComponent(component);
    }
    public getComponentForId(id: string): ITransversal {
        return this.functions.getComponentForId(id);
    }

    public viewFormRegister(component: IComponent): void {
        const value = this.getComponentForId('@inputTypeDocumentIdentity').getValue();
        if (value == 'c' || value == 'p') {
            this.getComponentForId('@inputNameCompanyRegister').isInvisible();
        } else if (value == 'r') {
            this.getComponentForId('@inputNameCompanyRegister').isVisible();
        }

        this.getComponentForId('@inputNameRegister').isVisible();
        this.getComponentForId('@inputLastNameRegister').isVisible();
        this.getComponentForId('@inputPhoneRegister').isVisible();
        this.getComponentForId('@inputCellPhoneRegister').isVisible();
        this.getComponentForId('@inputEmailRegister').isVisible();
        //this.getComponentForId('@inputFrontDocumentRegister').isVisible();
        this.getComponentForId('@labelBouquet').isVisible();
        this.getComponentForId('@inputBouquet').isVisible();
        this.getComponentForId('@inputHiddenAutomaticCompleteRegistry').setValue('Y');
        const ernoService = this.getComponentForId(component.id).isErrorService();
        if (ernoService) {
            this.getComponentForId('@inputNameRegister').setValuePostLoad('');
            this.getComponentForId('@inputLastNameRegister').setValuePostLoad('');
            this.getComponentForId('@inputPhoneRegister').setValuePostLoad('');
            this.getComponentForId('@inputCellPhoneRegister').setValuePostLoad('');
            this.getComponentForId('@inputEmailRegister').setValuePostLoad('');
            //this.getComponentForId('@inputFrontDocumentRegister').setValuePostLoad('');
            this.getComponentForId('@labelBouquet').setValuePostLoad('');
            this.getComponentForId('@inputBouquet').setValuePostLoad('');
            this.getComponentForId('@inputHiddenAutomaticCompleteRegistry').setValue('N');
        }

    }

    public fieldsVehicle(): void {
        this.getComponentForId('@labelSectionRegistry').isVisible();
        this.getComponentForId('@inputNumberCarIdentityRegistry').isVisible();
    }

    public fieldsCompany(component: IComponent): void {
        const value = this.getValueForComponent(component);
        const data = this.getComponentForId('@inputNumberDocumentIdentity').getValue() || '';

        if (value == 'c' || value == 'r') {
            this.getComponentForId('@inputNumberDocumentIdentity').isVisible();
            this.getComponentForId('@inputNumberPassportIdentity').isInvisible();
        } else if (value == 'p') {
            this.getComponentForId('@inputNumberPassportIdentity').isVisible();
            this.getComponentForId('@inputNumberDocumentIdentity').isInvisible();
        }
        if (value !== null) {
            let personalName = this.getComponentForId('@inputNameRegister');
            let personalLastname = this.getComponentForId('@inputLastNameRegister');
            if (value == 'c' || value == 'p') {
                personalName.setLabel('Nombres');
                personalLastname.setLabel('Apellidos');
                if (data != '') {
                    this.getComponentForId('@inputNameCompanyRegister').isInvisible();
                }
            } else if (value == 'r') {
                personalName.setLabel('Nombres persona de contacto');
                personalLastname.setLabel('Apellidos persona de contacto');
                if (data != '') {
                    this.getComponentForId('@inputNameCompanyRegister').isVisible();
                }
                this.getComponentForId('@inputNameCompanyRegister').setValuePostLoad('');
            }
            this.getComponentForId('@inputNameRegister').setValuePostLoad('');
            this.getComponentForId('@inputLastNameRegister').setValuePostLoad('');
            this.getComponentForId('@inputPhoneRegister').setValuePostLoad('');
            this.getComponentForId('@inputCellPhoneRegister').setValuePostLoad('');
            this.getComponentForId('@inputEmailRegister').setValuePostLoad('');
            //this.getComponentForId('@inputFrontDocumentRegister').setValuePostLoad('');
            this.getComponentForId('@labelBouquet').setValuePostLoad('');
            this.getComponentForId('@inputBouquet').setValuePostLoad('');
        }

    }

    public loadInformationCar(component: IComponent, data: any, err: any): void {
        this.showClearDataMotor();
        localStorage.setItem('modelEdit', 'false');
        if (data && (data.code == 1 || data.code == 0)) {
            this.getComponentForId('@inputNumberMotorCarRegistry').isInvisible();
            this.loadInformacionByCar(data);
        } else {
            this.hiddenClearDataMotor();
            this.getComponentForId('@inputNumberMotorCarRegistry').isVisible();
        }
    }

    public showNextDataMotor(): void {
        this.getComponentForId('@labelDataVehicleRegistry').isVisible();
        this.getComponentForId('@inputBrandCarRegistry').isVisible();
        this.getComponentForId('@inputModelCarRegistry').isVisible();
        this.getComponentForId('@inputYearCarRegistry').isVisible();
        this.getComponentForId('@inputNumberChasisCarRegistry2').isVisible();
        this.getComponentForId('@inputNumberMotorCarRegistry2').isVisible();
        this.getComponentForId('@inputColorCarRegistry').isVisible();
        this.getComponentForId('@buttonNextStep').isVisible();
    }

    public showClearDataMotor(): void {
        this.getComponentForId('@labelDataVehicleRegistry').isInvisible();
        this.getComponentForId('@inputNumberMotorCarRegistry').setValuePostLoad('');
        this.getComponentForId('@inputBrandCarRegistry').setValuePostLoad('');
        this.getComponentForId('@inputModelCarRegistry').setValuePostLoad('');
        this.getComponentForId('@inputYearCarRegistry').setValuePostLoad('');
        this.getComponentForId('@inputNumberChasisCarRegistry2').setValuePostLoad('');
        this.getComponentForId('@inputNumberMotorCarRegistry2').setValuePostLoad('');
        this.getComponentForId('@inputColorCarRegistry').setValuePostLoad('');
    }

    public hiddenClearDataMotor(): void {
        this.getComponentForId('@labelDataVehicleRegistry').isInvisible();
        this.getComponentForId('@inputBrandCarRegistry').isInvisible();
        this.getComponentForId('@inputModelCarRegistry').isInvisible();
        this.getComponentForId('@inputYearCarRegistry').isInvisible();
        this.getComponentForId('@inputNumberChasisCarRegistry2').isInvisible();
        this.getComponentForId('@inputNumberMotorCarRegistry2').isInvisible();
        this.getComponentForId('@inputColorCarRegistry').isInvisible();
        this.getComponentForId('@buttonNextStep').isInvisible();
    }

    public notShowInputsCar(): void {
        this.getComponentForId('@inputNumberMotorCarRegistry').isInvisible();
    }


    public showInputFile(component: IComponent): void {
        let idComponentInVisible = '';
        switch (component.id) {
            case '@inputExistPresencePolice':
                idComponentInVisible = '@inputDocumentReportPolice';
                break;
            case '@inputComplaint':
                idComponentInVisible = '@inputDamageSinister';
                break;
            case '@inputProforma':
                idComponentInVisible = '@inputPhotoProforma';
                break;
        }
        if (idComponentInVisible !== '') {
            let componentValue = this.getComponentForId(component.id);
            let componentResponse = this.getComponentForId(idComponentInVisible);
            if (componentValue.getValue() == 'N') {
                componentResponse.isInvisible();
            } else {
                componentResponse.isVisible();
            }
        }
    }

    public setSelectListOffice(component: IComponent, data: any): void {
        if (data) {
            const options = [];
            let newComponent = this.getComponentForId(component.id);
            component.extras.options = [];
            for (let lost of data.listOffices) {
                options.push({
                    key: lost.id,
                    value: lost.value
                });
            }
            const value = localStorage.getItem(component.id) || null;
            this.getComponentForId(component.id).setOptions(options);
            localStorage.setItem(component.id + '_options', JSON.stringify(newComponent.getComponent().extras.options));
            if (value !== null) {
                this.getComponentForId(component.id).onPostInit(value);
            }
        }
    }

    public loadInformationCarByMotor(component: IComponent, data: any): void {

        if (data && (data.code == 1 || data.code == 0)) {
            this.loadInformacionByCar(data);
        } else {
            this.getComponentForId('@inputHiddenAutomaticCompleteDataVehicle').setValue('N');
            let newComponent = this.getComponentForId('@inputBrandCarRegistry');
            newComponent.isVisible();
            this.functions.componentLogic.injectService.callService(newComponent.getComponent(), this.functions.componentLogic.data)
                .then(
                    (result: any) => {
                        if (result && result.code === 0) {
                            let dataResponseOptions = [];
                            for (let brand of result.vehicleByPlate) {
                                dataResponseOptions.push({
                                    key: brand.id,
                                    value: brand.value
                                });
                            }
                            newComponent.setOptions(dataResponseOptions);
                            localStorage.setItem('@inputBrandCarRegistry_options', JSON.stringify(newComponent.getComponent().extras.options));
                        } else {
                            this.loadInputTextLoadBrand();
                        }
                    })
                .catch((result) => {
                    this.loadInputTextLoadBrand();
                })
        }
    }

    private loadInputTextLoadBrand() {
        let inputBrandCarRegistry = this.getComponentForId('@inputBrandCarRegistry');
        inputBrandCarRegistry.setIsChangeService(true);
        let inputModelCarRegistry = this.getComponentForId('@inputModelCarRegistry');
        inputModelCarRegistry.isVisible();
        inputModelCarRegistry.setIsChangeService(true);
        this.loadYear(null, null);
    }

    public loadModelBrand(component: IComponent, data: any): void {
        if (data && data.code == 1) {
            this.loadInformacionByCar(data);
        } else {
            const newComponent = this.getComponentForId('@inputModelCarRegistry');
            newComponent.isVisible();
            this.functions.componentLogic.injectService.callService(newComponent.getComponent(), this.functions.componentLogic.data)
                .then(
                    (result: any) => {
                        if (result && result.code === 0) {
                            let dataResponseOptions = [];
                            for (let brand of result.vehicleModel) {
                                dataResponseOptions.push({
                                    key: brand.id,
                                    value: brand.value
                                });
                            }
                            newComponent.setOptions(dataResponseOptions);
                            localStorage.setItem('@inputModelCarRegistry_options', JSON.stringify(newComponent.getComponent().extras.options));
                            localStorage.setItem('modelEdit', 'true');
                        } else {
                            console.error('Error in result loadModelBrand', result);
                        }
                    })
                .catch((result) => {
                    console.error('Error in result loadModelBrand', result);
                })
        }
    }

    public loadYear(component: IComponent, data: any): void {
        let newComponent = this.getComponentForId('@inputYearCarRegistry');
        newComponent.isVisible();
        let date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        const lim = date.getFullYear() - 20;
        newComponent.getComponent().extras.options = [];
        for (let ind = date.getFullYear(); ind > lim; ind--) {
            newComponent.getComponent().extras.options.push({
                key: String(ind),
                value: String(ind)
            });
        }
        localStorage.setItem('@inputYearCarRegistry_options', JSON.stringify(newComponent.getComponent().extras.options));
        this.getComponentForId('@inputNumberChasisCarRegistry2').isVisible();
        this.getComponentForId('@inputNumberMotorCarRegistry2').isVisible();
        this.getComponentForId('@inputColorCarRegistry').isVisible();
        // this.getComponentForId('@inputNumberCarVehicle').isVisible();
        this.getComponentForId('@buttonNextStep').isVisible();
    }

    public loadInformacionByCar(data: any): void {
        this.getComponentForId('@inputHiddenAutomaticCompleteDataVehicle').setValue('Y');

        if (data.vehicle && data.vehicle.length > 0) {
            const isInputNumberMotorVisible = this.getComponentForId('@inputNumberMotorCarRegistry').getIsItemVisible();
            if (!isInputNumberMotorVisible) {
                this.notShowInputsCar();
            }
            for (let item of data.vehicle) {
                if (item.generatedSinisterToday) {
                    const modal = this.functions.componentLogic.modalService.open(ModalComponent, HttpServiceService.CENTER);
                    modal.componentInstance.data = {
                        type: 'reportExist',
                        value: 0
                    };
                    modal.result.then((data) => {
                        if (!data) {
                            localStorage.clear();
                            sessionStorage.clear();
                            window.location.href = '/';
                        }
                    });
                }
                let information = this.getComponentForId('@labelDataVehicleRegistry');
                this.functions.componentLogic.data.setKeyValue('@ramoForFilter', item.productId);
                // set label
                this.getComponentForId('@inputHiddenProduct').setValue(item.product);
                localStorage.setItem('broker', item.agent)
                information.getComponent().extras.options = [
                    { 'title': 'Número de póliza', 'value': item.policyNumber, 'type': 'polizaNumber' },
                    { 'title': 'Broker', 'value': item.agent, 'type': 'broker' },
                    // { 'title': 'Inicio de vigencia', 'value': item.validSince },
                    // { 'title': 'Fin de Vigencia', 'value': item.validUntil }
                ];
                // set Brand
                let componentBrand = this.getComponentForId('@inputBrandCarRegistry');
                componentBrand.getComponent().extras.options = [
                    { key: item.brandId, value: item.brand }
                ];
                localStorage.setItem('@inputBrandCarRegistry_options', JSON.stringify(componentBrand.getComponent().extras.options));
                componentBrand.setValue(item.brandId);
                // set model
                let componentModel = this.getComponentForId('@inputModelCarRegistry');
                componentModel.getComponent().extras.options = [
                    { key: item.idModel, value: item.model }
                ];
                localStorage.setItem('@inputModelCarRegistry_options', JSON.stringify(componentModel.getComponent().extras.options));
                componentModel.setValue(item.idModel);
                // set Year
                let componentYear = this.getComponentForId('@inputYearCarRegistry');
                componentYear.getComponent().extras.options = [
                    { key: item.year, value: item.year }
                ];
                localStorage.setItem('@inputYearCarRegistry_options', JSON.stringify(componentYear.getComponent().extras.options));
                componentYear.setValue(item.year);

                this.getComponentForId('@inputNumberChasisCarRegistry2').setValuePostLoad(item.chassis || '');
                this.getComponentForId('@inputNumberMotorCarRegistry2').setValuePostLoad(item.motor || '');
                localStorage.setItem('@inputColorCarRegistry_original', item.vehicleColor)
                this.getComponentForId('@inputColorCarRegistry').setValue(Functions.maskInformation('@inputColorCarRegistry', item.vehicleColor) || '');
                this.showNextDataMotor();
            }
        } else {
            this.showClearDataMotor();
        }
    }
}