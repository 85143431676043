<img
  class="img-tooltip"
  src="/assets/img/img-question.svg"
  data-trigger="click"
  alt="icon-question"
  [ngbPopover]="templateTooltip"
  [autoClose]="false"
  #p="ngbPopover"
  [placement]="'right'"
/>

<ng-template #templateTooltip>
  <div class="card" style="border: none;">
    <div class="card-header header-card">
      {{ title }} <span class="close-card" style="cursor: pointer;" (click)="p.toggle()" aria-hidden="true">&times;</span>
    </div>
    <div class="card-body">
      <div class="content-body-card">
        <div id="carouselTemplate" class="carousel slide" data-ride="carousel">
          <!-- Indicators -->
          <ul class="carousel-indicators">
            <li data-target="#carouselTemplate" data-slide-to="0" class="active"></li>
            <li data-target="#carouselTemplate" data-slide-to="1"></li>
            <li data-target="#carouselTemplate" data-slide-to="2"></li>
          </ul>

          <!-- The slideshow -->
          <div class="carousel-inner" [innerHTML]="contentCard">
           </div>

          <!-- Left and right controls -->
          <a class="carousel-control-prev" href="#carouselTemplate" data-slide="prev">
            <span class="carousel-control-prev-icon"></span>
          </a>
          <a class="carousel-control-next" href="#carouselTemplate" data-slide="next">
            <span class="carousel-control-next-icon"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-template>
