import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'zurich-dynamic-form-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class Header implements OnInit {

  public header:string;

  constructor(private deviceService: DeviceDetectorService) { }

  ngOnInit(): void {
    this.header = (this.deviceService.isDesktop()) ? 'assets/img/img-background2.png' : 'assets/img/img-background-mobile2.png';
  }

}
