<div class="form-group" [ngClass]="classSection" *ngIf="isItemVisible">
    <label *ngIf="component.extras.title !== ''" class="title-label" [for]="component.id">{{component.extras.title}}</label>
    <zurich-dynamic-form-tooltip-information *ngIf="component.extras.help && component.extras.help.description"
        [title]="component.extras.help.title"
        [body]="component.extras.help.description"
        [img]="component.extras.help.image" >
    </zurich-dynamic-form-tooltip-information>

    <zurich-dynamic-form-slide-tooltip *ngIf="component.extras.help && component.extras.help.options"
        [title]="component.extras.help.title"
        [options]="component.extras.help.options"
    ></zurich-dynamic-form-slide-tooltip>

    <p *ngIf="component.extras.subTitle !== ''" class="subtitle-label">{{component.extras.subTitle}}</p>

    <div
    *ngIf="!isEditableField"
    class="form-control input-portal" 
    [ngClass]="{'error-class': isError  , 'background-preload' : !isEditable }" 
    [name]="id" 
    [id]="id" 
    >
        {{ value | maskcrypt:component.extras.type}}
    </div>
    <button  type="button" class="button-into-input2"
    *ngIf="!isEditableButton && (component.extras.type === 'email' || component.extras.type === 'phone' || component.extras.type === 'cellphone')"
    [disabled]="(!(value !== '') || isError)"
    [ngClass]="{'btn-disabled' : (!(value !== '') || isError)}"
    (click)="editarValue()">
        Edita
    </button>



    <input 
    [type]="type" 
    class="form-control input-portal" 
    [ngClass]="{'error-class': isError  , 'background-preload' : !isEditable }" 
    [name]="id" 
    [id]="id" 
    *ngIf="isEditableField"
    [(ngModel)]="valueInput" 
    [disabled]="!isEditable"
    (keyup)="setValueForm()"
    (blur)="isValid()"
    (focus)="focusResetInput()"
    />
    <button type="button" class="button-into-input2"
    *ngIf="isEditableFieldButton"
    (click)="cerrarEdicion()">
        &times;
    </button>



    <!-- [ngClass]="{'animated-click animation-click' : (value !== '' && isFocusedButton)}" -->

    <zurich-dynamic-form-error *ngIf="isError"></zurich-dynamic-form-error>
    <zurich-dynamic-form-information-message *ngIf="messageInformation !== ''" [message]="messageInformation"></zurich-dynamic-form-information-message>
</div>