<div class="form-group" [ngClass]="classSection"  *ngIf="isItemVisible">
    <label *ngIf="title !== ''" class="title-label" [for]="id">{{title}}</label>
    <zurich-dynamic-form-tooltip-information *ngIf="component.extras.help"
        [title]="component.extras.help.title"
        [body]="component.extras.help.description"
        [title]="component.extras.help.image" >
    </zurich-dynamic-form-tooltip-information>
    <p *ngIf="subtitle !== ''" class="subtitle-label">{{subtitle}}</p>
    <div ngbDropdown #myDrop="ngbDropdown">
        <input class="form-control input-portal" readonly [ngClass]="{'error-class': isError }" placeholder="" (focus)="myDrop.open()" name="dp" [id]="id" [(ngModel)]="value">
        <button class="button-into-input" [attr.id]="id" type="button" ngbDropdownToggle >
            <img style="width: 90%; margin: 0;" src="/assets/img/img-clock.svg" alt="Calendar">
        </button>
        <zurich-dynamic-form-error *ngIf="isError"></zurich-dynamic-form-error>

        <div ngbDropdownMenu [attr.aria-labelledby]="id" class="dropdown-pickertime">
            <label class="title-label-tooltip" >Selecciona la hora</label>
            <ngb-timepicker [(ngModel)]="time" (ngModelChange)="valideHour()"></ngb-timepicker>
            <span class="title-label-tooltip-erno" *ngIf="msmErno !== ''" [innerHTML]="msmErno">{{msmErno}}</span>
            <div class="content-footer">
                <button type="button" class="btn" (click)="myDrop.close()">Cancelar</button>
                <button type="button" class="btn" [disabled]="msmErno != ''" (click)="setHour(myDrop)">Aceptar</button>
            </div>
        </div>
    </div>
    <zurich-dynamic-form-information-message *ngIf="messageInformation && messageInformation !== ''" [message]="messageInformation"></zurich-dynamic-form-information-message>
</div>