import { environment } from "@env/environment";
import { IComponent } from "@zurich-dynamic-form/schemas";
import { Functions } from "../../../utils/functions";
import { FunctionsComponent } from "../component-logic.functions";

export class LogicPage2 {

    private functions: FunctionsComponent;

    constructor(fun: FunctionsComponent) {
        this.functions = fun;
    }

    /*
    public setSelectTypeLost(component: IComponent , data: any) : void {
        if (data) {
            let options = [];
            for (let lost of data.listLossType) {
                options.push( {
                    key : lost.id,
                    value : lost.value
                });
            }
            this.functions.getComponentForId(component.id).setOptions(options);
            const value = localStorage.getItem(component.id) || null;
            localStorage.setItem(component.id + '_options' , JSON.stringify(options) );
            if (value !== null) {
                this.functions.getComponentForId(component.id).onPostInit(value);
            }
        }
    }
    */


    public setSelectCauseSiniester(component: IComponent, data: any): void {
        if (data) {
            let options = [];
            for (let lost of data.listCauseSinister) {
                options.push({
                    key: lost.id,
                    value: lost.value
                });
            }
            this.functions.getComponentForId(component.id).setOptions(options);
            const value = localStorage.getItem(component.id) || null;
            localStorage.setItem(component.id + '_options', JSON.stringify(options));
            if (value !== null) {
                this.functions.getComponentForId(component.id).onPostInit(value);
            }
        } else {
            let componentResponse = this.functions.getComponentForId('@inputTypeLost');
            this.functions.getComponentForId('@inputTypeLost').getComponent().extras.options = [];
            this.functions.getComponentForId('@inputTypeLost').setOptions([]);
            localStorage.setItem('@inputTypeLost_options', '[]');
            this.functions.componentLogic.injectService.callService(componentResponse.getComponent(), this.functions.componentLogic.data).then(
                (result: any) => {
                    console.log("setSelectCauseSiniester -> callService -> result", result);
                    componentResponse.getComponent().extras.options = [];
                    let options = [];
                    for (let lost of result.listLossType) {
                        options.push({
                            key: lost.id,
                            value: lost.value
                        });
                    }
                    componentResponse.setOptions(options);
                    localStorage.setItem('@inputTypeLost_options', JSON.stringify(options));
                    const value = localStorage.getItem(component.id) || null;
                    if (value !== null) {
                        this.functions.getComponentForId(component.id).onPostInit(value);
                    }
                }, (reject: any) => {
                    componentResponse.getComponent().extras.options = [];
                    componentResponse.setOptions([]);
                    componentResponse.setValue('');
                    localStorage.removeItem('@inputTypeLost_options');
                }
            ).catch((reject: any) => {
                componentResponse.getComponent().extras.options = [];
                componentResponse.setOptions([]);
                componentResponse.setValue('');
                localStorage.removeItem('@inputTypeLost_options');
            });
        }
    }

    public setSelectProvince(component: IComponent , data: any) : void {
        if (data) {
            let options = [];
            for (let lost of data.listOffices) {
                options.push({
                    key: lost.id,
                    value: lost.value
                });
            }
            const value = localStorage.getItem(component.id) || null;
            this.functions.getComponentForId(component.id).setOptions(options);
            localStorage.setItem(component.id + '_options', JSON.stringify(options));
            if (value !== null) {
                this.functions.getComponentForId(component.id).onPostInit(value);
            }
        } else {
            let componentResponse = this.functions.getComponentForId('@inputCitySinister');
            this.functions.getComponentForId('@inputCitySinister').getComponent().extras.options = [];
            this.functions.getComponentForId('@inputCitySinister').setOptions([]);
            localStorage.setItem('@inputCitySinister_options', '[]');
            this.functions.componentLogic.injectService.callService(componentResponse.getComponent(), this.functions.componentLogic.data).then(
                (result: any) => {
                    componentResponse.getComponent().extras.options = [];
                    let options = [];
                    for (let lost of result.listCities) {
                        options.push({
                            key: lost.id,
                            value: lost.value
                        });
                    }
                    componentResponse.setOptions(options);
                    localStorage.setItem('@inputCitySinister_options', JSON.stringify(options));
                    const value = localStorage.getItem(component.id) || null;
                    if (value !== null) {
                        this.functions.getComponentForId(component.id).onPostInit(value);
                    }
                }, (reject: any) => {
                    componentResponse.getComponent().extras.options = [];
                    componentResponse.setOptions([]);
                    componentResponse.setValue('');
                    localStorage.removeItem('@inputCitySinister_options');
                }
            ).catch((reject: any) => {
                componentResponse.getComponent().extras.options = [];
                componentResponse.setOptions([]);
                componentResponse.setValue('');
                localStorage.removeItem('@inputCitySinister_options');
            });
        }
    }

    public showDamageVehicle(): void {
        const data = this.functions.componentLogic.data.getAllObject();
        const valueAffected = data['@inputPolicyAffected'];
        if (this.functions.inspectionExpress()) {
            if (this.functions.getComponentForId('@labelDatosRegistro')) {
                this.functions.getComponentForId('@labelDatosRegistro').isInvisible();
            }
            if (this.functions.getComponentForId('@inputInteractiveRigth')) {
                this.functions.getComponentForId('@inputInteractiveRigth').isInvisible();
            }
            if (this.functions.getComponentForId('@inputInteractiveLeft')) {
                this.functions.getComponentForId('@inputInteractiveLeft').isInvisible();
            }
            if (this.functions.getComponentForId('@inputInteractiveFront')) {
                this.functions.getComponentForId('@inputInteractiveFront').isInvisible();
            }
            if (this.functions.getComponentForId('@inputInteractiveBack')) {
                this.functions.getComponentForId('@inputInteractiveBack').isInvisible();
            }
        } else {
            if (valueAffected === 'Y') {
                this.functions.getComponentForId('@labelDatosRegistro').isVisible();
                this.functions.getComponentForId('@inputInteractiveRigth').isVisible();
                this.functions.getComponentForId('@inputInteractiveLeft').isVisible();
                this.functions.getComponentForId('@inputInteractiveFront').isVisible();
                this.functions.getComponentForId('@inputInteractiveBack').isVisible();
            }
        }
    }

    public showPolicyAffected(component: IComponent): void {
        const data = this.functions.componentLogic.data.getAllObject();
        const valueAffected = data['@inputPolicyAffected'];
        if (valueAffected === 'Y') {
            this.functions.getComponentForId('@inputDamageThird').isVisible();
            if (this.functions.getComponentForId('@inputSinisterDetail')) {
                this.functions.getComponentForId('@inputSinisterDetail').isVisible();
            }
            if (this.functions.getComponentForId('@inputLiquidSinister')) {
                this.functions.getComponentForId('@inputLiquidSinister').isVisible();
            }
            if (this.functions.getComponentForId('@inputMobilize')) {
                this.functions.getComponentForId('@inputMobilize').isVisible();
            }
            // this.functions.getComponentForId('@inputCountPiece').isVisible();
            if (this.functions.getComponentForId('@inputProforma')) {
                this.functions.getComponentForId('@inputProforma').isVisible();
            }
            this.functions.getComponentForId('@inputPolicyAffected').getComponent().extras.messageInformation = '';
        } else {
            this.functions.getComponentForId('@inputPolicyAffected').getComponent().extras.messageInformation = environment.msm.policy;
            this.functions.getComponentForId('@inputDamageThird').isInvisible();
            this.functions.getComponentForId('@inputSinisterDetail').isInvisible();
            this.functions.getComponentForId('@inputLiquidSinister').isInvisible();
            this.functions.getComponentForId('@inputMobilize').isInvisible();
            // this.functions.getComponentForId('@inputCountPiece').isInvisible();
            this.functions.getComponentForId('@inputProforma').isInvisible();
            // Not Show Piece
            this.functions.getComponentForId('@labelDatosRegistro').isInvisible();
            this.functions.getComponentForId('@inputInteractiveRigth').isInvisible();
            this.functions.getComponentForId('@inputInteractiveLeft').isInvisible();
            this.functions.getComponentForId('@inputInteractiveFront').isInvisible();
            this.functions.getComponentForId('@inputInteractiveBack').isInvisible();
        }
    }

    public evaluateQuadrantsSendPage(): boolean {
        const data = this.functions.componentLogic.data.getAllObject();
        const valueAffected = data['@inputPolicyAffected'];
        if (!this.functions.inspectionExpress() && valueAffected === 'Y') {
            const data = this.functions.componentLogic.data.getAllObject();
            const inputInteractiveBack: string[] = Functions.cleanArray((data['@inputInteractiveBack'] || '').split(','));
            const inputInteractiveFront: string[] = Functions.cleanArray((data['@inputInteractiveFront'] || '').split(','));
            const inputInteractiveRigth: string[] = Functions.cleanArray((data['@inputInteractiveRigth'] || '').split(','));
            const inputInteractiveLeft: string[] = Functions.cleanArray((data['@inputInteractiveLeft'] || '').split(','));
            if (inputInteractiveBack.length > 0) return true;
            if (inputInteractiveFront.length > 0) return true;
            if (inputInteractiveRigth.length > 0) return true;
            if (inputInteractiveLeft.length > 0) return true;
            return false;
        }
        return true;
    }
}