import { Injectable } from "@angular/core";
import { ITransversal } from "@zurich-dynamic-form/ui";
import { Observable } from "rxjs";
import { Behaviour } from "../behavior/behavior";
import { Utils } from "../utils/Utils";
import { IData } from "./i.data";

@Injectable({
  providedIn: 'root'
})
export class Data implements IData {

  private behavoiur: Behaviour;
  private arrayComponents: ITransversal[];
  private static instance: Data = null;

  private constructor() {
    this.behavoiur = Behaviour.getInstance();
    this.arrayComponents = [];
    const keys = Object.keys(localStorage);
    keys.forEach((key) => {
      const value = localStorage.getItem(key);
      this.setKeyValue(key, value);
    });
  }

  public static getInstance(): Data {
    if (Data.instance == null) {
      Data.instance = new Data();
    }
    return Data.instance;
  }

  public setComponentBehavior(component: ITransversal): void {
    const key = component.getComponent().extras.idService;
    const value = component.getValue();
    this.arrayComponents.push(component);
    if (this.behavoiur.readLocal(key)) {
      component.setValue(this.behavoiur.readCache(key));
    }
  }

  public remove(key: string): void {
    this.behavoiur.remove(key);
  }

  public addArray(component: ITransversal): void {
    this.arrayComponents.push(component);
  }

  public setKeyValue(key: string, value: string): void {
    this.behavoiur.setDataBox(key, value);
  }

  public getDataForBehavior(): Observable<any> {
    return this.behavoiur.getDataBox();
  }

  public readDataForComponent(url: string, data: any): void {
    for (let component of this.arrayComponents) {
      const urlPreload: string[] = (component.getComponent().urlPreload) ? component.getComponent().urlPreload.split('|') : [];
      urlPreload.forEach(
        ((urlComponent) => {
          if (urlComponent === url) {
            const value = Utils.searchParam(data, component.getComponent().extras.idService);
            component.setValuePostLoad(value);
          }
        })
      );
    }
  }

  public getComponents(): ITransversal[] {
    return this.arrayComponents;
  }

  public getValueForComponents(): any {
    let info = {};
    for (let component of this.arrayComponents) {
      info[component.getComponent().id] = component.getValue();
    }
    return info;
  }

  public getValueForComponent(key: string): string {
    return this.behavoiur.readCache(key);
  }

  public getAllObject(): any {
    return this.behavoiur.getAllObjects();
  }

}