import { Component, Injector, Input, OnInit } from '@angular/core';
import { Transversal } from '../ui.transversal';

@Component({
  selector: 'zurich-dynamic-form-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class Steps extends Transversal implements OnInit{

  @Input() steps: number;
  @Input() step: number;
  public stepsArray: number[];

  constructor() {
    super();
   }

  ngOnInit():void {
    this.stepsArray = [];
    for(let index = 1 ; index <= this.steps ; index++){
      this.stepsArray.push(index);
    }
  }

  onPostInit(dataSelect: string): void {
    
  }
  setOptions(options: any[]): void{}
}
