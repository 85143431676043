import { EventEmitter } from "@angular/core";
import { IComponent, IOptions } from "@zurich-dynamic-form/schemas";
import { HttpServiceService } from "@zurich-dynamic-form/services";
import { Observable } from "rxjs";
import { Functions, maskData } from '../utils/functions';
import { ITransversal } from "./ui.itransversal";
import { Data } from '@zurich-dynamic-form/data';
import { Security } from '../utils/security';
import { PipeMask } from "./input-mask/Pipes/PipeMask.transform";
import { LogicPage2 } from '../../../../apps/zurich-dynamic-form/src/app/components/component-logic/logicpages/logic.page2';

export abstract class Transversal implements ITransversal {

    public event: EventEmitter<any> = new EventEmitter();
    public component: IComponent;
    public value: string = '';
    public classSection: string;
    public isError: boolean = false;
    public service: HttpServiceService;
    public behoviur: Data;
    public isEditable: boolean = true;
    public isItemVisible = true;
    public messageInformation: string = '';
    public isFocusedButton: boolean;
    public isErrorServiceFlag: boolean;
    public isChangeService: boolean;

    getIsItemVisible(): boolean {
        return this.isItemVisible;
    }

    setDataComponent(_component: IComponent): void {
        this.isChangeService = false;
        this.isFocusedButton = true;
        this.component = _component;
        const keys = (this.behoviur) ? Object.keys(this.behoviur.getAllObject()) : [];
        let data = (this.behoviur) ? this.behoviur.getValueForComponent(this.component.id) : null;
        if (keys.indexOf(this.component.id) > -1) {
            this.isVisible();
        } else {
            if (this.component.extras.hasOwnProperty('visible')) {
                (this.component.extras.visible) ? this.isVisible() : this.isInvisible();
            }
        }
        if (data !== null) {
            const nameInput = this.behoviur.getAllObject()[this.component.id.concat('_postLoad')] || '';
            if (nameInput === '') {
                this.setValue(data);
            } else {
                this.setValuePostLoad(data, false);
            }
        } else {
            this.onPostInit(data);
        }
    }

    abstract onPostInit(dataSelect: string): void;

    setLabel(label: string): void {
        this.component.extras.title = label;
    }

    setIsChangeService(fail: boolean): void {
        localStorage.setItem(this.component.id + '_editable', '1');
        this.isChangeService = fail;
    }

    setSubLabel(sublabel: string): void {
        this.component.extras.subTitle = sublabel;
    }

    isVisible(): void {
        this.behoviur.setKeyValue(
            this.component.id,
            this.value
        );
        this.isItemVisible = true;
    }

    isInvisible(): void {
        if (this.behoviur) this.behoviur.remove(this.component.id);
        this.isItemVisible = false;
    }

    setValue(value: string): void {
        if (this.component.extras.transform) {
            if (this.component.extras.transform.uppercase) {
                this.value = value.toUpperCase();
            } else if (this.component.extras.transform.lowercase) {
                this.value = value.toLowerCase()
            } else {
                this.value = value;
            }
        } else {
            this.value = value;
        }
        this.isError = false;

        this.behoviur.setKeyValue(
            this.component.id,
            this.value
        );
        this.onPostInit(this.value);
    }

    setValuePostLoad(value: string, isPreload: boolean = true): void {
        this.isEditable = !(value !== '');
        const nameInput = this.component.id.concat('_postLoad');
        if (isPreload) {
            if (this.isEditable) {
                localStorage.removeItem(nameInput);
            } else {
                localStorage.setItem(nameInput, 'isPreload');
            }
        }
        this.setValue(value);
    }

    setDataBehavior(data: Data): void {
        this.behoviur = data;
    }

    isErrorService(): boolean {
        return this.isErrorServiceFlag;
    }

    setService(service: HttpServiceService, functionsObservablesArray: Observable<unknown>[]): void {
        this.service = service;
        if (this.component.autoUrl && this.component.autoUrl !== '') {
            const componentsIdsArray : string[] = ["@inputCauseSinister", "@inputCityProcessSinister", "@inputProvinceSinister"];
            if (componentsIdsArray.includes(this.component.id)) {
                if (functionsObservablesArray.length < 3) {
                    const componentFunction = function callServiceObservable(component: IComponent, behoviur: Data, event: EventEmitter<any>) {
                        return new Observable((observer) => {
                            service.callService(component, behoviur).then(
                                (data: any) => {
                                if (component.extras.method) {
                                    observer.next(event.emit({ comp: component, data }));
                                    observer.complete();
                                }
                                },
                                (error: any) => {
                                observer.error(error);
                                }
                            );
                        });
                    };
                    const observable = componentFunction(this.component, this.behoviur, this.event);
                    functionsObservablesArray.push(observable);
                }
            } else {
                this.service.callService(this.component, this.behoviur).then(
                    (data) => {
                        if (this.component.extras.method) {
                            this.event.emit({ comp: this.component, data });
                        }
                    }
                );
            }
        }
    }

    setVisibleForError(flag: boolean): void {

    }

    abstract setOptions(options: IOptions[]): void;

    sendEmiterEvent(): Observable<any> {
        return this.event;
    }

    setClass(classBackground: string): void {
        this.classSection = classBackground;
    }

    getComponent(): IComponent {
        return this.component;
    }

    getValue(): string {
        return this.value;
    }

    isValid(focus?: boolean): boolean {
        this.isError = false;
        if (!this.isItemVisible) {
            return true;
        }
        if (!this.isEditable) {
            return true;
        }
        if (!Functions.validarInformacion(this.value)) {
            return true
        }
        if (!this.component.extras.validators || this.component.extras.validators.length === 0) {
            if (this.value !== '' && this.component.extras.idSaveLocal && this.value !== null) {
                localStorage.setItem(this.component.extras.idSaveLocal, this.value);
            }
            return true;
        }
        let validator: boolean = true;
        let formGroup = { key: this.component.id, value: validator, validators: [] };
        for (let validatorValue of this.component.extras.validators) {
            if (validatorValue !== '') {
                switch (validatorValue.toLowerCase()) {
                    case ('require'):
                        formGroup.validators.push('required');
                        validator = (validator && this.value !== '' && this.value !== null);
                    case ('required'):
                        formGroup.validators.push('required');
                        validator = (validator && this.value !== '' && this.value !== null);
                        break;
                    case ('email'):
                        formGroup.validators.push('email');
                        validator = (validator && Functions.validateEmail(this.value));
                        break;
                    case ('identification'):
                        formGroup.validators.push('identification');
                        validator = (validator && Functions.validateIdentification(this.value));
                        break;
                    // case ('descriptionsinister'):
                    //     formGroup.validators.push('descriptionSinister');
                    //     validator = (validator && Functions.validateLength(this.value, 300));
                    //     break;
                    default:
                        formGroup.validators.push('regex ' + validatorValue);
                        validator = (validator && Functions.validateRegex(validatorValue, this.value));
                        break;
                }
            }
        }
        formGroup.value = validator;
        this.isError = (!validator);
        if (this.isError) {
            if (focus) {
                if (document.getElementById(this.component.id)) {
                    document.getElementById(this.component.id).focus();
                }
                window.location.hash = '#' + this.component.id;
            }
        } else {
            if (this.component.extras.idSaveLocal) {
                localStorage.setItem(this.component.extras.idSaveLocal, this.value);
            }
        }
        if (validator) {
            const input = document.getElementById(this.component.id) as HTMLInputElement;
            if (input && Functions.COMPONENTSMASK.includes(this.component.id)) {
                if (localStorage.getItem(this.component.id + '_postLoad') != 'isPreload') {
                    const value = Functions.maskInformation(this.component.id, this.value);
                    input.value = value;
                    this.value = value;
                    input.setAttribute('ng-reflect-model', value);
                }
            }
        }
        return validator;
    }

    searchInformation(): void {
        this.messageInformation = '';
        this.service.callService(this.component, this.behoviur).then(
            (data: any) => {
                this.isErrorServiceFlag = false;
                this.isFocusedButton = false;
                if (this.component.extras.method) {
                    this.event.emit({ comp: this.component, data });
                }
            }, (error) => {
                this.isFocusedButton = false;
                this.messageInformation = this.component.extras.messageInformation || '';
                this.isErrorServiceFlag = true;
                this.isChangeService = true;
                this.event.emit({ comp: this.component, data: null, err: true });
            }
        );
    }

    isNextFunction(): void {
        if (this.component.extras.method) {
            this.event.emit(this.component);
        }
    }
}
