<zurich-dynamic-form-header></zurich-dynamic-form-header>
<div class="container">
    <form class="form-container">
        <zurich-dynamic-form-steps *ngIf="isHeader" [steps]="steps" [step]="step"></zurich-dynamic-form-steps>
        <div class="container-message">
            <zurich-dynamic-form-information-message *ngIf="step === 1" [message]="messageInformation"></zurich-dynamic-form-information-message>
        </div>
        <ng-container #viewContainer>
        </ng-container>
    </form>
</div>