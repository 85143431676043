<div class="form-group" [ngClass]="classSection" uploadFile (fileDropped)="onFileDropped($event)" *ngIf="isItemVisible" >
    <label class="title-label">{{title}}</label>
    <zurich-dynamic-form-tooltip-information *ngIf="component.extras.help"
        [title]="component.extras.help.title"
        [body]="component.extras.help.description"
        [img]="component.extras.help.image" >
    </zurich-dynamic-form-tooltip-information>
    <div class="border-container-file">
      <input type="file" #fileDropRef [attr.accept]="typeFile" id="fileDropRef" (change)="fileBrowseHandler($event.target.files)"/>
      <div class="label-informative">
        <img *ngIf="label !== ''" src="/assets/img/img-plus.svg" alt="icon-plus">
        <label *ngIf="label !== ''">{{label}}</label>
      </div>
    </div>
    <p class="subtitle-label">{{subtitle}}</p>
    <div class="container-label-file-upload" *ngIf="nameFile !== ''">
      <img src="/assets/img/img-clip.svg" alt="icon-clip">
      <p>{{getName( nameFile )}}</p>
      <button type="button" class="close" aria-label="Close" (click)="deleteFile()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div *ngIf="widthBar != '0%'" class="container-progress-bar">
      <div class="bar-progress-bar" [style.width]="widthBar"></div>
    </div>

    <zurich-dynamic-form-information-message *ngIf="messageInformation && messageInformation !== ''" [message]="messageInformation"></zurich-dynamic-form-information-message>
  </div>