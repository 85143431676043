import { Functions } from "libs/ui/src/utils/functions";

export class Persistence {

    public save(object: any): void {
        const keys: string[] = Object.keys(object);
        for (let key of keys) {
            if (Functions.COMPONENTSMASK.includes(key)) {
                const value = object[key];
                const valueMask = Functions.maskInformation(key, value);
                localStorage.setItem(key, (valueMask !== null) ? valueMask : '');

            } else {
                localStorage.setItem(key, (object[key] !== null) ? object[key] : '');

            }
        }
    }

    public saveOriginalValue(object: any): void {
        const keys: string[] = Object.keys(object);
        for (let key of keys) {
            const value = object[key];
            this.valueOriginal(key, value);
        }
    };

    public read(key: string): string {
        return localStorage.getItem(key) || '';
    }

    public removeElement(key: string): void {
        localStorage.removeItem(key);
    }

    public valueOriginal(key: string, object: any) {
        if (Functions.COMPONENTSMASK.includes(key)) {
            if (Functions.validarInformacion(object)) {
                localStorage.setItem(key + '_original', object != null ? object : '');
            }
        }
    }
}