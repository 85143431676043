<div class="form-group" [ngClass]="classSection" *ngIf="isItemVisible">
    <label *ngIf="component.extras.title !== ''" class="title-label" [for]="component.id">{{component.extras.title}}</label>
    <zurich-dynamic-form-tooltip-information *ngIf="component.extras.help && component.extras.help.description"
        [title]="component.extras.help.title"
        [body]="component.extras.help.description"
        [img]="component.extras.help.image" >
    </zurich-dynamic-form-tooltip-information>

    <zurich-dynamic-form-slide-tooltip *ngIf="component.extras.help && component.extras.help.options"
        [title]="component.extras.help.title"
        [options]="component.extras.help.options"
    ></zurich-dynamic-form-slide-tooltip>

    <p *ngIf="component.extras.subTitle !== ''" class="subtitle-label">{{component.extras.subTitle}}</p>

    <input 
    [type]="type" 
    class="form-control input-portal" 
    [ngClass]="{'error-class': isError  , 'background-preload' : !isEditable }" 
    [name]="id" 
    [id]="id" 
    [(ngModel)]="value" 
    [disabled]="!isEditable"
    (keyup)="setValue(value)"
    (keydown.enter)="onEnter()"
    (blur)="isValid()"
    />
    <button type="button" *ngIf="isButtonSearch" class="button-into-input2"
    [disabled]="(!(value !== '') || isError)"
    [ngClass]="{'btn-disabled' : (!(value !== '') || isError)}"
    (click)="searchInformation()">
        <!-- <img src="/assets/img/img-search.svg" alt="Search">  -->
        Buscar
    </button>

    <!-- [ngClass]="{'animated-click animation-click' : (value !== '' && isFocusedButton)}" -->

    <zurich-dynamic-form-error *ngIf="isError"></zurich-dynamic-form-error>
    <zurich-dynamic-form-information-message *ngIf="messageInformation !== ''" [message]="messageInformation"></zurich-dynamic-form-information-message>
</div>