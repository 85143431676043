<div class="container-steps" [ngClass]="classSection">
    <div class="container-step" *ngFor="let ind of stepsArray; let i = index">
        <div class="step" [ngClass]="{'step-select' : ind == step , 'step-last' : (i + 1) < step  }">
            <div class="step-item">{{ind}}</div>
        </div>
        <div *ngIf=" (i+1) < stepsArray.length " [ngClass]="(i + 1) < step ? 'container-box-unselect' : 'container-box-select'"></div>
    </div>
</div>


