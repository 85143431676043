import { AfterViewInit, Component, Input, OnInit, Pipe, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'zurich-dynamic-form-information-message',
  templateUrl: './information-message.component.html',
  styleUrls: ['./information-message.component.scss']
})
export class InformationMessageComponent implements AfterViewInit {

  @ViewChild('dataContainer') dataContainer
  @Input() message: string;

  constructor() { }

  ngAfterViewInit(): void {
    this.dataContainer.nativeElement.innerHTML = this.message;
  }

}
