import { Component, OnInit } from '@angular/core';
import { Transversal } from '../ui.transversal';

@Component({
  selector: 'zurich-dynamic-form-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class Checkbox extends Transversal{


  constructor() {
    super();
   }

  onPostInit(dataSelect: string): void {
  }
  setOptions(options: any[]): void{}

  setParameter(): void {
    this.setValue(
      (this.value) ? 'Y' : 'N'
    );
  }
}
