import { Component, OnInit } from '@angular/core';
import { Transversal } from '../ui.transversal';

@Component({
  selector: 'zurich-dynamic-form-viewinteractive',
  templateUrl: './viewinteractive.component.html',
  styleUrls: ['./viewinteractive.component.scss']
})
export class Viewinteractive extends Transversal{

  public title: string;
  public image: string;
  public indexSelect: number[];
  public id: string;

  constructor() { 
    super();
  }

  onPostInit(dataSelect: string): void {
    this.id = this.component.id;
    this.title = this.component.extras.title;
    this.image = this.component.extras.urlImage;
    dataSelect = (dataSelect) ? dataSelect : '';
    this.indexSelect = dataSelect.split(",").map(Number);
    const ind = this.indexSelect.indexOf(0);
    this.indexSelect = (ind > -1 ) ? this.indexSelect.splice(ind , 0) : this.indexSelect ;
  }


  public onSelect(index: number){
    if ( !(this.indexSelect.indexOf(index) > -1) ) {
      this.indexSelect.push(index);
    } else {
      const flag = this.indexSelect.indexOf(index);
      this.indexSelect.splice( flag , 1 );
    }
    this.setValue(this.indexSelect.toString());
  }
  setOptions(options: any[]): void{}

}
