import { IComponent } from "@zurich-dynamic-form/schemas";
import { DeviceDetectorService } from 'ngx-device-detector';
import { Security } from "./security";

export class maskData {
    valueMask: string;
    valueEncrypt: string;
    value: string
};

export class Functions {

    public static EXTENSIONS = {
        img: ['jpg', 'jpeg', 'bmp', 'png', 'gif'],
        pdf: ['pdf'],
        xls: ['xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xls', 'xlt', 'xls', 'xml', 'xlam', 'xla', 'xlw', 'xlr'],
        txt: ['txt'],
        csv: ['csv'],
        html: ['html'],
        video: ['webm', 'mkv', 'flv', 'flv', 'vob', 'ogv', 'ogg', 'drc', 'gif', 'gifv', 'mng', 'avi', 'MTS', 'M2TS', 'TS', 'mov', 'qt', 'wmv', 'yuv', 'rm', 'rmvb', 'viv', 'asf', 'amv', 'mp4', 'm4p', 'm4v', 'mpg ', 'mp2', 'mpeg', 'mpe', 'mpv', 'mpg', 'mpeg', 'm2v', 'm4v', 'svi', '3gp', '3g2', 'mxf', 'roq', 'nsv', 'flv ', 'f4v ', 'f4p ', 'f4a ', 'f4b'],
        audio: ['3gp', 'aa', 'aac', 'aax', 'act', 'aiff', 'alac', 'amr', 'ape', 'au', 'awb', 'dss', 'dvf', 'flac', 'gsm', 'iklax', 'ivs', 'm4a', 'm4b', 'm4p', 'mmf', 'mp3', 'mpc', 'msv', 'nmf', 'ogg', 'oga', 'mogg', 'opus', 'org', 'ra ', 'rm', 'raw', 'rf64', 'sln', 'tta', 'voc', 'vox', 'wav', 'wma', 'wv', 'webm', '8svx', 'cda'],
    }



    public static COMPONENTSMASK = [
        "@inputNameRegister",
        "@inputLastNameRegister",
        "@inputCellPhoneRegister",
        "@inputPhoneRegister",
        "@inputEmailRegister",
        "@inputNumberChasisCarRegistry2",
        "@inputNumberCarIdentityRegistry",
        "@inputNumberMotorCarRegistry",
        "@inputBrandCarRegistry",
        "@inputModelCarRegistry",
        "@inputNumberMotorCarRegistry2",
        "@inputColorCarRegistry",
    ];

    public static getPlaceHolder(component: IComponent, deviceDetector: DeviceDetectorService): string {
        let placeholder = '';
        if (component.extras.placeholder && component.extras.placeholder !== '') {
            placeholder = component.extras.placeholder;
        } else {
            if (component.extras.placeholderDesktop && component.extras.placeholderMobile) {
                if (deviceDetector.isDesktop()) {
                    placeholder = component.extras.placeholderDesktop;
                } else if (deviceDetector.isMobile() || deviceDetector.isTablet()) {
                    placeholder = component.extras.placeholderMobile;
                } else {
                    placeholder = '';
                }
            } else {
                placeholder = '';
            }
        }
        return placeholder;
    }

    public static validateEmail(value: string): boolean {
        const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const patron = /.*@.*\..*/;
        return regex.test(value) || patron.test(value);
    }

    public static maskInformation(componetId: string, value: string): string {
        const valueMask = Functions.COMPONENTSMASK.includes(componetId)
            ? Functions.encryptData(value, componetId) : value
        return valueMask;
    }

    public static validateLength(value: string, length: number): boolean {
        return value.length < length;
    }
    public static validarInformacion(informacion: string): boolean {
        // Expresión regular para comprobar si la información tiene asteriscos
        const asteriscosRegex = /\*/g;
        // Comprobamos si la información tiene asteriscos y números con 4 ceros
        const tieneAsteriscos = asteriscosRegex.test(informacion);

        // Si la información cumple con alguna de las condiciones, no se guarda en el local storage
        if (tieneAsteriscos) {
            return false;
        }
        return true;
    }




    public static encryptData(value: string, componetid?: string): string {
        if (value != '' && value != null) {
            if (/^0\d+$/.test(value)) {
                let dataReturn = '';
                for (let i = 0; i < 3; i++) {
                    dataReturn += value[i];
                }
                dataReturn += '****';
                for (let i = (value.length - 3); i < value.length; i++) {
                    dataReturn += value[i];
                }
                return dataReturn;
            } else {
                let inicio = value[0];
                let final = value[value.length - 1];
                let medio = value.toString().slice(1, -1).replace(/[a-zA-Z]/g, '*');
                return inicio + medio + final
            }
        }
        return value
    }

    public static encryptLicense(value: string): string {
        if (value != '') {
            let dataReturn = '';
            for (let i = 0; i < 3; i++) {
                dataReturn += value[i];
            }
            dataReturn += '****';
            for (let i = (value.length - 3); i < value.length; i++) {
                dataReturn += value[i];
            }
            return dataReturn;
        }

        return value;
    }


    public static validateIdentification(value: string): boolean {
        const regexCedula = /^\d{10}$/;
        const regexRuc = /^\d{10}001$/;
        const regexPassport = /^[a-zA-Z0-9]{1,20}$/;
        const typeIdentification = localStorage.getItem('@inputTypeDocumentIdentity') || '';
        if (typeIdentification === 'c') {
            return regexCedula.test(value);
        } else if (typeIdentification === 'r') {
            return regexRuc.test(value);
        } else if (typeIdentification === 'p') {
            return regexPassport.test(value);
        } else {
            throw new Error('Unknown type of identification: ' + typeIdentification);
        }
    }



    public static validateRegex(regex: string, value: string): boolean {
        const regexencrypt = /\*{2,}/;
        if (regexencrypt.test(value)) {
            return true;
        }

        let reg = new RegExp(regex);
        let val = reg.test(value);
        return (value !== '') ? val : true;
    }

    public static validateExtension(nameFile: string, type: string[]): boolean {
        const arraySplit = nameFile.split('.');
        const extension = arraySplit[(arraySplit.length - 1)] || '';
        if (extension !== '' && (type.length > 0)) {
            if (type.indexOf('all') > -1) {
                return true;
            }
            let evalueKey = false;
            type.forEach(
                (typeEvaluate) => {
                    const values = Functions.EXTENSIONS[typeEvaluate];
                    if (values.indexOf(extension) > -1) {
                        evalueKey = true;
                    }
                }
            );
            return evalueKey;
        }
        return false;
    }

    public static cleanArray(actual: string[]): string[] {
        var newArray = new Array();
        for (var i = 0, j = actual.length; i < j; i++) {
            let value = actual[i] || ''
            value = value.replace('\n', '');
            value = value.replace('\r', '');
            value = value.replace('\t', '');
            value = value.trim();
            if (value !== '' && value.charCodeAt(0) > 20) {
                newArray.push(actual[i]);
            }
        }
        return newArray;
    }

}