<div class="modal-content">
    <ng-container #containerModal></ng-container>
</div>

<ng-template #load>
    <div class="modal-body">
        <p class="label-primary">Buscando datos…</p>
        <div class="container-icon">
            <img src="assets/img/img-search-blue.svg">
        </div>
        <p class="label-secondary">Espera un momento mientras consultamos la información que ingresaste</p>
        <div class="progress-line"></div>
    </div>
</ng-template>

<ng-template #reportExist>
    <div class="modal-body">
        <p class="label-primary">Número de placa encontrado</p>
        <div class="container-icon">
            <img style="width: 100%;" src="assets/img/img-information.svg">
        </div>
        <p class="label-secondary">Hemos buscado en nuestros registros y esta placa actualmente presenta un reporte de siniestro en el mismo dia<br>¿Tienes otro siniestro para reportar?</p>
        <div class="form-group group-buttons">
            <button type="button" (click)="onClickAssertive()" class="btn btn-primary button-footer button-asertive" >Si</button>
            <button type="button" (click)="onClickClear()" class="btn btn-primary button-footer button-clear" >No</button>
        </div>
    </div>
</ng-template>

<ng-template #reportIncomplete>
    <div class="modal-body">
        <p class="label-primary">Reporte de siniestro encontrado</p>
        <div class="container-icon">
            <img style="width: 100%;" src="assets/img/img-information.svg">
        </div>
        <p class="label-secondary">Ya te encontrabas diligenciando un reporte de siniestro con la información ingresada<br>¿Quieres continuar donde lo dejaste?</p>
        <div class="form-group group-buttons">
            <button type="button" (click)="onClickAssertive()" class="btn btn-primary button-footer button-asertive" >Continuar con el reporte</button>
            <button type="button" (click)="onClickClear()" class="btn btn-primary button-footer button-clear" >Generar nuevo reporte</button>
        </div>
    </div>
</ng-template>

<ng-template #erno>
    <div class="modal-body">
        <p class="label-primary">¡Ups! Tuvimos un problema</p>
        <div class="container-icon">
            <img style="width: 100%;" src="assets/img/img-warning.svg">
        </div>
        <p class="label-secondary">Lo sentimos, en este momento no pudimos consultar la información, por favor inténtalo nuevamente</p>
        <div class="form-group group-buttons">
            <button type="button" (click)="onClickAssertive()" class="btn btn-primary button-footer button-asertive" >Intentar de nuevo</button>
            <button type="button" (click)="onClickClear()" class="btn btn-primary button-footer button-clear" >Cancelar</button>
        </div>
    </div>
</ng-template>

<ng-template #upload>
    <div class="modal-body">
        <div class="alert alert-danger" role="alert">
            Upload Modal
        </div>
    </div>
</ng-template>

<ng-template #confirmDeleteFile>
    <div class="modal-body">
        <p class="label-primary">Eliminar archivo adjunto</p>
        <div class="container-icon">
            <img style="width: 83%;margin: 0rem auto;display: block;margin-top: -.6rem;" src="assets/img/img-trash.svg">
        </div>
        <p class="label-secondary">¿Estás seguro que quieres eliminar el archivo seleccionado?</p>
        <div class="form-group group-buttons">
            <button type="button" (click)="onClickAssertive()" class="btn btn-primary button-footer button-asertive" >Confirmar eliminación</button>
            <button type="button" (click)="onClickClear()" class="btn btn-primary button-footer button-clear" >Cancelar</button>
        </div>
    </div>
</ng-template>

<ng-template #noConfirmAutorize>
    <div class="modal-body">
        <p class="label-primary">Confirmar términos y condiciones</p>
        <div class="container-icon">
            <img style="width: 100%;"  src="assets/img/img-information.svg">
        </div>
        <p class="label-secondary">Debes autorizar a Zurich para realizar el respectivo tratamiento de la información solicitada.</p>
        <div class="form-group">
            <button type="button" (click)="onClickAssertive()" class="btn btn-primary button-footer button-asertive group-button" >Continuar</button>
        </div>
    </div>
</ng-template>

<ng-template #noSelectPiece>
    <div class="modal-body">
        <p class="label-primary">Seleccionar piezas afectadas</p>
        <div class="container-icon">
            <img style="width: 100%;"  src="assets/img/img-information.svg">
        </div>
        <p class="label-secondary">Debes seleccionar al menos un lugar de daño afectado en las imágenes del vehículo.</p>
        <div class="form-group">
            <button type="button" (click)="onClickAssertive()" class="btn btn-primary button-footer button-asertive group-button" >Continuar</button>
        </div>
    </div>
</ng-template>

<ng-template #ernoSendReport>
    <div class="modal-body">
        <p class="label-primary">¡Ups! Tuvimos un problema</p>
        <div class="container-icon">
            <img style="width: 100%;" src="assets/img/img-warning.svg">
        </div>
        <p class="label-secondary">Lo sentimos, en este momento no pudimos enviar tu reporte, por favor inténtalo nuevamente</p>
        <div class="form-group group-buttons">
            <button type="button" (click)="onClickAssertive()" class="btn btn-primary button-footer button-asertive" >Intentar de nuevo</button>
            <button type="button" (click)="onClickClear()" class="btn btn-primary button-footer button-clear" >Cancelar</button>
        </div>
    </div>
</ng-template>