import { Component, EventEmitter } from '@angular/core';
import { Transversal } from '../ui.transversal';

@Component({
  selector: 'zurich-dynamic-form-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class Button extends Transversal{


  public title: string;
  public classButton: string;

  constructor() { 
    super();
  }

  onPostInit(dataSelect: string): void {
    this.title = this.component.extras.title;
    this.classButton = this.component.extras.type || 'button-asertive';
  }

  public onClickButton():void{
    this.event.emit(this.component);
  }
  setOptions(options: any[]): void{}
}
