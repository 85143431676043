import { Component, OnInit } from '@angular/core';
import { IComponent, IOptions } from '@zurich-dynamic-form/schemas';
import { timeout } from 'rxjs/operators';
import { Transversal } from '../ui.transversal';

@Component({
  selector: 'zurich-dynamic-form-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButton extends Transversal implements OnInit{

  public title: string;
  public id: string;
  public subtitle: string;
  public options: IOptions[];

  constructor() {
    super();
  }
  ngOnInit(): void {
    setTimeout(()=> {
      if (this.value && this.value != '') {this.isNextFunction();}
    }, 800);
  }

  onPostInit(dataSelect: string): void {
    this.id = this.component.id;
    this.title = this.component.extras.title;
    this.subtitle =this.component.extras.subTitle;
    this.options = this.component.extras.options;
    this.value = dataSelect;

      
  }
  setOptions(options: any[]): void{}

  changeValue(): void {
    this.setValue(this.value);
    this.isNextFunction();
  }
}
