export class Functions {

    public static validateEmail( value: string): boolean {
        const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return Functions.validateRegex( regex , value);
    }

    public static validateRegex( regex , value: string): boolean{
        return regex.test(value);
    }

    public static getHourFormat( hourInput: string ) : string {
        const hour = Number( hourInput.split(':')[0] );
        const minute = Number( hourInput.split(':')[1] );
        let finaltime = '';
        finaltime = finaltime.concat('' + ( (hour >= 10)? ''+hour : '0'+hour ) );
        finaltime = finaltime.concat(':');
        finaltime = finaltime.concat('' + ( (minute >= 10)? ''+minute : '0'+ minute ) );
        finaltime = finaltime.concat(':00');
        return finaltime;
    }

    public static validateQuadrant(inputs: string[]): string[] {
        let array = ['0','0','0','0'];
        inputs.forEach(
            (q) => {
                if (q === '1'){
                    array[0] = '1'
                } else if (q === '2'){
                    array[1] = '1'
                } else if (q === '3'){
                    array[2] = '1'
                } else {
                    array[3] = '1'
                }
            }
        );
        return array;
    }


    public static cleanArray( actual: string [] ){
        var newArray = new Array();
        for( var i = 0, j = actual.length; i < j; i++ ){
            if ( actual[ i ] && actual[ i ] !== '' ){
              newArray.push( actual[ i ] );
          }
        }
        return newArray;
      }

      public static cleanAllArray( actual: string [] ): string[]{
        var newArray = new Array();
        for( var i = 0, j = actual.length; i < j; i++ ){
            let value = actual[ i ] || ''
            value = value.replace('\n','');
            value = value.replace('\r','');
            value = value.replace('\t','');
            value = value.trim();
            if ( value !== '' && value.charCodeAt(0) > 20){
              newArray.push( actual[ i ] );
          }
        }
        return newArray;
    }
}