import { DecryptService } from './security/decrypt.service';
import { SecurityService } from './security/security.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment, service } from '@env/environment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ZurichMetricsService } from '@zpaas/sdk-angular';
import { AuthService } from 'libs/services/src/lib/oauh/http-service.service';
import { ModalComponent } from 'libs/ui/src/lib/modal/modal.component';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'zurich-dynamic-form-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  public static CENTER: NgbModalOptions = {
    size: 'dialog-centered modal-xl',
    backdrop: 'static',
    keyboard: false
  };

  constructor(private zurichMetricsService: ZurichMetricsService, private route: Router, private routeActive: ActivatedRoute,
     private modalService: NgbModal, securityService: SecurityService, decryptService: DecryptService) {
    let data =  decryptService.newData(service.tokenCognito.toString());
    let splitter = data.split(":");
    securityService.setUser(splitter[0]);
    securityService.setPassword(splitter[1]);
    securityService.setApikey(decryptService.newData(service.apiKeyCognito.toString()))
  }

  ngOnInit(): void {
    this.zurichMetricsService.ApplicationStarted({ applicationName: environment.appName });
    const rout = window.location.pathname;
    if (rout !== '/complete') {
      const sesion = sessionStorage.getItem('inSession') || '';
      if (sesion === '') {
        this.defaultValues();
      } else {
        const page = localStorage.getItem('PAGE') || 1;
        this.route.navigate([('/form/').concat(String(page))]);
      }
    }
  }


  private defaultValues(): void {
    const keys = Object.keys(localStorage);
    if (keys.length > 3) {
      const page = localStorage.getItem('PAGE') || 1;
      const modal = this.modalService.open(ModalComponent, AppComponent.CENTER);
      modal.componentInstance.data = {
        type: 'reportIncomplete',
        value: 0
      };
      modal.result.then(
        (result) => {
          if (result) {
            this.route.navigate([('/form/').concat(String(page))]);
          } else {
            localStorage.clear();
            this.loadInformation();
            window.location.reload();
          }
        });
    } else {
      this.loadInformation();
    }
  }

  private loadInformation(): void {
    let uuid = localStorage.getItem('UUID') || '';
    if (uuid === '') {
      uuid = uuidv4();
      localStorage.setItem('UUID', uuid);
    }
    const tmp = localStorage.getItem('@ramoForFilter') || null;
    if (tmp === null) {
      localStorage.setItem('@ramoForFilter', '7274496');
    }
    this.route.navigate([('/form/1')]);
  }
}
