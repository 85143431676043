import { Injectable } from '@angular/core';
import { IComponent } from '@zurich-dynamic-form/schemas';
import { FormControl, FormGroup } from '@angular/forms';
import { ITransversal } from '@zurich-dynamic-form/ui';

@Injectable()
export class FormService {

  private components: ITransversal[];
  
  constructor() { 
    this.components = [];
  }

  public setComponent(component: ITransversal): void {
    this.components.push(component);
  }

  public clearComponents(): void {
    this.components = [];
  }

  public validateFormControl(): boolean{
    let validateForm = true;
    this.components.forEach(
      (component: ITransversal) => {
        if ( validateForm && !component.isValid(true) ) {
          validateForm = false;
          return;
        }
      }
    );
    return validateForm;
  }
}

