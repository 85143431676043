<div class="form-group" [ngClass]="classSection"  *ngIf="isItemVisible" [id]="id">
    <label *ngIf="title !== ''" class="title-label" [for]="id">{{title}}</label>
    <zurich-dynamic-form-tooltip-information *ngIf="component.extras.help && component.extras.help.description"
        [title]="component.extras.help.title"
        [body]="component.extras.help.description"
        [img]="component.extras.help.image" >
    </zurich-dynamic-form-tooltip-information>

    <p *ngIf="subtitle !== ''" class="subtitle-label">{{subtitle}}</p>
    
    <zurich-dynamic-form-error *ngIf="isError"></zurich-dynamic-form-error>
    <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [ngClass]="{'error-class': isError }" (change)="changeValue()"  [(ngModel)]="value">
      <label ngbButtonLabel class="bg-light btn-select-group" *ngFor="let option of options">
        <input ngbButton type="radio" [value]="option.key"> {{option.value}}
      </label>
    </div>
    <zurich-dynamic-form-information-message *ngIf="component.extras.messageInformation" [message]="component.extras.messageInformation"></zurich-dynamic-form-information-message>
    <zurich-dynamic-form-information-message *ngIf="messageInformation !== ''" [message]="messageInformation"></zurich-dynamic-form-information-message>
</div>