import { Component, OnInit } from '@angular/core';
import { IComponent } from '@zurich-dynamic-form/schemas';
import { Transversal } from '../ui.transversal';

@Component({
  selector: 'zurich-dynamic-form-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextArea extends Transversal{
  
  title: string;
  subtitle: string;
  id: string;

  constructor() {
    super();
   }

   onPostInit(dataSelect: string): void {
    this.id = this.component.id;
    this.title = this.component.extras.title;
    this.subtitle =this.component.extras.subTitle;
  }
  setOptions(options: any[]): void{}
}
