<div class="form-group" [ngClass]="classSection"  *ngIf="isItemVisible">
    <label *ngIf="title !== ''" class="title-label" [for]="id">{{title}}</label>
    <zurich-dynamic-form-tooltip-information *ngIf="component.extras.help"
        [title]="component.extras.help.title"
        [body]="component.extras.help.description"
        [title]="component.extras.help.image" >
    </zurich-dynamic-form-tooltip-information>

    <p *ngIf="subtitle !== ''" class="subtitle-label">{{subtitle}}</p>
    <input [ngClass]="{'error-class': isError }" class="form-control input-portal" 
    [id]="id" placeholder="" name="dp" (focus)="dateSelect(d)" 
    [(ngModel)]="modelValue" ngbDatepicker  #d="ngbDatepicker" [markDisabled]="markDisabled">
    
    <button class="button-into-input calendar" (click)="dateSelect(d)" type="button">
        <img style="width: 90%;margin: 0;" src="/assets/img/img-calendar.svg" alt="Calendar">
    </button>
    <zurich-dynamic-form-error *ngIf="isError"></zurich-dynamic-form-error>
    <zurich-dynamic-form-information-message *ngIf="messageInformation && messageInformation !== ''" [message]="messageInformation"></zurich-dynamic-form-information-message>
</div>