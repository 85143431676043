import { Component, OnInit } from '@angular/core';
import { Transversal } from '../ui.transversal';

@Component({
  selector: 'zurich-dynamic-form-hidden-data',
  templateUrl: './hidden-data.component.html',
  styleUrls: ['./hidden-data.component.scss']
})
export class HiddenData extends Transversal{

  constructor() { 
    super();
  }

  onPostInit(dataSelect: string): void {

  }
  setOptions(options: any[]): void{
    
  }
}

