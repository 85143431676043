import { environment } from '@env/environment';
import * as CryptoJS from 'crypto-js';

export class Security {

    public static encription(text: string): string {
        let _key = CryptoJS.enc.Utf8.parse(environment.crypto.secret);
        let _iv = CryptoJS.enc.Utf8.parse(environment.crypto.iv);

        let encrypted = CryptoJS.AES.encrypt(
            text,
            _key,
            {
                iv: _iv,
                mode: CryptoJS.mode.CBC,
                format: CryptoJS.format.Hex
            }
        );

        let encripcion = encrypted;
        return encripcion.toString();
    }

    public static decription(text: string): string {
        let _key = CryptoJS.enc.Utf8.parse(environment.crypto.secret);
        let _iv = CryptoJS.enc.Utf8.parse(environment.crypto.iv);
        const decryptedStringHex = CryptoJS.AES.decrypt(
            text,
            _key,
            {
                iv: _iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.NoPadding,
                format: CryptoJS.format.Hex,
            });
        const data = Security.hexadecimalToText(decryptedStringHex.toString());
        return data;
    }
    public static hexadecimalToText(str1: string): string {
        var hex = str1.toString();
        var str = '';
        for (var n = 0; n < hex.length; n += 2) {
            str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
        }
        return str;
    }

    public static textToHexadecimal(str: string): string {
        var arr1 = [];
        for (var n = 0, l = str.length; n < l; n++) {
            var hex = Number(str.charCodeAt(n)).toString(16);
            arr1.push(hex);
        }
        return arr1.join('');
    }
}