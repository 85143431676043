import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentLogicComponent } from './components/component-logic/component-logic.component';
import { UiModule } from '@zurich-dynamic-form/ui';
import { FormService, HttpGeneralService, HttpServiceService, HttpInterceptorService } from '@zurich-dynamic-form/services';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageResultComponent } from './components/page-result/page-result.component';
import { PipeMask } from 'libs/ui/src/lib/input-mask/Pipes/PipeMask.transform';
import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { ZurichMetricsServiceModule } from '@zpaas/sdk-angular';
import { environment } from '@env/environment';
import { HttpTokenInterceptor } from '../../../../libs/services/src/lib/oauh/http-token-interceptor.service';

@NgModule({
  declarations: [AppComponent, ComponentLogicComponent, PageResultComponent],
  imports: [HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    UiModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    ZurichMetricsServiceModule,
    LoggerModule.forRoot({
      serverLoggingUrl: 'https://serviciosias.zurichseguros.com.ec/zurich/api/logger/1.0.0/logger',
      level: NgxLoggerLevel.INFO,
      serverLogLevel: NgxLoggerLevel.INFO,
      disableConsoleLogging: false
    })],
  providers: [
    PipeMask,
    HttpGeneralService,
    HttpServiceService,
    FormService,
    NGXLogger,
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: 'instrumentationKey', useValue: environment.insightsClient.instrumentationKey }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
