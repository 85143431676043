import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

/**
 * @author mmontaguano
 */
@Injectable({
    providedIn: 'root'
})
export class DecryptService {

    lkeve = CryptoJS.enc.Utf8.parse ("7a75726963686974");
    iv = CryptoJS.enc.Utf8.parse ('706173737a7572696368'); 

    constructor() {
    }

    newData(data: string){
        let encryptedHexStr = CryptoJS.enc.Hex.parse(data);
        let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
        let decrypt = CryptoJS.AES.decrypt(srcs, this.lkeve, { iv: this.iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
        return decryptedStr.toString();
    }

}
