import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'zurich-dynamic-form-page-result',
  templateUrl: './page-result.component.html',
  styleUrls: ['./page-result.component.scss']
})
export class PageResultComponent implements OnInit {

  public urlImage = 'assets/img/header-complete-desktop.png';
  public title : SafeHtml = '';

  constructor(private route: ActivatedRoute , private sanitizer:DomSanitizer) { }

  ngOnInit(): void {
    const value = sessionStorage.getItem('complete') || '';
    if (value == '') {
      window.location.href = '/';
    }
    this.route.queryParams.subscribe(
      (querys: {status: string , number: string}) => {
        if (querys.status === 'ok') {
          this.title = this.sanitizer.bypassSecurityTrustHtml(`El número de reclamo es <span style=" font-weight: bold; text-align: center; padding-bottom: 3px;color: #253760; font-size: 16px; font-family: "ZurichSansBold";">\"${querys.number}\"</span>. Dentro de las siguientes 48 horas recibirá un correo al email registrado en este formulario, donde explicaremos el proceso a seguir y la documentación necesaria para el sustento del reclamo.`);
        } else {
          this.title = this.sanitizer.bypassSecurityTrustHtml(`Dentro de las siguientes 48 horas recibirá un correo al email registrado en este formulario, donde explicaremos el proceso a seguir y la documentación necesaria para el sustento del reclamo.`);
        }
      }
    )
  }

  loadUrl(): void {
    sessionStorage.clear();
    window.location.href = 'https://www.zurichseguros.com.ec';
  }

}
