import { Component, ComponentFactoryResolver, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ITransversal , Components } from '@zurich-dynamic-form/ui';
import { FormService, HttpGeneralService, HttpServiceService } from '@zurich-dynamic-form/services';
import { APPLICATION } from '../../utils/constants';
import { IComponent, IFormConfiguration, ITemplate } from '@zurich-dynamic-form/schemas';
import { ActivatedRoute, Router } from '@angular/router';
import { Data } from '@zurich-dynamic-form/data';
import { FunctionsComponent } from './component-logic.functions';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@env/environment';
import { PipeMask } from 'libs/ui/src/lib/input-mask/Pipes/PipeMask.transform';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from 'libs/services/src/lib/oauh/http-service.service';
import { ZurichMetricsService } from '@zpaas/sdk-angular';
import { LogicPage2 } from './logicpages/logic.page2';
import { Observable, concat } from 'rxjs';


@Component({
  selector: 'zurich-dynamic-form-component-logic',
  templateUrl: './component-logic.component.html',
  styleUrls: ['./component-logic.component.scss']
})
export class ComponentLogicComponent implements OnInit {

  @ViewChild('viewContainer', {read: ViewContainerRef }) ngContainer: ViewContainerRef;
  public data: Data = null;
  public isHeader: boolean;
  public step: number;
  public steps: number;
  public functions: FunctionsComponent;
  public messageInformation: string;
  private logicpage2: LogicPage2;

  constructor( private compFactoryResolver: ComponentFactoryResolver , private servicePublic: HttpGeneralService,
               private activatedRoute: ActivatedRoute , public serviceForm: FormService ,
               public router: Router,
               public pipeMask:PipeMask,
               private logger: NGXLogger,
               private auth: AuthService,
               public injectService: HttpServiceService ,
               public modalService: NgbModal,
               private zurichMetricsService: ZurichMetricsService) {
    this.isHeader = false;
    this.messageInformation = environment.msm.information;
    this.step = parseInt(this.activatedRoute.snapshot.params.step);
    if (this.data == null) {
      this.data = Data.getInstance();
    }
   }

  ngOnInit(): void {
    this.functions = new FunctionsComponent(this.logger, this.auth, this.zurichMetricsService);
    this.functions.setComponent(this);
    const token = sessionStorage.getItem("token");
    sessionStorage.clear();
    sessionStorage.setItem("token", token);
    this.zurichMetricsService.Navigation({userID: '', target: window.location.href, applicationName: environment.appName});
  }

  ngAfterViewInit(): void {
    this.ngContainer.clear();
    console.log("ngAfterViewInit -> loadTemplate");
    this.loadTemplate();
  }

  private loadInLocation(componentType: Type<any> | any , component: IComponent , classTemplate: string, functionsObservablesArray: Observable<unknown>[] ): void {
    const factory = this.compFactoryResolver.resolveComponentFactory(componentType);
    const instanceLocal:any  = this.ngContainer.createComponent(factory);
    const objectClass: ITransversal = instanceLocal.instance;
    objectClass.setDataBehavior(this.data);
    objectClass.setClass(classTemplate);
    objectClass.setDataComponent(component);
    this.serviceForm.setComponent(objectClass);
    this.data.setComponentBehavior(objectClass)
    console.log("setService del loadInLocation");
    objectClass.setService(this.injectService, functionsObservablesArray);
    objectClass.sendEmiterEvent().subscribe(
      ($event) => {
        if ($event.hasOwnProperty('comp')){
          this.castEvents($event.comp , $event.data );
        } else {
          this.castEvents($event );
        }

      }
    );
    ;
	}

  public loadTemplate(): void {
    console.log("loadTemplate -> APPLICATION.REQUEST.FORM -> form.configuration -> templateDataSinister -> setSelectDataSinister")
    this.serviceForm.clearComponents();
    this.servicePublic.methodGet({
      body: {},
      url: APPLICATION.REQUEST.FORM
    }).subscribe(
      (response: IFormConfiguration) => {
        this.isHeader = response.headers.showSteps;
        this.loadSteps(response);
      }
    );
  }

  private async loadSteps(response: IFormConfiguration): Promise<void> {
    let steps: string[] = [];
    this.steps = response.steps.length;
    let functionsObservablesArray: Observable<unknown>[] = [];
    for(let step of response.steps){
      if ( ('' + step.index) === ('' + this.step) ) {
        steps = step.currentStep;
        break;
      }
    }
    if (steps.length > 0) {
      let indexClass = 1;
      for (let key of steps) {
        // validando si es un archivo .json
        let template: ITemplate = null;
        const reg = /([a-zA-Z0-9\s_\\.\-\(\):])+(.json)$/i;
        if(reg.exec(key)){
          template = await this.servicePublic.methodGetPromise({ body: {}, url: key });
        } else {
          template = response.templates[key];
        }
        for (let component of template.components) {
          let data: ITransversal = Components[component.type];
          let classSection = (indexClass % 2 == 0)?'select-seccion-background':'seccion-background';
          // todo: en realidad le tengo que ejecutar esta funcion en ngAfterViewInit si el lenght es igual a 3
          // todo: loadSteps debo pasarle el arreglo
          // todo: pasarle el arregloFunciones a loadInLocation
          // todo: pasarle el arregloFunciones al setService
          // todo: si el componenteID resulta ser de causesinister, listoffice o listprovince, almacenar la funcion en el arreglo
          // todo: una vez almacenadas las funciones en el arreglo colocar las funciones en el concat para ejecutarlas segun corresponda.
          // todo: no dejar que se ejecuten los metodos como se hacian normalmente en el setService, remover del json o hacer una condicion
          this.loadInLocation( data , component , classSection, functionsObservablesArray);
        }
        indexClass += 1;
      }
      if (functionsObservablesArray.length === 3) {
        concat(...functionsObservablesArray).subscribe(
          () => {},
          (error: any) => {
            console.error(`Error: ${error}`);
          },
          () => {
            console.log('Todas las funciones han completado');
          }
        );
      }
    } else {
      alert ('No se encontró el paso que buscas');
    }
  }


  private castEvents($event: IComponent , args? : any): void {
    if ($event.extras.method) {
      this.functions[$event.extras.method]( $event  , args);
    }
  }

}
