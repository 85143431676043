import { Component, ElementRef, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { Transversal } from '../ui.transversal';
import { Functions } from '../../utils/functions';
import { Security } from '../../utils/security';

@Component({
  selector: 'zurich-dynamic-form-input-mask',
  templateUrl: './input-mask.component.html',
  styleUrls: ['./input-mask.component.scss']
})
export class InputMask extends Transversal {

  public title: string;
  public id: string;
  public subtitle: string;
  public isButtonSearch: boolean;
  public type: string;
  public valueInput: string = '';
  @ViewChild("elementId", { read: ViewContainerRef }) element: ElementRef;

  public isEditableField: boolean = false;
  public isEditableButton: boolean = false;
  public isEditableFieldButton: boolean = false;
  private firstValue: string = '';
  public tmpVal = '';

  constructor(private renderer: Renderer2) {
    super();
  }

  setOptions(options: any[]): void { }

  public focusResetInput(): void {
    this.value = '';
    this.valueInput = '';
    localStorage.removeItem(this.component.id + '_original');
    localStorage.removeItem(this.component.id);

  }


  onPostInit(dataSelect: string | ''): void {
    if (dataSelect !== '' && dataSelect !== null) {
      this.valueInput = dataSelect;
      this.value = dataSelect;
      this.id = this.component.id;
      this.title = this.component.extras.title;
      this.subtitle = this.component.extras.subTitle;
      this.type = this.component.extras.type || 'text';
      const post = localStorage.getItem(this.component.id + '_postLoad') || '';
      if (post === 'isPreload') {
        this.isEditable = false;
        this.isEditableField = false;
        this.isEditableButton = false;
        this.isEditableFieldButton = false;
      } else {
        const edit = localStorage.getItem(this.component.id + '_isEditableField');
        if (edit) {
          this.isEditable = ((localStorage.getItem(this.component.id + '_editable') || '') === 'true');
          this.isEditableField = ((localStorage.getItem(this.component.id + '_isEditableField') || '') === 'true');
          this.isEditableButton = (localStorage.getItem(this.component.id + '_isEditableButton' || '') === 'true');
          this.isEditableFieldButton = (localStorage.getItem(this.component.id + '_isEditableFieldButton' || '') === 'true' && this.firstValue !== '');
        }
      }
    } else {
      this.isEditable = true;
      this.isEditableField = true;
      this.isEditableButton = true;
      this.isEditableFieldButton = false;
      this.firstValue = (this.firstValue !== '') ? this.firstValue : '';
      this.behoviur.setKeyValue(this.component.id + '_editable', 'true');
      this.behoviur.setKeyValue(this.component.id + '_isEditableField', 'true');
      this.behoviur.setKeyValue(this.component.id + '_isEditableButton', 'true');
      this.behoviur.setKeyValue(this.component.id + '_isEditableFieldButton', 'false');
      this.valueInput = '';
    }
  }

  public editarValue(): void {
    const valTmp = this.value;
    this.firstValue = valTmp;
    this.value = '';
    this.valueInput = '';
    this.isEditable = true;
    this.isEditableField = true;
    this.isEditableButton = true;
    this.isEditableFieldButton = true;

    this.behoviur.setKeyValue(this.component.id + '_editable', 'true');
    this.behoviur.setKeyValue(this.component.id + '_isEditableField', 'true');
    this.behoviur.setKeyValue(this.component.id + '_isEditableButton', 'true');
    this.behoviur.setKeyValue(this.component.id + '_isEditableFieldButton', 'true');
  }

  public cerrarEdicion(): void {
    this.setValuePostLoad(this.firstValue);
    this.isEditable = false;
    this.isEditableField = false;
    this.isEditableButton = false;
    this.isEditableFieldButton = false;

    this.behoviur.remove(this.component.id + '_editable');
    this.behoviur.remove(this.component.id + '_isEditableField');
    this.behoviur.remove(this.component.id + '_isEditableButton');
    this.behoviur.remove(this.component.id + '_isEditableFieldButton');
  }

  public setValueForm(): void {
    const key = this.component.id + '_postLoad';
    this.behoviur.remove(key);
    this.setValue(this.valueInput);

  }



}
