import { Component } from '@angular/core';
import { IOptions } from '@zurich-dynamic-form/schemas';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Transversal } from '../ui.transversal';

@Component({
  selector: 'zurich-dynamic-form-label-component',
  templateUrl: './label-component.component.html',
  styleUrls: ['./label-component.component.scss']
})
export class Label extends Transversal {

  public options: IOptions[] = [];
  public classMobile: string;

  constructor(private deviceService: DeviceDetectorService) {
    super();
    this.classMobile = '';
  }

  onPostInit(dataSelect: string): void {

    this.options = this.component.extras.options;
    this.classMobile = (this.deviceService.isMobile() || this.deviceService.isTablet()) ? 'grid-mobile' : '';
  }

  setOptions(options: any[]): void {
    this.component.extras.options = options;
  }
}
