import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IComponent, IOptions } from '@zurich-dynamic-form/schemas';
import { HttpServiceService } from '@zurich-dynamic-form/services';
import { Transversal } from '../ui.transversal';
import { Functions } from '../../utils/functions';
import { Security } from '../../utils/security';
declare var $: any;
@Component({
  selector: 'zurich-dynamic-form-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class Select extends Transversal {

  public title: string;
  public subtitle: string;
  public placeholder: string = 'Selecciona una opción';
  public options: IOptions[];
  public id: string;
  public filterValue: string;
  public optionsTmp: IOptions[] = [];
  public isPlaceholder: boolean = true;

  constructor(private changeDetection: ChangeDetectorRef) {
    super();
  }

  onPostInit(dataSelect: string): void {
    this.title = this.component.extras.title;
    this.subtitle = this.component.extras.subTitle;
    this.placeholder = (this.component.extras.placeholder) ? this.component.extras.placeholder : this.placeholder;
    this.isPlaceholder = true;
    const isEditableField = localStorage.getItem(this.component.id + '_editable') || '';

    if (isEditableField === '') {

      if (this.component.extras.options && this.component.extras.options.length > 0) {
        this.setOptions(this.component.extras.options);
      } else {
        this.setOptions(JSON.parse(localStorage.getItem(this.component.id + '_options') || '[]'));
      }

      this.id = this.component.id;

      if (dataSelect !== '' && dataSelect !== null) {
        this.component.extras.options.forEach(
          (option) => {
            if (('' + option.key) == ('' + dataSelect)) {
              Functions.COMPONENTSMASK.includes(this.component.id) ? localStorage.setItem(this.component.id + '_original', option.key) : null;
              this.isPlaceholder = false;
              let valEncrypt: string = "";

              if (Functions.COMPONENTSMASK.includes(this.component.id) && localStorage.getItem('@inputHiddenPolicyNumber_postLoad') === 'isPreload') {
                localStorage.setItem(this.component.id + '_postLoad', 'isPreload');
                if (localStorage.getItem('modelEdit') != 'true') {
                  let valdescryption = Security.decription(option.value);
                  const data = Functions.cleanArray(valdescryption.split(''));
                  valEncrypt = this.encryptValue(data.toString().replace(/,/g, ""));
                } else {
                  valEncrypt = this.encryptValue(option.value)
                }
              } else {
                Functions.COMPONENTSMASK.includes(this.component.id) ? valEncrypt = this.encryptValue(option.value) : valEncrypt = option.value;
              }
              this.placeholder = valEncrypt;// debo desencriptar
              this.value = option.key;
            }
          }
        );
      }
    } else {
      this.isChangeService = true;
    }
  }

  setOptions(options: IOptions[]): void {
    // Orden Asendente
    this.component.extras.options = options.sort((a, b) => (a.value < b.value ? -1 : 1));;
    this.isPlaceholder = true;
    this.optionsTmp = options;
    localStorage.setItem(this.component.id + '_options', JSON.stringify(this.optionsTmp));
    this.changeDetection.detectChanges();
  }

  filterOptions(): void {
    this.component.extras.options = this.optionsTmp.filter(
      (option) => { return (option.value.toLowerCase().indexOf(this.filterValue.toLowerCase()) > -1) }
    );
  }

  select(option: IOptions): void {
    this.filterValue = '';
    this.placeholder = option.value;
    this.component.extras.options = this.optionsTmp;
    this.isPlaceholder = false;
    this.setValue(option.key);
    this.isNextFunction();
  }

  setValueInput(value: string): void {
    this.isPlaceholder = false;
    this.setValue(value);
  }

  encryptValue(value: string): string {
    const name = Functions.cleanArray(value.split(''))
    let inicio = name[0];
    let final = name[name.length - 1];
    let medio = name.toString().slice(1, -1).replace(/[a-zA-Z]/g, '*');
    var cadenaSinComas = medio.replace(/,/g, "");
    return inicio + cadenaSinComas + final;
  }

}
