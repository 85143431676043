import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'zurich-dynamic-form-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  @Input() message: string = '';

  constructor() {}

  ngOnInit(): void {
    this.message = (this.message === '') ? 'Este campo es requerido o no cumple con las validaciones mínimas' : this.message ;
  }

}
