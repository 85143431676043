import { Component, Input, OnInit } from '@angular/core';
import { IHelpOption } from '@zurich-dynamic-form/schemas';
declare var $:any;
@Component({
  selector: 'zurich-dynamic-form-slide-tooltip',
  templateUrl: './slide-tooltip.component.html',
  styleUrls: ['./slide-tooltip.component.scss']
})
export class SlideTooltipComponent implements OnInit {

  @Input() title: string;
  @Input() options: IHelpOption[];
  public contentCard = '';

  constructor() { }

  ngOnInit(): void {
    let index = 0;
    for (let option of this.options) {
      const mod = ( (index % 4) == 0 );
      if (mod && index !== 0) {
        this.contentCard += '</div></div>';
      }

      if (mod){ 
        this.contentCard += '<div class="carousel-item';
        this.contentCard += ( index === 0 ) ? ' active"><div>' : '"><div>';
      }

      this.contentCard +='<div class="card-item-tooltip"><h4>'+option.label+'</h4>';
      this.contentCard +='<img  class="img-tooltip-slide" src="'+option.img+'" >';
      this.contentCard +='<span>'+option.sublabel+'</span></div>';

      if ((index + 1) === this.options.length) {
        this.contentCard += '</div></div>';
      }
      index += 1;
    }
  }

}
