import { Component, OnInit } from '@angular/core';
import { IComponent } from '@zurich-dynamic-form/schemas';
import { Transversal } from '../ui.transversal';
import { NgbCalendar, NgbDate, NgbDatepickerConfig, NgbDatepickerI18n, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18n, I18n } from './injectables/CustomDatepickerI18n';

@Component({
  selector: 'zurich-dynamic-form-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}, NgbDatepickerConfig]
})
export class Datepicker extends Transversal {
  
  public modelValue: NgbDateStruct;
  public title: string;
  public id: string;
  public subtitle: string;
  public markDisabled;

  constructor(private calendar: NgbCalendar, private config: NgbDatepickerConfig) { 
    super();
    // customize default values of datepickers used by this component tree
    const date = new Date();
    config.minDate = {year: date.getFullYear() - 150, month: 1, day: 1};
    config.maxDate = {year: date.getFullYear() + 30, month: 12, day: 31};
  }

  setOptions(options: any[]): void{}

  selectDate():void  {

  }
  
  onPostInit(dataSelect: string): void {
    this.id = this.component.id;
    this.title = this.component.extras.title;
    this.subtitle =this.component.extras.subTitle;
    const dateNow = new Date();
    this.value = (dataSelect) ? dataSelect : this.getDateSelect(null , dateNow);
    this.modelValue = {
      day : Number(this.value.split('-')[2]),
      month : Number(this.value.split('-')[1]),
      year : Number(this.value.split('-')[0]),
    }

    if (this.component.extras.disable) {
      if (this.component.extras.disable.max){
        this.config.maxDate = this.getLimit( this.component.extras.disable.max , true );
      }
      if (this.component.extras.disable.min){
        this.config.minDate = this.getLimit( this.component.extras.disable.min , false );
      }
      if (this.component.extras.disable.weekend) {
        this.markDisabled = (date: NgbDate) => { return this.calendar.getWeekday(date) >= 6 };
      }
    }
  }


  isDisabled = (date: NgbDate, current: {month: number, year: number}) => date.month !== current.month;
  isWeekend = (date: NgbDate) =>  this.calendar.getWeekday(date) >= 6;

  dateSelect(d: NgbInputDatepicker): void {
    d.dateSelect.subscribe(
      (data : NgbDateStruct) => {
        this.setValue( this.getDateSelect(data , null) );
        d.close();
      }
    )
    d.toggle();
  }


  private getLimit( flag: string ,  flagTop: boolean ): NgbDateStruct {
    let date: Date = new Date();
    let number = (flagTop) ? 1 : -1 ;
    switch (flag) {
      case 'day' :
        date.setDate( date.getDate() + number );
        break;
      case 'month' :
        date.setMonth( date.getMonth() + number );
        break;
      case 'year':
        date.setFullYear(date.getFullYear() + number );
        break;
    }
    const dateReturn  = {
      day : date.getDate(),
      month : (date.getMonth() + 1),
      year : date.getFullYear()
    };
    return dateReturn;
  }

  private getDateSelect( dateSelect: NgbDateStruct , dateNow: Date): string {
    let returnString = '';
    if (dateSelect !== null) {
      returnString = dateSelect.year + '-';
      returnString += this.validateNumber(dateSelect.month) + '-';
      returnString += this.validateNumber(dateSelect.day);
    } else {
      returnString = dateNow.getFullYear() + '-' ;
      returnString += this.validateNumber( (dateNow.getMonth()+1) ) + '-';
      returnString += this.validateNumber( dateNow.getDate() ) ;
    }
    return returnString;
  }

  private validateNumber(number: Number): string {
    return (number >= 10) ? ''+number : '0'+number;
  }
}
