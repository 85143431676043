import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { IComponent, SchemaCentralCall, SchemaHttp } from '@zurich-dynamic-form/schemas';
import { HttpGeneralService } from '@zurich-dynamic-form/services';
import { ModalComponent } from 'libs/ui/src/lib/modal/modal.component';
import { AuthService } from '../oauh/http-service.service';
import { service, environment } from '@env/environment'
import { HttpHeaders } from '@angular/common/http';
import { Data } from '@zurich-dynamic-form/data';
import { Observable, throwError } from 'rxjs';
import { Functions } from 'libs/ui/src/utils/functions';

@Injectable({
  providedIn: 'root'
})
export class HttpServiceService {

  public static CENTER: NgbModalOptions = {
    size: 'dialog-centered modal-xl',
    backdrop: 'static',
    keyboard: false
  };

  constructor(private modalService: NgbModal, private auth: AuthService, private service: HttpGeneralService) { }

  public callService(component: IComponent, behaviour: Data) {
    return new Promise(
      async (result, reject) => {
        const modal = this.modalService.open(ModalComponent, HttpServiceService.CENTER);
        modal.componentInstance.data = {
          type: 'load',
          value: 0
        };
        modal.result.then();
        const urlJson = (component.urlPostChange && component.urlPostChange !== '') ? component.urlPostChange :
          (component.autoUrl && component.autoUrl !== '') ? component.autoUrl : '';
        const send: SchemaCentralCall = service[urlJson];
        try {
          const data = await this.getData(component, behaviour);
          this.getSendData(send.method, data)
            .subscribe(
              (dataResponse) => {
                if (dataResponse.code === 0 || dataResponse.code === 1) {
                  behaviour.readDataForComponent(component.urlPostChange, dataResponse);
                  modal.close();
                  result(dataResponse);
                } else {
                  modal.close();
                  if (!component.extras.messageInformation) {
                    this.showError(component, behaviour);
                  }
                  reject(dataResponse);
                }
              }, (err) => {
                modal.close();
                if (!component.extras.messageInformation) {
                  this.showError(component, behaviour);
                }
                reject(err);
              });
        } catch (err) {
          modal.close();
          if (!component.extras.messageInformation) {
            this.showError(component, behaviour);
          }
          reject(err);
        }
      }
    );
  }

  private showError(component: IComponent, behaviour: Data): void {
    const modal = this.modalService.open(ModalComponent, HttpServiceService.CENTER);
    modal.componentInstance.data = {
      type: 'erno',
      value: 0
    };
    modal.result.then((res) => {
      modal.close();
      if (res) {
        this.callService(component, behaviour);
      }
    });
  }

  private async getData(component: IComponent, behaviour: Data): Promise<SchemaHttp> {
    try {
      const urlJson = (component.urlPostChange && component.urlPostChange !== '') ? component.urlPostChange :
        (component.autoUrl && component.autoUrl !== '') ? component.autoUrl : '';
      const send: SchemaCentralCall = service[urlJson];
      let url: string = environment.host;
      url = url.concat(send.path);
      url = await this.loadUrl(url, behaviour);
      url = url.replace(/\s/g, '');
      const uuid = localStorage.getItem('UUID');
      const headers: any = new HttpHeaders({
        'uuid': uuid,
        'originator': 'FNOL',
        'timestamp': (new Date().toISOString())
      });
      const data: SchemaHttp = {
        url,
        body: {},
        headers
      }
      return data;
    } catch (err) {
      throw new Error(err);
    }
    return null;
  }

  private getSendData(method: string, data: SchemaHttp): Observable<any> {
    let observer = null;
    switch (method) {
      case 'get':
        observer = this.service.methodGet(data);
        break;
      case 'post':
        observer = this.service.methodPost(data);
        break;
      case 'postByParams':
        observer = this.service.methodPostTest(data);
        break;
      case 'patch':
        observer = this.service.methodPatch(data);
        break;
      case 'put':
        observer = this.service.methodPut(data);
        break;
      default:
        observer = this.service.methodGet(data);
        break;
    }
    return observer
  }

  private loadUrl(url: string, behoviur: Data): Promise<string> {
    return new Promise(
      (resolve, reject) => {
        behoviur.getDataForBehavior().subscribe(
          (information: any) => {
            const keys = Object.keys(information);
            for (let key of keys) {
              const ind = url.search(key);
              if (ind > -1) {
                const parameter = Functions.COMPONENTSMASK.includes(key) ? localStorage.getItem(key + '_original') : information[key];
                url = url.replace(key, parameter);
              }
            }
            resolve(url);
          }, reject
        )
      }
    );
  }

  public async callServicePost(component: IComponent, behaviour: Data, data: any): Promise<Observable<any>> {
    const params = service[component.urlPostChange];
    let url: string = environment.host.concat(environment.path);
    url = url.concat(params.path);
    url = await this.loadUrl(url, behaviour);
    // Creacion de los headers
    const uuid = localStorage.getItem('UUID');
    const headers: any = new HttpHeaders({
      'uuid': uuid,
      'originator': 'FNOL',
      'timestamp': (new Date().toISOString())
    });

    const obj: SchemaHttp = {
      url,
      body: data,
      headers
    }
    return this.getSendData(params.method, obj);
  }

  public async callServiceForUrl(method: string, url: string, data: any): Promise<Observable<any>> {
    // Creacion de los headers
    const uuid = localStorage.getItem('UUID');
    const headers: any = new HttpHeaders({
      'uuid': uuid,
      'originator': 'FNOL',
      'timestamp': (new Date().toISOString())
    });

    const obj: SchemaHttp = {
      url,
      body: data,
      headers
    }
    return this.getSendData(method, obj);
  }

  public async callServiceForParams(method: string, url: string, data: FormData): Promise<Observable<any>> {
    // Creacion de los headers
    const boundary = this.generateBoundary();
    const uuid = localStorage.getItem('UUID');
    const headers: any = new HttpHeaders({
      'uuid': uuid,
      'originator': 'FNOL',
      'timestamp': (new Date().toISOString()),
    });

    const obj: SchemaHttp = {
      url,
      body : data,
      headers
    }
    console.log("OBJECT del POST-> ", obj);
    return this.getSendData(method, obj);
  }

  public generateBoundary(): string {
    let boundary = '';
    const possibleCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 16; i++) {
      boundary += possibleCharacters.charAt(Math.floor(Math.random() * possibleCharacters.length));
    }
    return boundary;
  }


}
