import { environment } from "@env/environment";
import { ZurichMetricsService } from "@zpaas/sdk-angular";
import { IComponent } from "@zurich-dynamic-form/schemas";
import { ITransversal } from "@zurich-dynamic-form/ui";
import { AuthService } from "libs/services/src/lib/oauh/http-service.service";
import { ModalComponent } from "libs/ui/src/lib/modal/modal.component";
import { NGXLogger } from "ngx-logger";
import { AppComponent } from "../../app.component";
import { ComponentLogicComponent } from "./component-logic.component";
import { LogicPage1 } from "./logicpages/logic.page1";
import { LogicPage2 } from "./logicpages/logic.page2";
import { LogicPage3 } from "./logicpages/logic.page3";

export class FunctionsComponent {

    public componentLogic: ComponentLogicComponent;
    private logicpage1: LogicPage1;
    private logicpage2: LogicPage2;
    private logicpage3: LogicPage3;

    constructor(private logger: NGXLogger, private auth: AuthService, private zurichMetricsService: ZurichMetricsService) {
        this.logicpage1 = new LogicPage1(this);
        this.logicpage2 = new LogicPage2(this);
        this.logicpage3 = new LogicPage3(this, logger, auth, zurichMetricsService);
    }

    public setComponent(comp: ComponentLogicComponent): void {
        this.componentLogic = comp;
    }

    public getValueForComponent(component: IComponent): string {
        const arrayComponent: ITransversal[] = this.componentLogic.data.getComponents();
        for (let parent of arrayComponent) {
            if (component.id === parent.getComponent().id) {
                return parent.getValue();
            }
        }
        return null;
    }

    public getComponentForId(id: string): ITransversal {
        const arrayComponent: ITransversal[] = this.componentLogic.data.getComponents();
        for (let parent of arrayComponent) {
            if (id === parent.getComponent().id) {
                return parent;
            }
        }
        return null;
    }


    public inspectionExpress(): boolean {
        // @inputLiquidSinister - NO
        // @inputMobilize - SI
        // @inputCountPiece - 7 <
        // @inputDamageThird - NO
        // @inputDamageBody - NO

        const data = this.componentLogic.data.getAllObject();
        const inputLiquidSinister = data['@inputLiquidSinister'] || '';
        const inputMobilize = data['@inputMobilize'] || '';
        const inputDamageBody = data['@inputDamageBody'] || '';

        // const inputCountPiece = data['@inputCountPiece'] || '20';
        //const inputDamageThird = data['@inputDamageThird'] || '';

        if (inputLiquidSinister !== 'N' || inputLiquidSinister == '') { return false; }
        if (inputMobilize !== 'Y' || inputMobilize == '') { return false; }

        // if (Number.parseInt(inputCountPiece) > 7) { return false; }
        // if (inputDamageThird !== 'N' || inputDamageThird == '') { return false; }

        if (inputDamageBody !== 'N' || inputDamageBody == '') { return false; }

        return true;
    }

    public sendNextPage(): void {
        if (this.componentLogic.serviceForm.validateFormControl()) {
            if (this.componentLogic.step === 2) {
                if (!this.logicpage2.evaluateQuadrantsSendPage()) {
                    const modal = this.componentLogic.modalService.open(ModalComponent, AppComponent.CENTER);
                    modal.componentInstance.data = {
                        type: 'noSelectPiece',
                        value: 0
                    };
                    modal.result.then();
                    return;
                }
            }
            this.componentLogic.step = this.componentLogic.step + 1;
            if (this.componentLogic.step < 4) {
                localStorage.setItem('PAGE', String(this.componentLogic.step));
                sessionStorage.setItem('inSession', 'active');
                window.location.href = '/form/' + this.componentLogic.step;
            }
        }
    }

    public sendBackPage(): void {
        this.componentLogic.step = this.componentLogic.step - 1;
        localStorage.setItem('PAGE', String(this.componentLogic.step));
        if (this.componentLogic.step > 0) {
            localStorage.setItem('PAGE', String(this.componentLogic.step));
            sessionStorage.setItem('inSession', 'active');
            window.location.href = '/form/' + this.componentLogic.step;
        }
    }

    /***
     * 
     * LOGIC COMPONENT PAGE 1
     * 
     **/

    public viewFormRegister(component: IComponent): void {
        this.zurichMetricsService.NavigationToFunction({ userID: '', functionName: 'customerInformation', applicationName: environment.appName });
        this.logicpage1.viewFormRegister(component);
    }
    public fieldsVehicle(): void {
        this.logicpage1.fieldsVehicle();
    }
    public fieldsCompany(component: IComponent): void {
        this.logicpage1.fieldsCompany(component);
    }
    public loadInformationCar(component: IComponent, data: any, err: any): void {
        this.logicpage1.loadInformationCar(component, data, err);
    }
    public showNextDataMotor(): void {
        this.logicpage1.showNextDataMotor();
    }
    public notShowInputsCar(): void {
        this.logicpage1.notShowInputsCar();
    }
    public showInputFile(component: IComponent): void {
        this.logicpage1.showInputFile(component);
    }
    public setSelectListOffice(component: IComponent, data: any): void {
        this.logicpage1.setSelectListOffice(component, data);
    }
    public loadInformationCarByMotor(component: IComponent, data: any): void {
        this.logicpage1.loadInformationCarByMotor(component, data);
    }
    public loadModelBrand(component: IComponent, data: any): void {
        this.logicpage1.loadModelBrand(component, data);
    }
    public loadYear(component: IComponent, data: any): void {
        this.logicpage1.loadYear(component, data);
    }
    public loadInformacionByCar(data: any): void {
        this.logicpage1.loadInformacionByCar(data);
    }

    /***
     * 
     * LOGIC COMPONENT PAGE 2
     * 
     **/


    public setSelectCauseSiniester(component: IComponent, data: any): void {
        this.logicpage2.setSelectCauseSiniester(component, data);
    }

    /*
    public setSelectTypeLost(component: IComponent , data: any) : void {
        this.logicpage2.setSelectTypeLost(component , data) ;
    }
    */

    public setSelectProvince(component: IComponent, data: any): void {
        this.logicpage2.setSelectProvince(component, data);
    }
    public showDamageVehicle(component: IComponent, data: any): void {
        this.logicpage2.showDamageVehicle();
    }

    public showPolicyAffected(component: IComponent, data: any): void {
        this.logicpage2.showPolicyAffected(component);
    }

    /**
     * 
     * LOGIC COMPONENT PAGE 3
     * 
     */

    public showLoadDriver(component: IComponent, data: any): void {
        this.logicpage3.showLoadDriver(component, data);
    }

    public showPeopleWitness(component: IComponent, data: any): void {
        this.logicpage3.showPeopleWitness(component, data);
    }

    public showDateInspectionExpress(component: IComponent): void {
        this.logicpage3.showDateInspectionExpress(component);
    }

    public showDataForm(component: IComponent): void {
        this.logicpage3.showDataForm(component);
    }

    public sendForm(component: IComponent): void {
        this.logicpage3.sendForm();
    }
}