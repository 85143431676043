import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddcardPopupRoutingModule } from './addcard-popup-routing.module';
import { IndexComponent } from './index/index.component';
import { PageComponent } from './page/page.component';
import { HttpServiceService } from '@zurich-dynamic-form/services';


@NgModule({
  declarations: [IndexComponent, PageComponent],
  imports: [
    CommonModule,
    AddcardPopupRoutingModule
  ], exports : [
    IndexComponent
  ], providers : [
    HttpServiceService
  ]
})
export class AddCard { }
