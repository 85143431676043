import { Component, OnInit } from '@angular/core';
import { Transversal } from '../ui.transversal';

@Component({
  selector: 'zurich-dynamic-form-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class Title extends Transversal{

  public title: string;
  public subtitle: string;

  constructor() { 
    super();
  }

  onPostInit(dataSelect: string): void {
    this.title = this.component.extras.title;
    this.subtitle = this.component.extras.subTitle;
  }
  setOptions(options: any[]): void{}
}
