import { HttpHeaders } from "@angular/common/http";
import { environment, service } from "@env/environment";
import { ZurichMetricsService } from "@zpaas/sdk-angular";
import { IComponent, IOptions } from "@zurich-dynamic-form/schemas";
import {
    ICauseSinisterType, ICities, ICoordinateDamage, ICreateSinister,
    IDetailsDriverInsuredVehicle, IDocument, IInformationClientLegalPerson, IInformationClientNaturalPerson,
    IListInjuredPerson, IListThirdPartyInformation, IListWitnessesToTheAccident,
    ILossType, IPolicyAffectedRamo, IProvince, ISendData, ISinisterCity,
    ISinisterData, IVehicleData, IWhereHappen, IWhoCompletedThisFormGroup
} from "apps/zurich-dynamic-form/src/interface/request.send";
import { AuthService } from "libs/services/src/lib/oauh/http-service.service";
import { PipeMask } from "libs/ui/src/lib/input-mask/Pipes/PipeMask.transform";
import { ModalComponent } from "libs/ui/src/lib/modal/modal.component";
import { Security } from "libs/ui/src/utils/security";
import { NGXLogger } from "ngx-logger";
import { AppComponent } from "../../../app.component";
import { APPLICATION } from "../../../utils/constants";
import { Functions } from "../../../utils/functions";
import { FunctionsComponent } from "../component-logic.functions";

import { Functions as fun } from '../../../../../../../libs/ui/src/utils/functions';

export class LogicPage3 {

    private functions: FunctionsComponent;
    private imageThird: string[] = [];

    constructor(fun: FunctionsComponent, private logger: NGXLogger, private auth: AuthService, private zurichMetricsService: ZurichMetricsService) {
        this.functions = fun;
    }

    public showLoadDriver(component: IComponent, data: any): void {
        this.showSectionDriverInformation(component);
        this.showSectionThirdInformation();
        this.showSectionInjuredInformation();
        this.showInspectionMethod();
        this.showDataRegisterSiniester();
        this.loadDocumentationInjured();
    }

    public loadDocumentationInjured(): void {
        const bool = this.functions.inspectionExpress();
        if (!bool) {
            this.functions.getComponentForId('@LabelDocumentationInsured').isVisible();
            this.functions.getComponentForId('@inputFrontDocumentRegister').isVisible();
            this.functions.getComponentForId('@inputBackDocumentRegister').isVisible();
            this.functions.getComponentForId('@inputNumberCarVehicleFront').isVisible();
            this.functions.getComponentForId('@inputNumberCarVehicleBack').isVisible();
        } else {
            this.functions.getComponentForId('@LabelDocumentationInsured').isInvisible();
            this.functions.getComponentForId('@inputFrontDocumentRegister').isInvisible();
            this.functions.getComponentForId('@inputBackDocumentRegister').isInvisible();
            this.functions.getComponentForId('@inputNumberCarVehicleFront').isInvisible();
            this.functions.getComponentForId('@inputNumberCarVehicleBack').isInvisible();
        }
    }

    public showDataRegisterSiniester(): void {
        this.functions.getComponentForId('@idRadicacionSiniestro').isVisible();
        const cacheData = this.functions.componentLogic.data.getAllObject();
        const dataCodeCitySiniester = cacheData['@inputCityProcessSinister'];
        const arrayCityOptions = JSON.parse(cacheData['@inputCityProcessSinister_options'] || '[]');
        let dataLabelCitySiniester = '';
        arrayCityOptions.forEach((data) => {
            if (data.key === dataCodeCitySiniester) {
                dataLabelCitySiniester = data.value;
            }
        });
        const dateRegisterSiniester = new Date();
        let options: IOptions[] = [];
        options.push({
            title: "Ciudad de radicación del siniestro",
            value: dataLabelCitySiniester
        });
        const valueDate = dateRegisterSiniester.getDate() + '/' +
            APPLICATION.MONTHS[dateRegisterSiniester.getMonth()] + '/' + dateRegisterSiniester.getFullYear();
        options.push({
            title: "Fecha de radicado",
            value: valueDate
        });
        this.functions.getComponentForId('@idRadicacionSiniestro').setOptions(options);
    }

    public showPeopleWitness(component: IComponent, data: any): void {
        const value = this.functions.getComponentForId(component.id).getValue();
        if (value == 'Y') {
            this.functions.getComponentForId('@idCardWitnesses').isVisible();
        } else {
            this.functions.getComponentForId('@idCardWitnesses').isInvisible();
        }
    }

    private showSectionDriverInformation(component: IComponent): void {
        const value = this.functions.getComponentForId(component.id).getValue();
        this.functions.getComponentForId('@inputNameInsured').isVisible();
        this.functions.getComponentForId('@inputLastNameInsured').isVisible();
        this.functions.getComponentForId('@inputCellPhoneInsured').isVisible();
        this.functions.getComponentForId('@inputEmailInsured').isVisible();

        if (value == 'Y') {
            const data = this.functions.componentLogic.data.getAllObject();
            const isPostLoadPhone = localStorage.getItem('@inputCellPhoneRegister_postLoad') || '';
            const isPostLoadEmail = localStorage.getItem('@inputEmailRegister_postLoad') || '';
            const isPostLoadName = localStorage.getItem('@inputNameRegister_postLoad') || '';
            const isPostLoadLastname = localStorage.getItem('@inputLastNameRegister_postLoad') || '';
            const typePerson = data['@inputTypeDocumentIdentity'] || '';
            const cell = (isPostLoadPhone === '') ? data['@inputCellPhoneRegister'] : this.functions.componentLogic.pipeMask.transform(data['@inputCellPhoneRegister'], PipeMask.CELLPHONE);
            const email = (isPostLoadEmail === '') ? data['@inputEmailRegister'] : this.functions.componentLogic.pipeMask.transform(data['@inputEmailRegister'], PipeMask.EMAIL);
            const name = (isPostLoadName === '') ? data['@inputNameRegister'] : this.functions.componentLogic.pipeMask.transform(data['@inputNameRegister_original'], PipeMask.NAMEREGISTER);
            const lastName = (isPostLoadLastname === '') ? data['@inputLastNameRegister'] : this.functions.componentLogic.pipeMask.transform(data['@inputLastNameRegister_original'], PipeMask.LASTNAMEREGISTER);
            this.functions.getComponentForId('@inputNameInsured').setValuePostLoad(name);
            this.functions.getComponentForId('@inputLastNameInsured').setValuePostLoad(lastName);
            this.functions.getComponentForId('@inputCellPhoneInsured').setValuePostLoad(cell);
            this.functions.getComponentForId('@inputEmailInsured').setValuePostLoad(email);
            if (typePerson == 'r') {
                this.functions.getComponentForId('@inputInsuredNumberLicense').setValuePostLoad('');
            } else {
                // guardamos el valor original en el localstorage
                localStorage.setItem('@inputInsuredNumberLicense_original', data['@inputNumberDocumentIdentity'])
                this.functions.getComponentForId('@inputInsuredNumberLicense').setValuePostLoad(fun.encryptLicense(data['@inputNumberDocumentIdentity']));
            }
            this.functions.getComponentForId('@inputRelationshipInsured').isInvisible();
        } else {
            this.functions.getComponentForId('@inputNameInsured').setValuePostLoad('');
            this.functions.getComponentForId('@inputLastNameInsured').setValuePostLoad('');
            this.functions.getComponentForId('@inputCellPhoneInsured').setValuePostLoad('');
            this.functions.getComponentForId('@inputEmailInsured').setValuePostLoad('');
            this.functions.getComponentForId('@inputInsuredNumberLicense').setValuePostLoad('');
            this.functions.getComponentForId('@inputRelationshipInsured').isVisible();
            this.functions.getComponentForId('@inputFrontDocumentLicenceInsured').isVisible();
            this.functions.getComponentForId('@inputBackDocumentLicenceInsured').isVisible();
        }
        const bool = this.functions.inspectionExpress();
        if (!bool) {
            this.functions.getComponentForId('@inputFrontDocumentLicenceInsured').isVisible();
            this.functions.getComponentForId('@inputBackDocumentLicenceInsured').isVisible();
        } else {
            this.functions.getComponentForId('@inputFrontDocumentLicenceInsured').isInvisible();
            this.functions.getComponentForId('@inputBackDocumentLicenceInsured').isInvisible();
        }
    }

    private showSectionThirdInformation(): void {
        const value = this.functions.componentLogic.data.getAllObject()['@inputDamageThird']; if (value == 'Y') {
            this.functions.getComponentForId('@LabelPeopleInvolved').isVisible();
            this.functions.getComponentForId('@idCardThird').isVisible();
        } else {
            const valueIns = this.functions.componentLogic.data.getAllObject()['@inputPolicyAffected'];
            if (valueIns == 'N') {
                this.functions.getComponentForId('@LabelPeopleInvolved').isVisible();
                this.functions.getComponentForId('@idCardThird').isVisible();
            } else {
                this.functions.getComponentForId('@LabelPeopleInvolved').isInvisible();
                this.functions.getComponentForId('@idCardThird').isInvisible();
            }
        }
    }

    private showSectionInjuredInformation(): void {
        const value = this.functions.componentLogic.data.getAllObject()['@inputDamageBody'];
        if (value == 'Y') {
            this.functions.getComponentForId('@LabelPeopleInjured').isVisible();
            this.functions.getComponentForId('@idCardPeopleInjured').isVisible();
        } else {
            this.functions.getComponentForId('@LabelPeopleInjured').isInvisible();
            this.functions.getComponentForId('@idCardPeopleInjured').isInvisible();
        }
    }

    private showInspectionMethod(): void {
        const bool = this.functions.inspectionExpress();
        if (bool) {
            this.functions.getComponentForId('@labelSeccionInspeccionExpress').isVisible();
            this.functions.getComponentForId('@inputInspeccionExpress').isVisible();
        } else {
            this.functions.getComponentForId('@labelSeccionInspeccionExpress').isInvisible();
            this.functions.getComponentForId('@inputInspeccionExpress').isInvisible();
        }
    }

    public showDateInspectionExpress(component: IComponent): void {
        const value = this.functions.getComponentForId(component.id).getValue();
        if (value == 'C') {
            this.functions.getComponentForId('@inputDateInspectionExpress').isVisible();
            this.functions.getComponentForId('@inputHourInspectionExpress').isVisible();
        } else {
            this.functions.getComponentForId('@inputDateInspectionExpress').isInvisible();
            this.functions.getComponentForId('@inputHourInspectionExpress').isInvisible();
        }
    }

    public showDataForm(component: IComponent): void {
        const value = this.functions.getComponentForId(component.id).getValue();
        const data = this.functions.componentLogic.data.getAllObject();

        this.functions.getComponentForId('@inputNameReportComplete').isVisible();
        this.functions.getComponentForId('@inputLastNameReportComplete').isVisible();
        this.functions.getComponentForId('@inputCellPhoneReportComplete').isVisible();
        const complete = (data['@inputCellPhoneRegister_postLoad'] || '');
        const completeName = (data['@inputNameRegister_postLoad'] || '');
        const completeLastName = (data['@inputLastNameRegister_postLoad'] || '');
        if (value == 'O') {
            this.functions.getComponentForId('@inputNameReportComplete').setValuePostLoad('');
            this.functions.getComponentForId('@inputLastNameReportComplete').setValuePostLoad('');
            this.functions.getComponentForId('@inputCellPhoneReportComplete').setValuePostLoad('');
        } else if (value == 'A') {
            const cell = (complete !== '') ? this.functions.componentLogic.pipeMask.transform(data['@inputCellPhoneRegister'], PipeMask.CELLPHONE) : data['@inputCellPhoneRegister'];
            const name = (completeName !== '') ? this.functions.componentLogic.pipeMask.transform(data['@inputNameRegister_original'], PipeMask.NAMEREGISTER) : data['@inputNameRegister'];
            const lastname = (completeLastName !== '') ? this.functions.componentLogic.pipeMask.transform(data['@inputLastNameRegister_original'], PipeMask.LASTNAMEREGISTER) : data['@inputLastNameRegister'];
            this.functions.getComponentForId('@inputNameReportComplete').setValuePostLoad(name);
            this.functions.getComponentForId('@inputLastNameReportComplete').setValuePostLoad(lastname);
            this.functions.getComponentForId('@inputCellPhoneReportComplete').setValuePostLoad(cell);
        } else {
            this.functions.getComponentForId('@inputNameReportComplete').setValuePostLoad(data['@inputNameInsured']);
            this.functions.getComponentForId('@inputLastNameReportComplete').setValuePostLoad(data['@inputLastNameInsured']);
            this.functions.getComponentForId('@inputCellPhoneReportComplete').setValuePostLoad(data['@inputCellPhoneInsured']);
        }
    }

    public sendForm(): void {
        if (this.functions.componentLogic.serviceForm.validateFormControl()) {
            const authDocumentation = this.functions.getComponentForId('@inputCheckBoxAutorizationDocumentation').getValue();
            const authData = this.functions.getComponentForId('@inputCheckBoxAutorizationData').getValue();
            const authEnd = this.functions.getComponentForId('@inputCheckBoxAutorizationEnd').getValue();
            if (authDocumentation === 'Y' && authData === 'Y' && authEnd === 'Y') {
                const modal = this.functions.componentLogic.modalService.open(ModalComponent, AppComponent.CENTER);
                modal.componentInstance.data = {
                    type: 'load',
                    value: 0
                };
                modal.result.then();
                const listThirdPartyInformation = this.getListThirdPartyInformation();
                let requestService: ISendData = {
                    createSinister: this.getCreateSinister(),
                    causeSinisterType: this.getCausaSiniestroType(),
                    detailsDriverInsuredVehicle: this.getDetailsDriverInsuredVehicle(),
                    informationClientNaturalPerson: this.getInformationClientNaturalRegistry(),
                    informationClientLegalPerson: this.getInformationClientLegalRegistry(),
                    listImagesSinister: this.getImages(),
                    listInjuredPerson: this.getListInjuredPerson(),
                    listThirdPartyInformation,
                    listWitnessesToTheAccident: this.getListWitnessesToTheAccident(),
                    lossType: this.getLossType(),
                    policyAffectedRamo: this.getpolicyAffectedRamo(),
                    sinisterCity: this.getCitySiniester(),
                    sinisterData: this.getSinisterData(listThirdPartyInformation),
                    vehicleData: this.getInformationCar()
                }
                console.log(requestService);

                const url = environment.host.concat(service.createSinister.path);

                this.zurichMetricsService.NavigationToFunction({ userID: '', functionName: 'createSinister', applicationName: environment.appName });

                 this.functions.componentLogic.injectService.callServiceForUrl(service.createSinister.method, url, requestService).then(
                     (observer) => {
                         observer.subscribe(
                             (result: { code: number; message: string; numberCreateSinister: string }) => {
                                 let url = '/complete';
                                 if (result.code === 0) {
                                     url += '?status=ok&number=' + result.numberCreateSinister;
                                 } else {
                                     url += '?status=pendiente';
                                 }
                                 modal.close();
                                 sessionStorage.clear();
                                 localStorage.clear();
                                 sessionStorage.setItem('complete', 'Y');
                                 window.location.href = url;
                             }
                         );
                     }, this.errorSendService
                 ).catch(this.errorSendService);
            } else {
                const modal = this.functions.componentLogic.modalService.open(ModalComponent, AppComponent.CENTER);
                modal.componentInstance.data = {
                    type: 'noConfirmAutorize',
                    value: 0
                };
                modal.result.then((result) => { });
            }
        }
    }

    private errorSendService(erno: any): void {
        const modal = this.functions.componentLogic.modalService.open(ModalComponent, AppComponent.CENTER);
        modal.componentInstance.data = {
            type: 'ernoSendReport',
            value: 0
        };
        modal.result.then();
    }

    // Seccion datos del asegurado persona natural
    private getInformationClientNaturalRegistry(): IInformationClientNaturalPerson {
        const data = this.functions.componentLogic.data.getAllObject();
        const type = data['@inputTypeDocumentIdentity'];
        if (type !== 'r') {
            let informationClientNaturalPerson: IInformationClientNaturalPerson = {
                cellPhone: this.getDataUserEncription().phone,
                document: {
                    id: type,
                    number: data['@inputNumberDocumentIdentity'],
                    value: (type == 'c') ? 'Cédula' : 'Pasaporte'
                },
                email: this.getDataUserEncription().email,
                id: (data['@inputHiddenIdCliente']) ? data['@inputHiddenIdCliente'] : '',
                landline: this.getDataUserEncription().landline,
                lastName: this.getDataUserEncription().lastname,
                name: this.getDataUserEncription().name
            }
            return informationClientNaturalPerson;
        }
        return null;
    }
    //
    // Seccion datos del asegurado persona natural
    private getInformationClientLegalRegistry(): IInformationClientLegalPerson {
        const data = this.functions.componentLogic.data.getAllObject();
        const type = data['@inputTypeDocumentIdentity'];
        if (type === 'r') {
            let informationClientNaturalPerson: IInformationClientLegalPerson = {
                contactPerson: {
                    cellPhone: this.getDataUserEncription().phone,
                    email: this.getDataUserEncription().email,
                    landline: this.getDataUserEncription().landline,
                    lastName: this.getDataUserEncription().lastname,
                    name: this.getDataUserEncription().name
                },
                id: (data['@inputHiddenIdCliente']) ? data['@inputHiddenIdCliente'] : '',
                nameLegalPerson: data['@inputNameCompanyRegister'],
                numberRUC: {
                    id: 'r',
                    number: data['@inputNumberDocumentIdentity'],
                    value: 'RUC'
                }
            }
            return informationClientNaturalPerson;
        }
        return null;
    }

    // Seccion de datos del vehiculo
    private getInformationCar(): IVehicleData {

        const data = this.functions.componentLogic.data.getAllObject();
        // Modelo
        let model: any = {};
        let loadModel: boolean = true;
        const listModel = JSON.parse(data['@inputModelCarRegistry_options']);
        const modelId = data['@inputModelCarRegistry_original'];
        listModel.forEach((modelItem) => {
            if (modelItem.key == modelId) {
                model = modelItem;
            }
        });
        // Si la carga de modelo no fue exitosa
        if (!model.key) {
            model = {
                key: modelId,
                value: modelId
            }
            loadModel = false;
        }
        // Marca
        let brand: any = {};
        let loadBand: boolean = true;
        const listBrand = JSON.parse(data['@inputBrandCarRegistry_options']);
        const brandId = data['@inputBrandCarRegistry_original'];
        listBrand.forEach((brandItem) => {
            if (brandItem.key == brandId) {
                brand = brandItem;
            }
        });
        // Si la carga de marca no fue exitosa
        if (!brand.key) {
            brand = {
                key: brandId,
                value: brandId
            }
            loadBand = false;
        }
        let vehicleData: IVehicleData = {
            agent: 'FNOL',
            brand: this.getEncriptModelAnBrand(loadBand, loadModel, model.value, brand.value).brand,
            brandId: brand.key,
            chassis: this.getEncriptAutoMovil().chasis,
            generatedSinisterToday: this.parseStringToBoolean(data['@inputHiddenGeneratedSinisterToday']),
            idModel: model.key,
            idPolicy: (data['@inputHiddenPolicyId']) ? data['@inputHiddenPolicyId'] : '',
            idVehicle: (data['@inputHiddenVehicleId']) ? data['@inputHiddenVehicleId'] : '',
            model: this.getEncriptModelAnBrand(loadBand, loadModel, model.value, brand.value).model,
            motor: this.getEncriptAutoMovil().motor,
            plates: data['@inputNumberCarIdentityRegistry_original'],
            policyNumber: (data['@inputHiddenPolicyNumber']) ? data['@inputHiddenPolicyNumber'] : "",
            product: (data['@inputHiddenProduct']) ? data['@inputHiddenProduct'] : '',
            productId: (data['@inputHiddenProductId']) ? data['@inputHiddenProductId'] : '',
            validSince: (data['@inputHiddenValidSince']) ? data['@inputHiddenValidSince'] : '',
            validUntil: (data['@inputHiddenValidUntil']) ? data['@inputHiddenValidUntil'] : '',
            vehicleColor: data['@inputColorCarRegistry_original'],
            year: data['@inputYearCarRegistry']
        };
        return vehicleData;
    }

    private parseStringToBoolean(valor: string): boolean {
        return Boolean(valor);
    }

    // Seccion daños del siniestro / causa siniestro
    private getCausaSiniestroType(): ICauseSinisterType {
        const data = this.functions.componentLogic.data.getAllObject();
        let dataResponse: any = {};
        //const listSiniester = JSON.parse(data['@inputCauseSinister_options']);
        //const modelId = data['@inputCauseSinister'];
        const listSiniester = JSON.parse(localStorage.getItem('@inputCauseSinister_options'));
        const modelId = localStorage.getItem('@inputCauseSinister');
        listSiniester.forEach((item) => {
            if (item.key == modelId) {
                dataResponse = item;
            }
        });

        let causeSinisterType: ICauseSinisterType = {
            id: dataResponse.key,
            value: dataResponse.value
        }
        return causeSinisterType;
    }

    // Seccion póliza afectada
    private getpolicyAffectedRamo(): IPolicyAffectedRamo {
        let policyAffectedRamo: IPolicyAffectedRamo = {
            id: 'VH',
            value: 'Vehículos'
        }
        return policyAffectedRamo;
    }

    // Seccion daños del siniestro / Tipo perdida
    private getLossType(): ILossType {
        const data = this.functions.componentLogic.data.getAllObject();
        let dataResponse: any = {};
        //const listTypeLost = JSON.parse(data['@inputTypeLost_options']);
        //const type = data['@inputTypeLost'];
        const listTypeLost = JSON.parse(localStorage.getItem('@inputTypeLost_options'));
        const type = localStorage.getItem('@inputTypeLost');
        listTypeLost.forEach((item) => {
            if (item.key == type) {
                dataResponse = item;
            }
        });

        let lossType: ILossType = {
            id: dataResponse.key,
            value: dataResponse.value
        }
        return lossType;
    }

    // Seccion daños del siniestro / ciudad
    private getCitySiniester(): ISinisterCity {
        const data = this.functions.componentLogic.data.getAllObject();
        let dataResponse: any = {};
        //const listCitySiniester = JSON.parse(data['@inputCityProcessSinister_options']);
        //const city = data['@inputCityProcessSinister'];
        const listCitySiniester = JSON.parse(localStorage.getItem('@inputCityProcessSinister_options'));
        const city = localStorage.getItem('@inputCityProcessSinister');
        listCitySiniester.forEach((item) => {
            if (item.key == city) {
                dataResponse = item;
            }
        });
        let sinisterCity: ISinisterCity = {
            id: dataResponse.key,
            value: dataResponse.value
        }
        return sinisterCity;
    }

    // Seccion daños del siniestro / data
    private getSinisterData(third?: IListThirdPartyInformation[]): ISinisterData {

        const data = this.functions.componentLogic.data.getAllObject();
        let thirdPartyDamage = (data['@inputDamageThird'] == 'Y') ? true : (third && third.length > 0);

        let sinisterData: ISinisterData = {
            canTheVehicleMobilizedByItsOwnMeans: (data['@inputMobilize'] == 'Y'),
            claimAddress: data['@inputAddressSinister'],
            coolantOilOrHydraulicFluidLeaked: (data['@inputLiquidSinister'] == 'Y'),
            coordinateDamage: this.getCoordinateDamage(),
            howHappen: data['@inputDescriptionSinister'],
            // howManyPiecesWereAffectedInTheCrash: data['@inputCountPiece'],
            // Validar con Adrian estos dos Parametros
            wasAnyComplaintMade: (data['@inputComplaint'] == 'Y'),
            thereInjuriesOccupants: (data['@inputDamageBody'] == 'Y'),
            therePolicePresence: (data['@inputExistPresencePolice'] == 'Y'),
            // Validar hora
            timeHappened: Functions.getHourFormat(data['@inputHourSinister']),
            wasProformaRepairVehicleDamagesCarriedOut: (data['@inputProforma'] == 'Y'),
            whatDamages: data['@inputSinisterDetail'],
            // Validar Fecha
            whenHappen: data['@inputDateSinister'],
            whereHappen: this.getWhereHappen(),
            useTheContractedPolicyForRepair: (data['@inputPolicyAffected'] == 'Y'),
            thirdPartyDamage
        }
        return sinisterData;
    }

    // Seccion daños del siniestro / cuadrantes
    private getCoordinateDamage(): ICoordinateDamage {
        const data = this.functions.componentLogic.data.getAllObject();
        return this.getCoordinateDamageObject(
            data['@inputInteractiveBack'],
            data['@inputInteractiveFront'],
            data['@inputInteractiveRigth'],
            data['@inputInteractiveLeft']
        );
    }

    // Seccion daños del siniestro / ubicacion del siniestro
    private getWhereHappen(): IWhereHappen {
        return {
            cities: this.getCityHappen(),
            province: this.getProvinceHappen()
        }
    }

    // Seccion daños del siniestro / Ciudad del siniestro
    private getCityHappen(): ICities {
        const data = this.functions.componentLogic.data.getAllObject();
        let dataResponse: any = {};
        const listCity = JSON.parse(data['@inputCitySinister_options']);
        const city = data['@inputCitySinister'];
        listCity.forEach((item) => {
            if (item.key == city) {
                dataResponse = item;
            }
        });

        let cityLocate: ICities = {
            id: city,
            value: dataResponse.value || ''
        }
        return cityLocate;
    }

    // Seccion daños del siniestro / provincia del siniestro
    private getProvinceHappen(): IProvince {
        const data = this.functions.componentLogic.data.getAllObject();
        let dataResponse: any = {};
        const listProvince = JSON.parse(data['@inputProvinceSinister_options']);
        const province = data['@inputProvinceSinister'];
        listProvince.forEach((item) => {
            if (item.key == province) {
                dataResponse = item;
            }
        });

        let provinceLocate: IProvince = {
            id: dataResponse.key,
            value: dataResponse.value
        }
        return provinceLocate;
    }

    // Seccion datos del conductor
    private getDetailsDriverInsuredVehicle(): IDetailsDriverInsuredVehicle {

        const dateRegisterSiniester = new Date();
        const filingDate = dateRegisterSiniester.getFullYear() + '-' + (dateRegisterSiniester.getMonth() + 1) + '-' + dateRegisterSiniester.getDate();
        const data = this.functions.componentLogic.data.getAllObject();
        const driverIsInsured = (data['@inputInsuredDriverPage3'] == 'Y');
        const liscense = localStorage.getItem('@inputInsuredNumberLicense_original');
        let detailsDriverInsuredVehicle: IDetailsDriverInsuredVehicle = {
            addressOfYourHome: '',
            authorizedDriveBy: '',
            cellPhone: (driverIsInsured) ? this.getDataUserEncription().phone : this.validateEncription('@inputCellPhoneInsured', data, 'P'),
            dueDate: data['@inputInsuredDateLicense'],
            cityOfSettlementOfTheClaim: this.getCityClaim(),
            email: (driverIsInsured) ? this.getDataUserEncription().email : this.validateEncription('@inputEmailInsured', data, 'E'),
            filingDate,
            landline: (driverIsInsured) ? this.getDataUserEncription().landline : '',
            lastNameDriver: (driverIsInsured) ? this.getDataUserEncription().lastname : this.validateEncription('@inputLastNameInsured', data, 'L'),
            licenseNumber: liscense ? liscense : data['@inputInsuredNumberLicense'],
            licenseType: data['@inputInsuredTypeLicense'],
            namesDriver: (driverIsInsured) ? this.getDataUserEncription().name : this.validateEncription('@inputNameInsured', data, 'N'),
            relationshipWithTheInsured: (data['@inputRelationshipInsured']) ? data['@inputRelationshipInsured'] : '',
            theDriverIsTheInsured: driverIsInsured,
            whoCompletedThisFormGroup: this.getWhoCompletedThisFormGroup()
        }
        return detailsDriverInsuredVehicle;
    }

    // Seccion datos del conductor / ciudad de radicacion del siniestro
    private getCityClaim(): string {
        const cacheData = this.functions.componentLogic.data.getAllObject();
        const dataCodeCitySiniester = cacheData['@inputProvinceSinister'];
        const arrayCityOptions = JSON.parse(cacheData['@inputProvinceSinister_options'] || '[]');
        let dataLabelCitySiniester = '';
        arrayCityOptions.forEach((data) => {
            if (data.key === dataCodeCitySiniester) {
                dataLabelCitySiniester = data.value;
            }
        });
        return dataLabelCitySiniester;
    }

    // Seccion de Completar el formulario / Quien completo
    private getWhoCompletedThisFormGroup(): IWhoCompletedThisFormGroup {
        const data = this.functions.componentLogic.data.getAllObject();
        const inputSendReportComplete = data['@inputSendReportComplete'];
        const driverIsInsured = (data['@inputInsuredDriverPage3'] == 'Y');
        const complete = (data['@inputHiddenAutomaticCompleteRegistry'] == 'Y');
        let cellPhone = '';
        let nameRegister = '';
        let lastnameRegister = '';
        // this.validateEncription('@inputCellPhoneReportComplete' , data , 'P')
        switch (inputSendReportComplete) {
            case 'A':
                cellPhone = (complete) ? this.getDataUserEncription().phone : this.validateEncription('@inputCellPhoneRegister_original', data, 'P');
                nameRegister = (complete) ? this.getDataUserEncription().name : this.validateEncription('@inputNameRegister_original', data, 'N');
                lastnameRegister = (complete) ? this.getDataUserEncription().lastname : this.validateEncription('@inputLastNameRegister_original', data, 'L');
                break;
            case 'C':
                cellPhone = (driverIsInsured) ? this.getDataUserEncription().phone : this.validateEncription('@inputCellPhoneInsured', data, 'P');
                nameRegister = (driverIsInsured) ? this.getDataUserEncription().name : this.validateEncription('@inputNameInsured', data, 'N');
                lastnameRegister = (driverIsInsured) ? this.getDataUserEncription().lastname : this.validateEncription('@inputLastNameInsured', data, 'L');
                break;
            default:
                cellPhone = Security.encription(data['@inputCellPhoneReportComplete']);
                nameRegister = Security.encription(data['@inputNameReportComplete']);
                lastnameRegister = Security.encription(data['@inputLastNameReportComplete']);
                break;
        }
        let responseFunction: IWhoCompletedThisFormGroup = {
            whoCompletedThisForm: data['@inputSendReportComplete'],
            cellPhone,
            name: nameRegister,
            lastName: lastnameRegister
        }
        return responseFunction;
    }

    // Seccion de Completar el formulario / Datos de creacion
    private getCreateSinister(): ICreateSinister {

        const data = this.functions.componentLogic.data.getAllObject();

        let now: Date = new Date();
        var numberOfMlSeconds = now.getTime();
        var addMlSeconds = 2880 * 60000;
        var date48h = new Date(numberOfMlSeconds + addMlSeconds);

        let finalDate = date48h.toLocaleDateString().split("/");

        let day: number = +finalDate[0];
        let month: number = +finalDate[1];

        let nowDay, nowMonth;

        nowDay = day;
        nowMonth = month;

        if (day < 10) {
            nowDay = '0' + day;
        }

        if (month < 10) {
            nowMonth = '0' + month;
        }

        let finallyDate = nowDay + "/" + nowMonth + "/" + finalDate[2];

        let hour = date48h.getHours().toString();
        let minutes = date48h.getMinutes().toString();
        let seconds = date48h.getSeconds().toString();

        if (date48h.getHours() < 10) {
            hour = '0' + date48h.getHours();
        }
        if (date48h.getMinutes() < 10) {
            minutes = '0' + date48h.getMinutes();
        }
        if (date48h.getSeconds() < 10) {
            seconds = '0' + date48h.getSeconds();
        }

        let finallyHour = hour + ":" + minutes + ":" + seconds;

        let inspectionDate = '';
        if (data['@inputInspeccionExpress'] == 'C') {
            inspectionDate = finallyDate;
        }

        let createSinister: ICreateSinister = {
            vehicleIsAffected: (data['@inputPolicyAffected'] == 'Y'),
            uuidRequest: data['UUID'],
            receiveACallFromTheAdvisor: (data['@inputInspeccionExpress'] == 'C'),
            theSinisterIsManual: !(data['@inputHiddenAutomaticCompleteDataVehicle'] == 'Y' && data['@inputHiddenAutomaticCompleteRegistry'] == 'Y'),
            inspectionDate,
            inspectionHour: (data['@inputInspeccionExpress'] == 'C') ? finallyHour : '',
            doAnIntrospection: (data['@inputInspeccionExpress'] != 'C' && data['@inputInspeccionExpress'] != '' && data['@inputInspeccionExpress'] != undefined)
        }
        return createSinister;
    }

    // Seccion Heridos involucrados
    private getListInjuredPerson(): IListInjuredPerson[] {
        const data = this.functions.componentLogic.data.getAllObject();
        let listInjuredPerson: IListInjuredPerson[] = [];
        const injured: any[] = (data['@idCardPeopleInjured']) ? JSON.parse(data['@idCardPeopleInjured']) : [];
        injured.forEach(
            (item) => {
                const injuredIsInsured = (item.info['@radioButtonWitness'] == 'Y');

                const injuredPerson: IListInjuredPerson = {
                    cellPhone: (injuredIsInsured) ? this.getDataUserEncription().phone : Security.encription(item.info['@inputCellPhoneRegisterWitness']),
                    email: (injuredIsInsured) ? this.getDataUserEncription().email : Security.encription(item.info['@inputEmailRegisterWitness']),
                    landline: (injuredIsInsured) ? this.getDataUserEncription().landline : '',
                    lastName: (injuredIsInsured) ? this.getDataUserEncription().lastname : Security.encription(item.info['@inputLastNameRegisterWitness']),
                    name: (injuredIsInsured) ? this.getDataUserEncription().name : Security.encription(item.info['@inputNameRegisterWitness']),
                    sayWhereYouWereAtTheTimeOfTheAccident: item.info['@inputLocationCar'],
                    listIsTheInjuredPersonTheSameInsuredPerson: (item.info['@radioButtonWitness'] == 'Y'),
                    relationshipWithTheInsured: ''
                }
                listInjuredPerson.push(injuredPerson);
            }
        );

        return listInjuredPerson;
    }

    // Seccion Testigos involucrados
    private getListWitnessesToTheAccident(): IListWitnessesToTheAccident[] {
        const data = this.functions.componentLogic.data.getAllObject();
        let listWitnessesToTheAccident: IListWitnessesToTheAccident[] = [];
        const witnesses: any[] = (data['@idCardWitnesses']) ? JSON.parse(data['@idCardWitnesses']) : [];
        witnesses.forEach(
            (item) => {
                const witnessesToTheAccident: IListWitnessesToTheAccident = {
                    name: Security.encription(item.info['@inputNameRegisterWitnesses']),
                    lastName: Security.encription(item.info['@inputLastNameRegisterWitnesses']),
                    cellPhone: Security.encription(item.info['@inputCellPhoneRegisterWitnesses']),
                    email: '',
                    landline: ''
                }
                listWitnessesToTheAccident.push(witnessesToTheAccident)
            }
        );
        return listWitnessesToTheAccident;
    }

    // Seccion Terceros involucrados
    private getListThirdPartyInformation(): IListThirdPartyInformation[] {
        const data = this.functions.componentLogic.data.getAllObject();
        let listThirdPartyInformation: IListThirdPartyInformation[] = [];
        this.imageThird = [];
        const third: any[] = (data['@idCardThird']) ? JSON.parse(data['@idCardThird']) : [];
        third.forEach(
            (item) => {
                const materialDamage = (item.info['inputArticleThird'] == 'm') ? 'Material' : (item.info['inputArticleThird'] == 'v') ? 'Vehiculo' : 'Bien Inmueble';
                const valueDocument = (item.info['inputTypeDocumentThird'] == 'c') ? 'Cedula' : (item.info['inputTypeDocumentThird'] == 'p') ? 'Pasaporte' : 'RUC';
                const documentThird: IDocument = {
                    id: item.info['inputTypeDocumentThird'],
                    number: item.info['inputNumberDocumentIdentityThird'],
                    value: valueDocument
                }


                const idMaterial = ((item.info['inputArticleThird'] == 'm') ? '02' : (item.info['inputArticleThird'] == 'v') ? '01' : '03');

                const thirdPartyInformation: IListThirdPartyInformation = {
                    idTypeOfDamage: item.info['radioButtonThird'],
                    typeOfDamage: (item.info['radioButtonThird'] == 'C') ? 'Corporal' : 'Material',
                    idMaterialDamage: (item.info['radioButtonThird'] == 'M') ? idMaterial : '',
                    materialDamage: (item.info['radioButtonThird'] == 'M') ? materialDamage : '',
                    document: documentThird,
                    name: item.info['inputNameRegisterThird'],
                    lastName: item.info['inputLastNameRegisterThird'],
                    lossAdress: '',
                    cellPhone: item.info['inputCellPhoneRegisterThird'],
                    email: item.info['inputEmailRegisterThird'],
                    chassis: item.info['inputNumberChasisCarThird'],
                    motor: item.info['inputNumberMotorCarThird'],
                    plates: item.info['inputNumberCarThird'],
                    descriptionWellAffected: item.info['inputDescriptionArticleThird'],
                    damageDescription: item.info['inputDescriptionSinister'],
                    nameOfTheInsurer: (item.info['inputNameArticleInsuredThird']) ? item.info['inputNameArticleInsuredThird'] : '',
                    theAffectedPropertyIsInsured: (item.info['radioArticleInsuredThird'] == 'Y'),
                    coordinateDamage: this.getCoordinateDamageObject(
                        item.info['inputInteractiveBackThird'],
                        item.info['inputInteractiveFrontThird'],
                        item.info['inputInteractiveLeftThird'],
                        item.info['inputInteractiveRigthThird']
                    ),
                }
                listThirdPartyInformation.push(thirdPartyInformation);
                let tmpRouteImage = '';
                tmpRouteImage = (item.info['inputFrontDocumentRegisterThird']) ? item.info['inputFrontDocumentRegisterThird'] : '';
                if (tmpRouteImage !== '') this.imageThird.push(tmpRouteImage);

                tmpRouteImage = (item.info['inputBackDocumentRegisterThird']) ? item.info['inputBackDocumentRegisterThird'] : '';
                if (tmpRouteImage !== '') this.imageThird.push(tmpRouteImage);

                tmpRouteImage = (item.info['inputPhotoFrontLicenseThird']) ? item.info['inputPhotoFrontLicenseThird'] : '';
                if (tmpRouteImage !== '') this.imageThird.push(tmpRouteImage);

                tmpRouteImage = (item.info['inputPhotoBackLicenseThird']) ? item.info['inputPhotoBackLicenseThird'] : '';
                if (tmpRouteImage !== '') this.imageThird.push(tmpRouteImage);
            }
        );
        return listThirdPartyInformation;
    }

    // Seleccion de cuadrantes
    private getCoordinateDamageObject(backInput: string, frontInput: string, leftInput: string, rightInput: string): ICoordinateDamage {
        const back = (backInput) ? backInput.split(',') : [];
        const front = (frontInput) ? frontInput.split(',') : [];
        const left = (leftInput) ? leftInput.split(',') : [];
        const right = (rightInput) ? rightInput.split(',') : [];
        let coordinate: ICoordinateDamage = {
            back: Functions.validateQuadrant(back),
            front: Functions.validateQuadrant(front),
            left: Functions.validateQuadrant(left),
            right: Functions.validateQuadrant(right),
        }
        return coordinate;
    }

    // Seccion Imagenes
    private getImages(): string[] {
        const data = this.functions.componentLogic.data.getAllObject();
        let images: string[] = [];
        let tmpRouteImage = '';

        //imagen denuncia
        tmpRouteImage = (data['@inputDamageSinister']) ? data['@inputDamageSinister'] : '';
        if (tmpRouteImage !== '') images.push(tmpRouteImage.replace('//uploads', ''));

        //imagen reporte policial
        tmpRouteImage = (data['@inputDocumentReportPolice']) ? data['@inputDocumentReportPolice'] : '';
        if (tmpRouteImage !== '') images.push(tmpRouteImage.replace('//uploads', ''));

        //imagen proforma
        tmpRouteImage = (data['@inputPhotoProforma']) ? data['@inputPhotoProforma'] : '';
        if (tmpRouteImage !== '') images.push(tmpRouteImage.replace('//uploads', ''));

        //imagen frontal licencia
        tmpRouteImage = (data['@inputFrontDocumentLicenceInsured']) ? data['@inputFrontDocumentLicenceInsured'] : '';
        if (tmpRouteImage !== '') images.push(tmpRouteImage.replace('//uploads', ''));

        //imagen posterior licencia
        tmpRouteImage = (data['@inputBackDocumentLicenceInsured']) ? data['@inputBackDocumentLicenceInsured'] : '';
        if (tmpRouteImage !== '') images.push(tmpRouteImage.replace('//uploads', ''));

        //imagen frontal documento identidad
        tmpRouteImage = (data['@inputFrontDocumentRegister']) ? data['@inputFrontDocumentRegister'] : '';
        if (tmpRouteImage !== '') images.push(tmpRouteImage.replace('//uploads', ''));

        //imagen posterior documento identidad
        tmpRouteImage = (data['@inputBackDocumentRegister']) ? data['@inputBackDocumentRegister'] : '';
        if (tmpRouteImage !== '') images.push(tmpRouteImage.replace('//uploads', ''));

        //imagen frontal matricula del vehiculo
        tmpRouteImage = (data['@inputNumberCarVehicleFront']) ? data['@inputNumberCarVehicleFront'] : '';
        if (tmpRouteImage !== '') images.push(tmpRouteImage.replace('//uploads', ''));

        //imagen posterior matricula del vehiculo
        tmpRouteImage = (data['@inputNumberCarVehicleBack']) ? data['@inputNumberCarVehicleBack'] : '';
        if (tmpRouteImage !== '') images.push(tmpRouteImage.replace('//uploads', ''));
        this.imageThird.forEach(
            (item) => {
                images.push(item.replace('//uploads', ''));
            }
        );

        return images;
    }

    // Validacion ecripcion
    private validateEncription(id: string, data: any, type: 'E' | 'P' | 'N' | 'L'): string {
        let valueResponse = '';
        const value = data[id];
        if (type === 'E') {
            valueResponse = (value.indexOf('******@') > -1) ? this.getDataEncriptUser(type, data) : Security.encription(value);
        } else if (type === 'P') {
            valueResponse = (value.indexOf('****') > -1) ? this.getDataEncriptUser(type, data) : Security.encription(value);
        } else if (type === 'N') {
            console.error('validateEncription', value);
            valueResponse = (value?.indexOf('******') > -1) ? this.getDataEncriptUser(type, data) : Security.encription(value);
        } else if (type === 'L') {
            console.warn('validateEncription', value);
            valueResponse = (value?.indexOf('******') > -1) ? this.getDataEncriptUser(type, data) : Security.encription(value);
        }
        return valueResponse;
    }

    private getDataEncriptUser(type: string, data: any): string {
        if (type == 'P') {
            return data['@inputCellPhoneRegister']
        } else if (type = 'E') {
            return data['@inputEmailRegister'];
        } else if (type = 'N') {
            return data['@inputNameRegister_original'];
        } else if (type = 'L') {
            return data['@inputLastNameRegister_original'];

        }

    }

    // Obtiene el objeto email y phone para encripcion
    private getDataUserEncription(): { email: string, phone: string, landline: string, name: string, lastname: string } {
        let email = '';
        let phone = '';
        let landline = '';
        let name = '';
        let lastname = '';

        const isPostLoadPhone = localStorage.getItem('@inputCellPhoneRegister_postLoad') || '';
        const isPostLoadLandline = localStorage.getItem('@inputPhoneRegister_postLoad') || '';
        const isPostLoadEmail = localStorage.getItem('@inputEmailRegister_postLoad') || '';
        const isPostLoadName = localStorage.getItem('@inputNameRegister_postLoad') || '';
        const isPostLoadLastname = localStorage.getItem('@inputLastNameRegister_postLoad') || '';

        if (isPostLoadPhone === '') {
            const phoneDecription = Number(localStorage.getItem('@inputCellPhoneRegister_original'));
            phone = Security.encription('' + phoneDecription);
        } else {
            const phoneDecrip = Security.decription(localStorage.getItem('@inputCellPhoneRegister_original'));
            const clearData = Functions.cleanAllArray(phoneDecrip.split(''));
            const phoneDecription = Number(clearData.join(''));

            phone = Security.encription('' + phoneDecription);
        }
        if (isPostLoadLandline === '') {
            const landLineDescription = Number(localStorage.getItem('@inputPhoneRegister_original'));
            landline = Security.encription('' + landLineDescription);
        } else {
            landline = localStorage.getItem('@inputPhoneRegister_original');
        }
        if (isPostLoadEmail === '') {
            email = Security.encription(localStorage.getItem('@inputEmailRegister_original'));
        } else {
            email = localStorage.getItem('@inputEmailRegister_original');
        }
        if (isPostLoadName === '') {
            name = Security.encription(localStorage.getItem('@inputNameRegister_original'));
        } else {
            name = localStorage.getItem('@inputNameRegister_original');
        }
        if (isPostLoadLastname === '') {
            lastname = Security.encription(localStorage.getItem('@inputLastNameRegister_original'));
        } else {
            lastname = localStorage.getItem('@inputLastNameRegister_original');
        }

        return {
            email,
            phone,
            landline,
            name,
            lastname
        }
    }

    /**
     *
     * @param models modelos del vehiculo
     * @param brands marcas del vehiculo
     * @returns retorna el objeto encriptado
     */
    private getEncriptAutoMovil(models?: string, brands?: string): { chasis: string, motor: string, model: string, brand: string } {
        let motor = '';
        let chasis = '';
        let model = '';
        let brand = '';
        const isPostLoadMotor = localStorage.getItem('@inputNumberMotorCarRegistry2_postLoad') || '';
        const isPostLoadChasis = localStorage.getItem('@inputNumberChasisCarRegistry2_postLoad') || '';
        const isPostloadModel = localStorage.getItem('@inputModelCarRegistry_postLoad') || '';
        const isPostloadBrand = localStorage.getItem('@inputBrandCarRegistry_postLoad') || '';
        if (isPostLoadMotor === '') {
            motor = Security.encription(localStorage.getItem('@inputNumberMotorCarRegistry2_original'));
        } else {
            motor = localStorage.getItem('@inputNumberMotorCarRegistry2_original');
        }
        if (isPostLoadChasis === '') {
            chasis = Security.encription(localStorage.getItem('@inputNumberChasisCarRegistry2_original'))
        } else {
            chasis = localStorage.getItem('@inputNumberChasisCarRegistry2_original');
        }
        if (isPostloadModel === '') {
            model = Security.encription(models);
        } else {
            model = models;
        }
        if (isPostloadBrand === '') {
            brand = Security.encription(brands);
        } else {
            brand = brands;
        }
        return {
            motor,
            chasis,
            model,
            brand
        }
    }

    /**
     *
     * @param loadBrand booleano para saber si se carga la marca
     * @param loadModel booleano para saber si se carga el modelo
     * @param model modelo del vehiculo
     * @param brand marca del vehiculo
     * @returns retorna el objeto encriptado
     */
    private getEncriptModelAnBrand(loadBrand: boolean, loadModel: boolean, model: string, brand: string): { model: string, brand: string } {
        let modelResponse = '';
        let brandResponse = '';

        const isPostloadModel = localStorage.getItem('@inputModelCarRegistry_postLoad') || '';
        const isPostloadBrand = localStorage.getItem('@inputBrandCarRegistry_postLoad') || '';

        if (isPostloadModel === '') {
            modelResponse = Security.encription(model);
        } else {
            loadModel ? modelResponse = model : modelResponse = Security.encription(model);
        }

        if (isPostloadBrand === '') {
            brandResponse = Security.encription(brand);
        } else {
            loadBrand ? brandResponse = brand : brandResponse = Security.encription(brand);
        }

        return {
            model: modelResponse,
            brand: brandResponse
        }
    };
}
