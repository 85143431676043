import { Component, ComponentFactoryResolver, OnInit, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { ITransversal } from '../../ui.itransversal';
import { FormService, HttpGeneralService, HttpServiceService } from '@zurich-dynamic-form/services';
import { Components } from '@zurich-dynamic-form/ui';
import { IComponent } from '@zurich-dynamic-form/schemas';
import { Data } from '@zurich-dynamic-form/data';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FunctionsExtendModal } from '../logic.personalize.functions.ts/functions';
import { PipeMask } from '../../input-mask/Pipes/PipeMask.transform';

@Component({
  selector: 'zurich-dynamic-form-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {

  @ViewChild('viewPopUp', {read: ViewContainerRef }) ngContainer: ViewContainerRef;
  public template: string;
  public new: boolean;
  public data: Data 
  public information: any;
  public componentsModal: ITransversal[] = [];
  public functions: FunctionsExtendModal;

  constructor(private compFactoryResolver: ComponentFactoryResolver, private servicePublic: HttpGeneralService ,
    public serviceForm: FormService , public activeModal: NgbActiveModal , public pipeMask:PipeMask, public injectService: HttpServiceService ) {
    this.data = Data.getInstance();
    this.functions = new FunctionsExtendModal();
  }

  ngOnInit(): void {
   this.componentsModal = [];
  }

  ngAfterViewInit(): void {
    this.ngContainer.clear();
    this.servicePublic.methodGetPromise({
      url: this.template,
      body : {},
    }).then(
      (modalData) => {
        let indexClass = 1;
        const sections = Object.keys(modalData);
        for(let section of sections){
          for ( let component of modalData[section] ) {
            let data: ITransversal = Components[component.type];
            let classSection = (indexClass % 2 == 0)?'select-seccion-background':'seccion-background';
            this.loadComponent(data , component , classSection);
          }
          indexClass +=1;
        }
      }
    );
  }

  private loadComponent(componentType: Type<any> | any, component: IComponent , classTemplate: string ): void {
    const factory = this.compFactoryResolver.resolveComponentFactory(componentType);
    const instanceLocal:any  = this.ngContainer.createComponent(factory);
    const objectClass: ITransversal = instanceLocal.instance;
    this.componentsModal.push(objectClass);
    objectClass.setClass(classTemplate);
    objectClass.setDataComponent(component);
    objectClass.setService(this.injectService);
    //this.serviceForm.setComponent(objectClass);
    objectClass.setDataBehavior(this.data);
    this.data.addArray(objectClass);
    objectClass.sendEmiterEvent().subscribe(
      ($event: IComponent) => {
        if ($event.extras.method === 'save') {
          this.returnInformation();
        } else {
          this.functions[$event.extras.method](this);
        }
      }
    );
    if (this.new) {
      objectClass.setValue('');
    } else {
      const value = this.information[objectClass.getComponent().id] || '';
      objectClass.setValue( value );
    }
  }

  private returnInformation(): void{
    let data = {};
    let isCorrect = true;
    this.componentsModal.forEach(
      (component) => {
        const isValidComponent = component.isValid();
        if (!isValidComponent){
          isCorrect = isValidComponent;
        } else {
          if (component.getValue()!== ''){
            data[component.getComponent().id] = component.getValue();
          }
        }
      }
    );
    if (isCorrect) {
      this.activeModal.close(data);
    }
  }

  public closePopUp(): void {
    this.activeModal.close();
  }
}
