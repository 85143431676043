export class Utils {

    private static traverseAndFlatten(currentNode: any, target: any, flattenedKey?: any): void {
        for (let key in currentNode) {
            if (currentNode.hasOwnProperty(key)) {
                let newKey;
                if (flattenedKey === undefined) {
                    newKey = key;
                } else {
                    newKey = flattenedKey + '.' + key;
                }
                let value = currentNode[key];
                if (typeof value === "object") {
                    Utils.traverseAndFlatten(value, target, newKey);
                } else {
                    target[newKey] = value;
                }
            }
        }
    }
    
    public static flatten(obj: any) {
        let flattenedObject = {};
        Utils.traverseAndFlatten(obj, flattenedObject);
        return flattenedObject;
    }

    public static searchParam(obj: any , inputKey: string): string | number | any {
        // flat to object
        let newObj = Utils.flatten(obj);
        const keys = Object.keys(newObj);
        const inputs = inputKey.split('|');
        for (let key of keys) {
            for(let input of inputs) {
                if (key.indexOf( input ) > -1 ) {
                    return newObj[key];
                }
            }
        }
        return '';
    }
}