import { Component, OnInit } from '@angular/core';
import imageCompression from 'browser-image-compression';
import { Transversal } from '../ui.transversal';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Functions } from '../../utils/functions';
import { HttpServiceService } from '@zurich-dynamic-form/services';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../modal/modal.component';
import { environment, service as ServicesEnvironment } from '@env/environment';

@Component({
  selector: 'zurich-dynamic-form-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFile extends Transversal {

  public title: string;
  public subtitle: string;
  public label: string;
  public nameFile: string;
  public typeFile: string;
  public widthBar: string = '0%';
  public attachment: any = {
    file: null,
  };
  private ind: string[];
  private static types = {
    img: 'image/*',
    pdf: 'application/pdf, .pdf',
    xls: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
    txt: 'text/plain',
    csv: '.csv',
    html: 'text/html',
    video: 'video/*',
    audio: 'audio/*',
    all: '*'
  };

  constructor(private deviceService: DeviceDetectorService, private modalService: NgbModal) {
    super();
  }

  setOptions(options: any[]): void { }

  onPostInit(dataSelect: string): void {
    this.nameFile = dataSelect || '';
    this.label = Functions.getPlaceHolder(this.component, this.deviceService);
    this.title = this.component.extras.title;
    this.subtitle = this.component.extras.subTitle;
    this.ind = this.component.extras.type.split('|') || ['all'];
    this.typeFile = '';
    this.ind.forEach(
      (indicativo) => {
        this.typeFile += InputFile.types[indicativo] + ', ';
      }
    );
    this.widthBar = '0%';
  }

  /**
 * on file drop handler
 */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.attachment.file = files[0];
    this.prepareFilesList(files);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: any[]) {
    this.widthBar = '0%';
    let fileInput: File = files[0];
    this.nameFile = fileInput.name;
    const validation = Functions.validateExtension(this.nameFile, this.ind);
    if (validation) {
      this.messageInformation = '';
      if (Functions.validateExtension(this.nameFile, ['img'])) {
        const options = {
          onProgress: (value: number) => {
            this.widthBar = (((50 * value) / 100) + 10) + '%';
          },       // optional, a function takes one progress argument (percentage from 0 to 100)
          useWebWorker: true,      // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
          maxIteration: 70,       // optional, max number of iteration to compress the image (default: 10)
          fileType: 'jpeg',
          initialQuality: 1      // optional, initial quality value between 0 and 1 (default: 1)
        }
        // imageCompression(fileInput, options).then(
        //   (fileResult) => {
        //     imageCompression.getDataUrlFromFile(fileResult).then((file ) => { this.uploadData(file) });
        //   }
        // )
        this.uploadData(fileInput)
      } else {
        this.widthBar = '10%';
        this.toBase64(fileInput).then((file: File) => { this.widthBar = '50%'; this.uploadData(fileInput) });

      }
    } else {
      this.nameFile = '';
      this.messageInformation = 'El archivo que intentas enviar no es del tipo solicitado.';
    }
  }

  private uploadData(file: File) {
    const uuid = localStorage.getItem('UUID');
    const name = this.component.extras.idService.replace('@', '');
    // if (typeof file === 'string') {
    //   const tmp = file.split(',');
    //   file = tmp[tmp.length - 1];
    // }
    const time = new Date().getTime();
    const obj = {
      fileName: `${(name.replace('input', '')).concat('_').concat(('' + time))}.${this.nameFile.split('.')[1]}`,
      path: '/zurich/api/fnol/'.concat(uuid).concat('/'),
      fileDocument: file,
      body: ''
    }



    const formData = new FormData();
    formData.append('fileDocument', this.attachment.file as File);
    formData.append('fileName', `${(name.replace('input', '')).concat('_').concat(('' + time))}.${this.nameFile.split('.')[1]}` as string);
    formData.append('path', '/zurich/api/fnol/'.concat(uuid).concat('/'));
    this.service.callServiceForParams(ServicesEnvironment.uploadftpParams.method,
      //environment.host.concat(environment.path).concat(ServicesEnvironment.uploadftpParams.path),
      environment.host.concat(ServicesEnvironment.uploadftpParams.path),
      formData).then(
        (observable) => {
          observable
            .pipe(
              catchError((err) => {
                this.widthBar = '100%';
                const modal = this.modalService.open(ModalComponent, HttpServiceService.CENTER);
                modal.componentInstance.data = {
                  type: 'erno',
                  value: 0
                };
                modal.result.then((res) => {
                  modal.close();
                  if (res) {
                    this.uploadData(file);
                  } else {
                    this.nameFile = '';
                    this.messageInformation = 'Lo sentimos, en este momento no pudimos enviar el archivo de forma exitosa. Por favor inténtalo nuevamente';
                  }
                });
                return throwError(err);
              })
            )
            .subscribe((res) => {
              if (Number(res.code) === 1 || Number(res.code) === 0) {
                this.widthBar = '100%';
                const value = res.file.path.concat(res.file.fileName);
                this.setValue(value);
              } else {
                this.messageInformation = 'Lo sentimos, en este momento no pudimos enviar el archivo de forma exitosa. Por favor inténtalo nuevamente';
                this.setValue('');
              }
            });
        }
      );
  }

  public deleteFile(): void {
    const modal = this.modalService.open(ModalComponent, HttpServiceService.CENTER);
    modal.componentInstance.data = {
      type: 'confirmDeleteFile',
      value: 0
    };
    modal.result.then((res) => {
      modal.close();
      if (res) {
        this.widthBar = '30%';
        this.confirmDelete();
      }
    });
  }


  private confirmDelete(): void {
    const value = (this.behoviur.getAllObject()[this.component.id]).split('/');
    const fileName = value[value.length - 1];
    const uuid = localStorage.getItem('UUID');
    const obj = {
      fileName,
      path: '/zurich/api/fnol/'.concat(uuid).concat('/'),
    }
    this.service.callServiceForUrl(
      ServicesEnvironment.deleteftp.method,
      //environment.host.concat(environment.path).concat(ServicesEnvironment.deleteftp.path),
      environment.host.concat(ServicesEnvironment.deleteftp.path),
      obj
    ).then(
      (observable) => {
        observable
          .pipe(
            catchError((err) => {
              this.showErnoService();
              this.widthBar = '0%';
              return throwError(err);
            })
          )
          .subscribe(
            (response) => {
              this.widthBar = '100%';
              if (response.code === 1 || response.code === 0) {
                this.setValue('');
              } else {
                this.messageInformation = 'Lo sentimos, en este momento no pudimos eliminar el archivo de forma exitosa. Por favor inténtalo nuevamente';
              }
            }
          );
      }
    );
  }

  private showErnoService(): void {
    const modal = this.modalService.open(ModalComponent, HttpServiceService.CENTER);
    modal.componentInstance.data = {
      type: 'erno',
      value: 0
    };
    modal.result.then((res) => {
      modal.close();
      if (res) {
        this.deleteFile();
      } else {
        this.messageInformation = 'Lo sentimos, en este momento no pudimos enviar el archivo de forma exitosa. Por favor inténtalo nuevamente';
      }
    });
  }

  private toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  public getName(value: string): string {
    const array = value.split('/');
    return array[array.length - 1];
  }
}
