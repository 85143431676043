<div class="form-group" [ngClass]="classSection" *ngIf="isItemVisible" [attr.id]="id" >
    <label *ngIf="title !== ''" class="title-label" for="usr">{{title}}</label>
    <zurich-dynamic-form-tooltip-information *ngIf="component.extras.help && component.extras.help.description"
        [title]="component.extras.help.title"
        [body]="component.extras.help.description"
        [title]="component.extras.help.image" >
    </zurich-dynamic-form-tooltip-information>

    <zurich-dynamic-form-slide-tooltip *ngIf="component.extras.help && component.extras.help.options"
        [title]="component.extras.help.title" 
        [options]="component.extras.help.options"
    ></zurich-dynamic-form-slide-tooltip>

    <p *ngIf="subtitle !== ''" class="subtitle-label">{{subtitle}}</p>
   

    <div ngbDropdown class="d-block" *ngIf="!isChangeService">
            <button class="btn btn-info btn-block" type="button" [ngClass]="{'error-class': isError , 'input-changeColor' : !isPlaceholder}" ngbDropdownToggle>{{placeholder}}</button>
        <div ngbDropdownMenu [attr.aria-labelledby]="id" style="width: 100%;">
            <input type="text" class="form-control input-portal" 
                placeholder="Buscar"
                [(ngModel)]="filterValue" (keyup)="filterOptions()" *ngIf="optionsTmp.length >= 7"
            />
            <div class="content-scrollable">
                    <button ngbDropdownItem role="button" type="button" *ngFor="let option of component.extras.options;" (click)="select(option)">{{option.value}}</button>
            </div>
        </div>
        <zurich-dynamic-form-error *ngIf="isError"></zurich-dynamic-form-error>
    </div>
    <input 
        type="text" 
        style="margin: .5rem;width: 97%;"
        *ngIf="isChangeService"
        class="form-control input-portal" 
        [ngClass]="{'error-class': isError }" 
        [(ngModel)]="value" 
        [disabled]="!isEditable"
        (keyup)="setValueInput(value)"
    />
  </div>