export const environment = {
  production: true,
  host: 'https://api.zurichseguros.com.ec',
  hostApiGateway: 'https://prod-zurich.auth.us-east-1.amazoncognito.com/oauth2',
  // host : '',
  path: '/zurich/api/fnol',
  token: '/token',
  msm: {
    information: 'En Zurich no solo cuidamos su patrimonio, sino también sus datos personales. Por ello enmascararemos toda la información que Usted ya nos ha proporcionado, con el fin de protegerlo. De acuerdo con la ley de protección de datos conforme resolución 009-NG-DINARDAP-2021.',
    policy: 'Entendemos que su reclamación no contemplará los daños del vehículo asegurado, pero si contemplará información correspondiente a terceros afectados. Al continuar al siguiente paso, solicitaremos información acerca de los involucrados'
  },
  crypto: {
    iv: 'DeRT2f2k9/5/5A80',
    secret: 'd6b9c*S?F)H-LnOwDjCnTw4y7q8x#V$V'
  },
  appName: 'ec-webapp-fnol-sit',
  insightsClient: {
    instrumentationKey: 'cb8792ea-f7f1-4348-b6fc-0a2a0662e1c3'
  }
};


export const service = {
  token: 'OFplekdWbm9FTllOZzN5Y2VfRGpwc3VKRUdnYTppS2JRTldwUGQ3RWNKQnpUcXRSME5Nb0NMblFh',
  apiKeyCognito:  window["env"]["apik"] || "N/A",
  tokenCognito:  window["env"]["user"] || "N/A",
  apiKey: 'WwRuh7PPZt7RnN9Is7IS8FRPB9lhxnN2PCjFkoq4',
  'customerInformation': {
    method: 'get',
    path: '/ms-customers-s0146/v1/customers/customer/@inputTypeDocumentIdentity/@inputNumberDocumentIdentity'
  },
  'informationByPlate': {
    method: 'get',
    path: '/ms-vehicle-information-by-plate-s0161/v1/vehicle/informationByPlate/@inputNumberCarIdentityRegistry/@inputTypeDocumentIdentity/@inputNumberDocumentIdentity'
  },
  'informationByMotor': {
    method: 'get',
    path: '/ms-vehicle-information-by-plate-s0161/v1/vehicle/informationByMotor/@inputNumberMotorCarRegistry/@inputTypeDocumentIdentity/@inputNumberDocumentIdentity'
  },
  'losstype': {
    method: 'get',
    path: '/ms-loss-type-s0152/v1/parametric/losstype/@ramoForFilter/@inputCauseSinister'
  },
  'causesinister': {
    method: 'get',
    path: '/ms-cause-sinister-s0156/v1/parametric/causesinister/@ramoForFilter'
  },
  'listprovince': {
    method: 'get',
    path: '/ms-province-s0150/v1/provinces/listprovince'
  },
  'listcities': {
    method: 'get',
    path: '/ms-cities-s0144/v1/cities/listcities/@inputProvinceSinister'
  },
  'listoffice': {
    method: 'get',
    path: '/ms-office-s0148/v1/offices/listoffice'
  },
  'uploadftp': {
    method: 'post',
    path: '/ms-upload-s0142/v1/ftp/upload'
  },
  'uploadftpParams': {
    method: 'post',
    path: '/ms-upload-s0142/v1/ftp/upload'
  },
  'deleteftp': {
    method: 'post',
    path: '/ms-upload-s0142/v1/ftp/delete'
  },
  'brand': {
    method: 'get',
    path: '/ms-vehicle-brand-s0165/v1/vehicle/brand'
  },
  'brandModel': {
    method: 'get',
    path: '/ms-vehicle-model-s0154/v1/vehicle/model/@inputBrandCarRegistry'
  },
  'createSinister': {
    method: 'post',
    path: '/ms-create-sinister-s0141/v1/sinister/create'
  }
}
