import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputText } from './input/input.component';
import { Select } from './select/select.component';
import { TextArea } from './text-area/text-area.component';
import { Datepicker } from './datepicker/datepicker.component';
import { RadioButton } from './radio-button/radio-button.component';
import { InputFile } from './input-file/input-file.component';
import { Label} from './label-component/label-component.component';
import { Header } from './header/header.component';
import { Steps } from './steps/steps.component';
import { Hourpicker } from './hourpicker/hourpicker.component';
import { Title } from './title/title.component';
import { Viewinteractive } from './viewinteractive/viewinteractive.component';
import { AddCard } from './addcard-popup/addcard-popup.module';
import { Button } from './button/button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputFileDirective } from './input-file/input-file.directive';
import { TooltipInformationComponent } from './tooltip-information/tooltip-information.component';
import { NgbDropdown, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ErrorComponent } from './error/error.component';
import { ModalComponent } from './modal/modal.component';
import { Checkbox } from './checkbox/checkbox.component';
import { InformationMessageComponent } from './information-message/information-message.component';
import { SlideTooltipComponent } from './slide-tooltip/slide-tooltip.component';
import { HiddenData } from './hidden-data/hidden-data.component';
import { InputMask } from './input-mask/input-mask.component';
import { PipeMask } from './input-mask/Pipes/PipeMask.transform';

@NgModule({
  imports: [
    CommonModule , 
    FormsModule , NgbModule , ReactiveFormsModule,
    AddCard
  ],
  declarations: [
    PipeMask,
    InputFileDirective,
    InputText, 
    Select, 
    TextArea, 
    Datepicker, 
    RadioButton, 
    InputFile, 
    Label, 
    Header, 
    Steps, 
    Hourpicker, 
    Title, 
    Viewinteractive, 
    HiddenData, 
    Button, 
    SlideTooltipComponent,
    TooltipInformationComponent, 
    ErrorComponent, ModalComponent, Checkbox, InformationMessageComponent, InputMask
  ],
  exports: [
    InputText, 
    Select, 
    TextArea, 
    Datepicker, 
    RadioButton, 
    InputFile, 
    Label, 
    Header, 
    Steps, 
    Hourpicker, 
    Title, 
    Viewinteractive, 
    HiddenData, 
    AddCard,
    Button,
    Checkbox,
    SlideTooltipComponent,
    TooltipInformationComponent,
    InformationMessageComponent,
    InputMask
  ],
  providers: [NgbDropdown],
  bootstrap: [Select]
})
export class UiModule {}
