import { Datepicker } from './lib/datepicker/datepicker.component';
import { Header } from './lib/header/header.component';
import { InputFile } from './lib/input-file/input-file.component';
import { InputText } from './lib/input/input.component';
import { Label } from './lib/label-component/label-component.component';
import { RadioButton } from './lib/radio-button/radio-button.component';
import { Select } from './lib/select/select.component';
import { Steps } from './lib/steps/steps.component';
import { TextArea } from './lib/text-area/text-area.component';
import { Hourpicker } from './lib/hourpicker/hourpicker.component';
import { Title } from './lib/title/title.component';
import { Viewinteractive } from './lib/viewinteractive/viewinteractive.component';
import { HiddenData } from './lib/hidden-data/hidden-data.component'
import { Button } from './lib/button/button.component';
import { TooltipInformationComponent } from './lib/tooltip-information/tooltip-information.component';
import { IndexComponent } from './lib/addcard-popup/index/index.component';
import { Checkbox } from './lib/checkbox/checkbox.component';
import { InputMask } from './lib/input-mask/input-mask.component';

export * from './lib/ui.module';
export const Components =  {
    Datepicker,
    InputText,
    InputFile,
    RadioButton,
    Select,
    TextArea,
    Label,
    Steps,
    Header,
    AddCard : IndexComponent,
    Hourpicker,
    HiddenData,
    Title,
    Viewinteractive,
    Button,
    TooltipInformationComponent,
    Checkbox,
    InputMask
};
export * from './lib/ui.itransversal';
