import { Injectable } from '@angular/core';

/**
 * @author mmontaguano
 */
@Injectable({
    providedIn: 'root'
})
export class SecurityService {

    user: string;
    password: string;
    apiKey : string;

    constructor() {
    }

    getUser(){
        return this.user;
    }

    setUser(user: string){
        this.user=user;
    }

    getPassword(){
        return this.password;
    }

    setPassword(password: string){
        this.password=password;
    }

    getApiKey(){
      return this.apiKey;
    }

    setApikey(apiKey:string){
      this.apiKey = apiKey
    }


}
