import { Pipe, PipeTransform } from '@angular/core';
import { Functions } from 'libs/ui/src/utils/functions';
import { Security } from 'libs/ui/src/utils/security';

@Pipe({ name: 'maskcrypt' })
export class PipeMask implements PipeTransform {

    public static PHONE = 'phone';
    public static CELLPHONE = 'cellphone';
    public static EMAIL = 'email';
    public static NAMEREGISTER = 'nameRegister';
    public static LASTNAMEREGISTER = 'lastName';
    public static NUMBERCARDIDENTITYREGISTER = 'numberCardIdentity';
    public static BRANDCARDREGISTER = 'brandCard';
    public static MODELCARDREGISTER = 'modelCard';
    public static NUMBERCHASISREGISTER = 'numberChasis';
    public static NUMBERMOTOTCARREGISTER = 'numberMotor';
    public static POLIZANUMBER = 'polizaNumber';
    public static BOROKER = 'broker';
    public static PLACA = 'numberCar';


    transform(value: string, type: string): string {
        if (value !== '') {
            let val = Security.decription(value);
            const data = Functions.cleanArray(val.split(''));
            // valida cantidad para enmascarar
            if (data.length > 5 || data.length > 2) {
                // Encripcion Phone
                if (type === PipeMask.CELLPHONE) {
                    const data = localStorage.getItem('@inputCellPhoneRegister_original')
                    let val = Security.decription(data)
                    const cellPhone = Functions.cleanArray(val.split(''))
                    let dataReturn = '';
                    for (let i = 0; i < 3; i++) {
                        dataReturn += cellPhone[i];
                    }
                    dataReturn += '****';
                    for (let i = (cellPhone.length - 3); i < cellPhone.length; i++) {
                        dataReturn += cellPhone[i];
                    }
                    return dataReturn;
                }
                if (type === PipeMask.PHONE) {
                    const data = localStorage.getItem('@inputPhoneRegister_original')
                    let val = Security.decription(data)
                    const cellPhone = Functions.cleanArray(val.split(''))
                    let dataReturn = '';
                    for (let i = 0; i < 3; i++) {
                        dataReturn += cellPhone[i];
                    }
                    dataReturn += '****';
                    for (let i = (cellPhone.length - 3); i < cellPhone.length; i++) {
                        dataReturn += cellPhone[i];
                    }
                    return dataReturn;
                }
                if (type === PipeMask.CELLPHONE) {
                    const data = localStorage.getItem('@inputPhoneRegister_original')
                    let val = Security.decription(data)
                    const cellPhone = Functions.cleanArray(val.split(''))
                    let dataReturn = '';
                    for (let i = 0; i < 3; i++) {
                        dataReturn += cellPhone[i];
                    }
                    dataReturn += '****';
                    for (let i = (cellPhone.length - 3); i < cellPhone.length; i++) {
                        dataReturn += cellPhone[i];
                    }
                    return dataReturn;
                }
                // Encripcion Email
                else if (type === PipeMask.EMAIL) {
                    const data = localStorage.getItem('@inputEmailRegister_original')
                    let val = Security.decription(data)
                    const email = Functions.cleanArray(val.split(''))
                    const partFront = val.split('@');
                    let dataReturn = '';
                    for (let i = 0; i < 3; i++) {
                        dataReturn += email[i];
                    }
                    dataReturn += '******@';
                    dataReturn += partFront[1];
                    let e = Functions.cleanArray(dataReturn.split(''));
                    return e.reduce((a, b) => a + b, '');

                } else if (type === PipeMask.NAMEREGISTER) {
                    const data = localStorage.getItem('@inputNameRegister_original')
                    let val = Security.decription(data)
                    const name = Functions.cleanArray(val.split(''))
                    let inicio = name[0];
                    let final = name[name.length - 1];
                    let medio = name.toString().slice(1, -1).replace(/[a-zA-Z]/g, '*');
                    var cadenaSinComas = medio.replace(/,/g, "");
                    return inicio + cadenaSinComas + final;
                } else if (type === PipeMask.LASTNAMEREGISTER) {
                    const data = localStorage.getItem('@inputLastNameRegister_original')
                    let val = Security.decription(data)
                    const lastname = Functions.cleanArray(val.split(''))
                    let dataReturn = '';
                    for (let i = 0; i < 3; i++) {
                        dataReturn += lastname[i];
                    }
                    dataReturn += '******';
                    for (let i = (lastname.length - 3); i < lastname.length; i++) {
                        dataReturn += lastname[i];
                    }
                    return dataReturn;
                } else if (type === PipeMask.NUMBERCARDIDENTITYREGISTER) {
                    let dataReturn = '';
                    for (let i = 0; i < 3; i++) {
                        dataReturn += data[i];
                    }
                    dataReturn += '******';
                    for (let i = (data.length - 3); i < data.length; i++) {
                        dataReturn += data[i];
                    }
                    return dataReturn;
                } else if (type === PipeMask.NUMBERCHASISREGISTER) {
                    const data = localStorage.getItem('@inputNumberChasisCarRegistry2_original')
                    let val = Security.decription(data)
                    const chasis = Functions.cleanArray(val.split(''))

                    let dataReturn = '';
                    for (let i = 0; i < 3; i++) {
                        dataReturn += chasis[i];
                    }
                    dataReturn += '******';
                    for (let i = (chasis.length - 3); i < chasis.length; i++) {
                        dataReturn += chasis[i];
                    }
                    return dataReturn;
                } else if (type === PipeMask.NUMBERMOTOTCARREGISTER) {
                    const data = localStorage.getItem('@inputNumberMotorCarRegistry2_original')
                    let val = Security.decription(data)
                    const motor = Functions.cleanArray(val.split(''))
                    let dataReturn = '';
                    for (let i = 0; i < 3; i++) {
                        dataReturn += motor[i];
                    }
                    dataReturn += '******';
                    for (let i = (motor.length - 3); i < motor.length; i++) {
                        dataReturn += motor[i];
                    }
                    return dataReturn;
                } else if (type === PipeMask.POLIZANUMBER) {
                    const data = localStorage.getItem('@inputHiddenPolicyNumber')
                    let val = Security.decription(data)
                    const policyNumber = Functions.cleanArray(val.split(''))
                    let dataReturn = '';
                    const strData = policyNumber; // Convertir el número a una cadena de texto
                    for (let i = 0; i < 3; i++) {
                        dataReturn += strData[i];
                    }
                    dataReturn += '******';
                    for (let i = (strData.length - 3); i < strData.length; i++) {
                        dataReturn += strData[i];
                    }
                    return dataReturn.replace(',', '');
                } else if (type === PipeMask.BOROKER) {
                    const data = localStorage.getItem('broker')
                    let val = Security.decription(data)
                    const policyNumber = Functions.cleanArray(val.split(''))
                    let dataReturn = '';
                    for (let i = 0; i < 3; i++) {
                        dataReturn += policyNumber[i];
                    }
                    dataReturn += '******';
                    for (let i = (policyNumber.length - 3); i < policyNumber.length; i++) {
                        dataReturn += policyNumber[i];
                    }
                    return dataReturn.replace(',', '');
                } else if (type === PipeMask.PLACA) {
                    let dataReturn = '';
                    const strData = data.toString(); // Convertir el número a una cadena de texto

                    dataReturn += '******';
                    for (let i = (strData.length - 3); i < strData.length; i++) {
                        dataReturn += strData[i];
                    }
                    return dataReturn;
                }
                // Encripcion Any
                else {
                    let dataReturn = '******';
                    for (let i = (data.length - 6); i < data.length; i++) {
                        dataReturn += data[i];
                    }
                    return dataReturn;
                }
            }
            return val;
        }
        return value;
    }


}
