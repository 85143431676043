import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig, NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Transversal } from '../ui.transversal';

@Component({
  selector: 'zurich-dynamic-form-hourpicker',
  templateUrl: './hourpicker.component.html',
  styleUrls: ['./hourpicker.component.scss'],
  providers: [NgbDropdownConfig, NgbTimepickerConfig]
})
export class Hourpicker extends Transversal {

  public id: string;
  public time = { hour: 13, minute: 30 };
  public meridian = true;
  public title: string;
  public subtitle: string;
  public msmErno: SafeHtml = '';
  public errorM1: string;
  public date = new Date();
  public today;

  constructor(private config: NgbTimepickerConfig, private sanitizer: DomSanitizer) {
    super();
    this.config.meridian = this.meridian;
    this.config.spinners = true;
    this.config.seconds = false;
    this.config.hourStep = 1;
    this.config.minuteStep = 1;
    this.config.secondStep = 1;
    this.config.disabled = false;
    this.config.readonlyInputs = false;
    this.config.size = 'medium';
  }

  setOptions(options: any[]): void { }

  onPostInit(dataSelect: string): void {
    this.id = this.component.id;
    this.title = this.component.extras.title;
    this.subtitle = this.component.extras.subTitle;
    this.id = this.component.id;
    const data = new Date();
    dataSelect = this.setInitialHour(data,dataSelect);
    const valueHour = dataSelect.split(':');
    this.time = {
      hour: Number(valueHour[0]),
      minute: Number(valueHour[1])
    }
    if (this.component.extras.edit) {
      if (this.time.minute !== 0 && this.time.minute !== 30) {
        this.time.minute = 0;
      }
      this.config.minuteStep = 30;
      this.config.readonlyInputs = true;
    }
    this.valideHour();
  }

  toggleMeridian() {
    this.meridian = !this.meridian;
  }

  setHour(myDrop) {
    this.setValue(((this.time.hour > 9) ? this.time.hour : '0' + this.time.hour) + ':' + ((this.time.minute > 9) ? this.time.minute : '0' + this.time.minute));

    myDrop.close();
  }

  valideHour(): void {

    if(this.behoviur.getValueForComponent("@inputDateInspectionExpress")==null){
      this.today = this.date.toISOString().substr(0, 10);
    }else{
      this.today = this.behoviur.getValueForComponent("@inputDateInspectionExpress");
    }
    var todayTemp = this.date.toISOString().substr(0, 10);
    var actualH = this.date.getHours();
    var actualM = this.date.getMinutes();

    this.errorM1 = `Lamentablemente en el horario<br>seleccionado no tenemos la posibilidad<br>de atender la inspección mediante<br>video llamada, por lo que le recomendamos<br>seleccionar la opción de auto inspección<br>que le guiará en el proceso de captura de <br>daños acelerando los tiempos de resolución<br>de su siniestro.`;
    

    if (this.component.extras && this.component.extras.disable) {
      if (this.component.extras.disable.work) {
        if (this.time.hour >= 0 && this.time.hour < 8) {
          this.msmErno = this.sanitizer.bypassSecurityTrustHtml(this.errorM1);
        } else if (this.time.hour > 12 && this.time.hour < 14) {
          this.msmErno = this.sanitizer.bypassSecurityTrustHtml(this.errorM1);
        } else if (this.time.hour > 18 && this.time.hour <= 24) {
          this.msmErno = this.sanitizer.bypassSecurityTrustHtml(this.errorM1);
          
        } else if (todayTemp === this.today) {
          if (this.time.hour < actualH) {
            this.msmErno = this.sanitizer.bypassSecurityTrustHtml(this.errorM1);
          } else if (actualM == 0) {
            if (this.time.hour < actualH + 2) {
              this.msmErno = this.sanitizer.bypassSecurityTrustHtml(this.errorM1);
            }
            else {
              this.msmErno = '';
            }
          } else if (actualM < 30) {
            if (this.time.hour < actualH + 2 || (this.time.hour == actualH + 2 && this.time.minute == 0)) {
              this.msmErno = this.sanitizer.bypassSecurityTrustHtml(this.errorM1);
            } else {
              this.msmErno = '';
            }
          } else if (actualM>=30) {
            if (this.time.hour < actualH + 3) {
              this.msmErno = this.sanitizer.bypassSecurityTrustHtml(this.errorM1);
            } else {
              this.msmErno = '';
            }
          }
        }
        else {
          this.msmErno = '';
        }
      }
    }
  }

  setInitialHour(data, dataSelect){

    var actualM = this.date.getMinutes();

    if (actualM == 0) {
      dataSelect = (dataSelect) ? dataSelect : (data.getHours()+2) + ':' + data.getMinutes();
    }else if (actualM < 30) {
      dataSelect = (dataSelect) ? dataSelect : (data.getHours()+2) + ':' + 30;
    }else if (actualM>=30) {
      dataSelect = (dataSelect) ? dataSelect : (data.getHours()+3) + ':' + data.getMinutes();
    }
    return dataSelect;


  }
}

