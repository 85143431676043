import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Transversal } from '../../ui.transversal';
import { PageComponent } from '../page/page.component';

@Component({
  selector: 'zurich-dynamic-form-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent extends Transversal {

  public id: string;
  public title: string;
  public placeholder: string;

  private isData: boolean;

  public listItem: any[];

  
  constructor(private router: Router , private modalService: NgbModal) { 
    super();
    this.listItem = [];
  }

  public static FULLPAGE: NgbModalOptions = {
    size: 'dialog-centered fullscreen-modal',
    backdrop : 'static',
    keyboard : false
  }

  onPostInit(dataSelect: string): void {
    this.id = this.component.id;
    this.title = this.component.extras.title;
    this.placeholder = this.component.extras.placeholder;
    if (dataSelect && dataSelect !== '' ) {
      this.listItem = JSON.parse( localStorage.getItem(this.component.id) || '[]');
    }
  }

  public addCard(): void {
    const card = this.modalService.open(PageComponent, IndexComponent.FULLPAGE );
    card.componentInstance.template = this.component.extras.type;
    card.componentInstance.new = true;
    card.result.then(
      (data) => {
        if(data) {
          this.listItem.push( {info: data} );
          this.setValue( JSON.stringify(this.listItem) );
        }
      }
    );
  }

  setOptions(options: any[]): void{}

  public removeCard(index : number) : void {
    this.listItem.splice( index , 1 );
    this.setValue( JSON.stringify(this.listItem) );
  }

  public editar(index: number) : void {
    const card = this.modalService.open(PageComponent, IndexComponent.FULLPAGE );
    card.componentInstance.template = this.component.extras.type;
    card.componentInstance.new = false;
    card.componentInstance.information = this.listItem[index].info;
    card.result.then(
      (data) => {
        if(data) {
          this.listItem[index] =  {info: data} ;
          this.setValue( JSON.stringify(this.listItem) );
        }
      }
    );
  }

  public getName(index : number) : string {
    let data = (this.listItem[index].info.hasOwnProperty('inputNameRegisterThird')) ? 
              this.listItem[index].info['inputNameRegisterThird'] + ' ' + this.listItem[index].info['inputLastNameRegisterThird'] : '' ;
    if ( data !== '') { return data; }
    data = (this.listItem[index].info.hasOwnProperty('@inputNameRegisterWitnesses')) ? 
              this.listItem[index].info['@inputNameRegisterWitnesses'] + ' ' + this.listItem[index].info['@inputLastNameRegisterWitnesses'] : '' ;
    if ( data !== '') { return data; }
    data = (this.listItem[index].info.hasOwnProperty('@inputNameRegisterWitness')) ? 
        this.listItem[index].info['@inputNameRegisterWitness'] + ' ' + this.listItem[index].info['@inputLastNameRegisterWitness'] : '' ;
    if ( data !== '') { return data; }
    return this.placeholder + ' ' + (index + 1);
  }
}
