import { Template } from '@angular/compiler/src/render3/r3_ast';
import { Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'zurich-dynamic-form-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  public data: any;
  @ViewChild('containerModal', { read: ViewContainerRef })
  public container: ViewContainerRef;
  @ViewChild('load')
  private load: TemplateRef<any>;
  @ViewChild('erno')
  private erno: TemplateRef<any>;
  @ViewChild('upload')
  private upload: TemplateRef<any>;
  @ViewChild('reportExist')
  private reportExist: TemplateRef<any>;
  @ViewChild('reportIncomplete')
  private reportIncomplete: TemplateRef<any>;
  @ViewChild('confirmDeleteFile')
  private confirmDeleteFile: TemplateRef<any>;
  @ViewChild('noConfirmAutorize')
  private noConfirmAutorize: TemplateRef<any>;
  @ViewChild('ernoSendReport')
  private ernoSendReport: TemplateRef<any>;
  @ViewChild('noSelectPiece')
  private noSelectPiece: TemplateRef<any>;


  constructor(private activeModal: NgbActiveModal) {
    this.data = {
      type: '',
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const InputTemplate: string = this.data.type;
    switch (InputTemplate) {
      case 'load':
        this.container.insert(this.load.createEmbeddedView(null));
        break;
      case 'upload':
        this.container.insert(this.upload.createEmbeddedView(null));
        break;
      case 'reportExist':
        this.container.insert(this.reportExist.createEmbeddedView(null));
        break;
      case 'reportIncomplete':
        this.container.insert(this.reportIncomplete.createEmbeddedView(null));
        break;
      case 'confirmDeleteFile':
        this.container.insert(this.confirmDeleteFile.createEmbeddedView(null));
        break;
      case 'noConfirmAutorize':
        this.container.insert(this.noConfirmAutorize.createEmbeddedView(null));
        break;
      case 'ernoSendReport':
        this.container.insert(this.ernoSendReport.createEmbeddedView(null));
        break;
      case 'noSelectPiece':
        this.container.insert(this.noSelectPiece.createEmbeddedView(null));
        break;
      default:
        this.container.insert(this.erno.createEmbeddedView(null));
        break;
    }
  }


  onClickAssertive(): void {
    this.activeModal.close(true);
  }
  onClickClear(): void {
    this.activeModal.close(false);
  }
}
