<div class="form-group" [ngClass]="classSection" *ngIf="isItemVisible">
    
    <label *ngIf="title !== ''" class="subtitle-label" [for]="id">{{title}}</label>

    <div class="class-container" [ngClass]="(listItem.length > 1) ? 'shadow-container ' + classSection : classSection">
        
        <div class="card padding-card" style="width:17rem" (click)="addCard()">
            <div class="container-image">
                <img class="card-img-top" src="assets/img/img-plus-2.svg" alt="Card image">
            </div>
            <p class="card-text label-card">Agregar {{placeholder}}</p>
        </div>

        <div class="card card-item" *ngFor="let item of listItem; index as i" style="width:17rem">
            <div class="card-header">            
                <button type="button" class="close" aria-label="Close" (click)="removeCard(i)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div (click)="editar(i)" class="container-image">
                <img class="card-img-top" src="assets/img/img-user.png" alt="Card image">
            </div>
            <p (click)="editar(i)" class="card-text label-card">{{getName(i)}}</p>
            <div (click)="editar(i)" class="card-footer">Editar</div>
        </div>

    </div>

</div>