<div class="header">
    <img [src]="urlImage">
</div>
<div class="container">
    <section class="first-section">
        <div class="form-group">
            <h3 class="title-label">Tu reporte de siniestro ha sido enviado exitosamente</h3>
            <div class="split-title"></div>
            <img src="assets/img/img-check.png" alt="icon">
            <div class="subtitle-label" [innerHTML]="title"></div>
        </div>
    </section>
    <section class="second-section">
        <div class="form-group">
            <img style="width: 100%;" src="assets/img/img-claims-info.png"/>
            <!-- <img src="assets/img/img-complete.gif" alt="icon"> -->
        </div>
        <button class="btn button-footer button-asertive" (click)="loadUrl()">Finalizar</button>
    </section>

</div>
