import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, ViewContainerRef } from '@angular/core';
import { Transversal } from '../ui.transversal';
import { Functions } from '../../utils/functions';


@Component({
  selector: 'zurich-dynamic-form-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputText extends Transversal {

  public title: string;
  public id: string;
  public subtitle: string;
  public isButtonSearch: boolean;
  public type: string;
  @ViewChild("elementId", { read: ViewContainerRef }) element: ElementRef;


  constructor(private renderer: Renderer2) {
    super();
    this.isButtonSearch = false;
  }

  setOptions(options: any[]): void { }

  onPostInit(dataSelect: string): void {
    this.value = dataSelect;
    this.id = this.component.id;
    this.title = this.component.extras.title;
    this.subtitle = this.component.extras.subTitle;
    this.type = this.component.extras.type || 'text';
    this.isButtonSearch = (this.component.urlPostChange && this.component.urlPostChange !== '');
    if (Functions.COMPONENTSMASK.includes(this.component.id)) {
      if (this.value) {
        if (this.value.includes('-')) {
          let parts = this.value.split('-');
          this.value = parts[1];
        }
      }
    }
  }

  onEnter(): void {
    if (this.isButtonSearch) {
      if (this.isValid()) {
        this.searchInformation();
      }
    } else {
      this.isValid();
    }
  }


}
